import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import TestSuiteRow from 'components/TestSuiteRow';

import { getAllTestSuites } from 'store/actions/test_suites_actions';

const TestSuites = () => {
  const [loadingTestSuites, setLoadingTestSuites] = useState(false);

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.menu.categories);
  const userInfo = useSelector((state) => state.app.userInfo);
  const suites = useSelector((state) => state.testSuites.suites);
  const roles = useSelector((state) => state.testSuites.roles);

  useEffect(() => {
    const validServices = ['thservices', 'projects'];
    const invalidCategories = ['testdata', 'Perf testing'];

    if (suites.length === 0) {
      setLoadingTestSuites(true);
    }

    // removing not supported categories
    const services = validServices
      .reduce((accumulator, currentCategory) => {
        return [...accumulator, ...categories[currentCategory]];
      }, [])
      .filter((category) => !invalidCategories.includes(category.servicename))
      .map((category) => {
        if (!category.servicename) {
          category.servicename = category.name;
        }

        return category;
      });

    dispatch(getAllTestSuites(services)).then(() => {
      setLoadingTestSuites(false);
    });
  }, []);

  const renderRows = (suites) => {
    if (loadingTestSuites) {
      return (
        <li className="empty-row">
          <LoadingSpinner text="Loading test suites" />
        </li>
      );
    }

    if (suites.length > 0) {
      return suites.map((service) => {
        return service.testsuites.map((suite) => {
          const rowProps = {
            key: suite.testsuiteid,
            suite: suite,
            roles: roles,
            userInfo: userInfo,
          };

          return <TestSuiteRow {...rowProps} />;
        });
      });
    } else {
      return (
        <li className="empty-row">
          <div className="test-suite-info">
            <span>
              0 test suites,{' '}
              <NavLink to="/testsuites/new">Create a test suite</NavLink> now
            </span>
          </div>
        </li>
      );
    }
  };

  const rows = renderRows(suites);

  return (
    <div className="testsuites-wrapper">
      <PageTitle
        title="Test suites"
        description="Test suites give you the freedom to trigger different set of tests at the press of a button."
      >
        <NavLink to="/testsuites/new" className="btn btn-primary">
          Create a new test suite
        </NavLink>
      </PageTitle>

      <ul className="test-suites-table">
        <li>
          <div className="test-suite-info">
            <span>Suite name</span>
            <span>Category</span>
            <span>Environment</span>
            <span>Number of tests</span>
            <span>Actions</span>
          </div>
        </li>

        {rows}
      </ul>
    </div>
  );
};
export default TestSuites;
