import React from 'react';
import { Link } from 'react-router-dom'
import { reject } from 'lodash';

import { addNewNotification } from 'store/actions/notification_actions';
import { createTestCase, createAccessTestCase } from 'utils/api.service';
import { fetchChannelList } from 'utils/api.service';

export const meshTypes = {
  SUBMIT_TESTCASE: "mesh/SUBMIT",
  IS_SUBMITING: "mesh/WAITING_RESPONSE_FROM_SUBMIT",
  RESET_SUCCESS: "mesh/RESETTING_DEFAULT",
  SET_ID: "mesh/SETTING_CHANNELS",
  SET_MESH_OPTIONS: "mesh/SETTTING_LIST",
  LIST_LOADING: "mesh/LOADING_LIST",
  RESET_DATA: "mesh/RESET_LIST",
  DONE_SUBMITTING: 'mesh/DONE_SUBMITTING',
};

export const submitMeshTestCases = (data, referral) => dispatch => {
  dispatch({ type: 'quarry/RESETTING_DEFAULT' });
  dispatch({ type: meshTypes.IS_SUBMITING });
  dispatch({type:'HOSTING_RESET'})

  return new Promise ((resolve, reject) => {
    const submit = referral.toLowerCase() === 'access' ? createAccessTestCase : createTestCase;
    let channel;
    let payload = {
      env: data.env,
      executionid: data.executionid,
      executionname: data.executionname,
      notificationemail: data.notificationemail
    };

    payload.items = data.items.map(test => {
      let itemPayload = {
        testcaseid: test.testcaseid,
        testtype: test.testtype || test.testype,
      };

      if (test.attempt) {
        itemPayload = {
          ...itemPayload,
          ...test,
        };
      }

      if (test.params) {
        itemPayload = {
          ...itemPayload,
          ...test.params,
        };
      }

      if (test.reason) {
        delete itemPayload.status;
        delete itemPayload.reason;
      }

      channel = test.channel;

      return itemPayload;
    });

    return submit(payload, channel).then(response => {
      dispatch({
        type: 'MESH_TESTCASE_REQUEST_COMPLETED',
        data:  response ? response.data : {},
        status: response.status
      });

      dispatch(addNewNotification(
        <span>{ response.data.detail } <Link to='/jobsqueue'>Go to Jobs Queue</Link></span>,
        'success')
      );

      resolve({ data: { message: 'Test cases submitted successfully' }});
    }).catch(error => {
      const errorMessage = (error && error.response) ? error.response.data.detail : error.message;

      dispatch(addNewNotification(
        <span>{ errorMessage }</span>,
        'danger')
      );

      reject(error);
    });
  }).catch(error => {
    dispatch({
      type: 'MESH_TESTCASE_REQUEST_COMPLETED',
      data: (error && error.response) ? error.response.data : {message: error.message},
      status:  (error && error.response) ? error.response.status : undefined
    });

    reject('error');
  });
};

export const getMeshTestCases = (categoryid, subcategoryid) => {
  return fetchChannelList(categoryid, subcategoryid);
};
