import { TYPES } from 'utils/session.service';

const initialState = {
  awsLoginErrors: '',
  isLoggedIn: false,
  auth: {},
};

const reducer = function loginReducer(state = initialState, action) {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case TYPES.LOGIN_PROCESS:
      return {
        awsLoginErrors: action.err,
        isLoggedIn: action.auth,
        auth: action.user,
      };
    case TYPES.LOGIN_ERROR_HANDLER:
      return {
        ...state,
        awsLoginErrors: action.err,
      };
    case TYPES.LOGIN_SET:
      return {
        ...state,
        isLoggedIn: action.val,
      };
    case TYPES.LOGOUT_PROCESS:
      return {
        ...state,
        awsLoginErrors: '',
        isLoggedIn: false,
      };
    case TYPES.LOGIN_REFRESH:
      newState.auth = action.user;
      return newState;
    default:
      return state;
  }
};

export default reducer;
