import { Types } from 'store/actions/app_actions';

const defaultState = {
  menu: {},
  environment: {},
  lastUpdate: +new Date(),
  availableServices: [],
  minimumContentLoaded: false,
};

export default (state = defaultState, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case Types.DONE_LOADING:
      newState.minimumContentLoaded = true;

      return newState;
    case Types.UPDATE_MENU_CATEGORY_ID:
      const category = action.category === 'simulation' ? 'orders' : action.category;

      if (!newState.menu) {
       newState.menu = {};
      }

      newState.menu[action.service] = category;
      newState.menu.service = action.service;
      newState.menu.categoryName = action.categoryName;

      return newState;
    case Types.UPDATE_ENVIRONMENT:
      newState.environment[action.context] = action.environment;
      newState.environment.lastUpdate = +new Date();

      return newState;
    case (Types.UPDATE_USER_INFO):
      return {
        ...newState,
        userInfo: action.userInfo,
      }
    case Types.UPDATE_MENU_AVAILABLE_CATEGORIES:
      const supportedKeys = ['thservices', 'projects'];
      const categoryValues = {
        cjt: 'testcases',
      };
      let availableServices = [];

      supportedKeys.forEach(section => {
        const group = action.categories[section].map(category => {
          const id = category.servicename || category.org;

          return {
            displayName:  category.displayName || category.displayname,
            id: categoryValues[id] || id,
          };
        })

        availableServices = [...availableServices, ...group];
      });

      return {
        ...newState,
        availableServices,
      };
    default:
      return newState;
  }
};
