import React from 'react';
import { connect } from 'react-redux';

import Notification from 'components/Notification';

import { startLogoutProcess } from 'store/actions/login';

class Heading extends React.Component {
  renderNotifications(notifications) {
    return notifications.map((notification) => {
      const currentTime = +new Date();

      // if notification was created less than 2 seconds ago
      if (currentTime - notification.createdAt <= 2000) {
        const notificationProps = {
          key: notification.createdAt,
          message: notification.message,
          type: notification.type,
        };

        return <Notification {...notificationProps} />;
      }

      return null;
    });
  }

  render() {
    const notifications = this.renderNotifications(this.props.notifications);
    const helpProps = {
      className: 'button button-link',
      href: 'https://autodesk.slack.com/messages/CF9QHHQ85',
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    const logoutProps = {
      type: 'button',
      className: 'button button-link',
      onClick: this.props.startLogout,
    };

    return (
      <React.Fragment>
        <div id='ZN_298m7asUWd8cSNL'></div>

        <div className='notifications'>
          { notifications }
        </div>

        <header className='heading-wrapper'>
          <a {...helpProps}>Help</a>
          <button {...logoutProps}>Logout</button>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  lastNotificationAt: state.notifications.lastNotificationAt,
});

const mapDispatchToProps = (dispatch) => ({
  startLogout: startLogoutProcess(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Heading);
