import React from 'react';
import classname from 'classname';
import Checkbox from '../../components/Checkbox';
import TotalSelectedBadge from '../../components/TotalSelectedBadge';
import { useSelector } from 'react-redux';

const HostingControls = ({
  selectedAll,
  onSelectAll,
  available,
  selected,
  total,
  showModalWithContent,
  onChangeView,
  viewType,
  onSearchInputChange,
  searchValue,
}) => {
  const role = useSelector((state) => state.hosting.role);

  const searchProps = {
    type: 'search',
    onChange: onSearchInputChange,
    value: searchValue,
    name: 'search',
    className: 'form-control',
    placeholder: 'Search test cases',
  };

  return (
    <>
      <section className="hosting-panel">
        {/* view buttons (grid/table) */}
        <div className="left-panel">
          <div className="btn-group btn-group-xs view-type mr-4" role="group">
            <button
              type="button"
              className={classname({
                btn: true,
                'btn-default': true,
                selected: viewType === 'grid',
              })}
              data-view="grid"
              onClick={onChangeView}
            >
              <i className="bi bi-grid-3x3-gap-fill"></i>
            </button>
            <button
              type="button"
              className={classname({
                btn: true,
                'btn-default': true,
                selected: viewType === 'table',
              })}
              data-view="table"
              onClick={onChangeView}
            >
              <i className="bi bi-table"></i>
            </button>
          </div>

          {role === 'admin' && (
            <button
              key="newtestcase"
              className="btn btn-primary mr-3"
              onClick={showModalWithContent('newTestCase')}
            >
              Add tests
            </button>
          )}
          {/* dropdown buttons*/}
          <div className="dropdown">
            <button
              className="btn btn-light dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>

            <div className="dropdown-menu">
              {role === 'admin' && (
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={showModalWithContent('addTestData')}
                  disabled={selected === 0}
                >
                  Manage Test Data
                </button>
              )}

              <button
                className="dropdown-item"
                type="button"
                onClick={showModalWithContent('cloneTestCasesBulk')}
                disabled={selected === 0}
              >
                Clone
              </button>

              <button
                className="dropdown-item"
                type="button"
                onClick={showModalWithContent('editTestCasesBulk')}
                disabled={selected === 0}
              >
                Edit
              </button>

              <button
                className="dropdown-item"
                type="button"
                onClick={showModalWithContent('deleteTestCasesBulk')}
                disabled={selected === 0}
              >
                Delete
              </button>

              <button
                className="dropdown-item"
                type="button"
                onClick={showModalWithContent('moveTestCasesBulk')}
                disabled={selected === 0}
              >
                Move
              </button>
            </div>
          </div>
        </div>
        {/* select all checkbox and results label */}
        <div className="p-0 text-right right-pane">
          <TotalSelectedBadge
            available={available}
            selected={selected}
            total={total}
          />

          <div className="hosting-select-all">
            <Checkbox onChange={onSelectAll} checked={selectedAll} /> Select all
          </div>

          <button
            key="submit"
            type="button"
            onClick={showModalWithContent('submitTestCases')}
            disabled={selected === 0}
            className="btn btn-primary"
          >
            Submit tests
          </button>
        </div>

        {/* search bar */}
      </section>
      <hr />
      <section className="search-pagination d-flex justify-content-between">
        <div className="form-group col-md-3 pl-0">
          <input {...searchProps} />
        </div>
      </section>
    </>
  );
};

export default HostingControls;
