import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setDefaultFilter } from 'store/actions/jobsQueue_actions';
import { submitOrder } from 'store/actions/quarry_actions';

import Modal from 'components/Modal';
import FlashMessage from 'components/FlashMessage';
import crypto from 'crypto';
import { getPublicKey } from '../../../utils/api.service';
import Tooltip from '../../../components/Tooltip';

class ProductsModal extends React.Component {
  static propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    testCase: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    loggedInUserEmail: PropTypes.string,
    selectedProduct: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  };

  static defaultProps = {
    fields: [],
    options: [],
  };

  state = {
    selectedOption: {},
    fieldValues: {
      quantity: '',
    },
    errors: {},
  };

  constructor() {
    super();

    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.handleSubmitFormClicked = this.handleSubmitFormClicked.bind(this);
  }

  componentDidMount() {
    const fieldValues = {};

    // set default values
    this.props.fields.forEach((field) => {
      fieldValues[field.field] = field.default;
    });

    this.setState({ fieldValues });
  }

  handleFormFieldChange(field) {
    return (value) => {
      this.setState((state) => {
        return { fieldValues: { ...state.fieldValues, ...{ [field]: value } } };
      });
    };
  }

  async handleSubmitFormClicked() {
    let password = '';
    if (this.state.fieldValues.password) {
      let publicKey = await getPublicKey();
      console.log(this.state.fieldValues.password);
      const encryptedPassword = crypto
        .publicEncrypt(
          {
            key: publicKey,
            padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
          },
          Buffer.from(this.state.fieldValues.password)
        )
        .toString('base64');
      password = encryptedPassword;
    }

    const payload = {
      env: 'stg',
      origin: 'quarry/products',
      orderid: this.props.testCase.orderid,
      ordertype: this.props.testCase.ordertype,
      fulfillment: 'custom',
      subcategoryname: 'BIC',
      notificationemail: this.props.loggedInUserEmail,
      items: [
        {
          store: this.state.fieldValues.store,
          emailId: this.state.fieldValues.emailId,
          password: password,
          productName: this.props.testCase.offeringName,
          offeringId: this.props.testCase.offeringId,
          quantity: this.state.fieldValues.quantity || '1',
          term: this.state.fieldValues.term,
          termDesc: this.props.testCase.offers.filter(
            (o) => o.code === this.state.fieldValues.term
          )[0].description,
          accessModel: this.props.testCase.accessModel,
          accessModelDesc: this.props.testCase.accessModelDescription,
          usageType: this.props.testCase.intendedUsage,
          usageTypeDesc: this.props.testCase.intendedUsageDescription,
          connectivity: this.props.testCase.connectivity,
          connectivityDesc: this.props.testCase.connectivityDescription,
          servicePlan: this.props.testCase.servicePlanOffering,
          servicePlanDesc: this.props.testCase.servicePlanOfferingDescription,
          lineitems: [],
        },
      ],
    };

    let mandatoryFieldsUpdated = this.props.fields.reduce((acc, f) => {
      if (f.mandatory === 'true') {
        if (!this.state.fieldValues[f.field]) {
          this.setState((state) => {
            return { errors: { ...state.errors, ...{ [f.field]: 'Error' } } };
          });
          return false;
        } else {
          this.setState((state) => {
            return { errors: { ...state.errors, ...{ [f.field]: '' } } };
          });
        }
      }
      return acc;
    }, true);

    if (mandatoryFieldsUpdated && payload.orderid) {
      this.props.submitOrder(payload, 'v2', 'bic');
      this.props.setDefaultFilter('testdata');
      this.props.history.push('/submit?from=products&f=true');
    }
  }

  renderOptions(field) {
    if (field.field === 'term') {
      const options = this.props.options;
      return options.map((option) => (
        <option key={option.code} name={option.description} value={option.code}>
          {option.description}
        </option>
      ));
    } else {
      const options = field.options;
      return options.map((option) => {
        console.log(option);
        return (
          <option
            key={Object.keys(option)[0]}
            name={Object.values(option)[0]}
            value={Object.values(option)[0]}
          >
            {Object.values(option)[0]}
          </option>
        );
      });
    }
  }

  renderFields(fields) {
    return fields.map((field) => {
      const onChange = this.handleFormFieldChange(field.field);
      let input = null;

      if (field.type === 'select') {
        const options = this.renderOptions(field);
        input = (
          <select
            className="form-control"
            name={field.field}
            onChange={(e) => onChange(e.target.value)}
          >
            {options}
          </select>
        );
      } else if (field.type === 'password') {
        input = (
          <input
            className="form-control"
            type="password"
            name={field.field}
            value={this.state.fieldValues[field.field]}
            onChange={(e) => onChange(e.target.value)}
          />
        );
      } else {
        input = (
          <input
            className="form-control"
            type="text"
            name={field.field}
            value={this.state.fieldValues[field.field]}
            onChange={(e) => onChange(e.target.value)}
          />
        );
      }

      let optional;
      const tooltip = field.description ? (
        <Tooltip text={field.description} />
      ) : null;
      optional =
        field.mandatory === 'false' ? (
          <span className="optional-field">Optional</span>
        ) : (
          <span className="mandatory-field">*</span>
        );

      if (field.mandatory && field.mandatory.field) {
        let obj = field.mandatory.field;
        const isMandatory = Object.keys(obj).every(
          (k) => this.state[k] === obj[k]
        );
        optional = isMandatory === false ? <span>Optional</span> : null;
      }
      const hasError = this.state.errors[field.field] ? ' has-error' : '';

      return (
        <div key={field.field} className={`col-sm-6 form-group ${hasError}`}>
          <label htmlFor={field.field}>
            {field.name}
            {optional}
            {tooltip}
          </label>
          {input}
        </div>
      );
    });
  }

  renderForm() {
    const fields = this.renderFields(this.props.fields);

    return (
      <div className="modal-form">
        <div className="row">{fields}</div>
      </div>
    );
  }

  render() {
    const modalProps = {
      title: this.props.selectedProduct,
      onClose: this.props.onClose,
      onSubmit: this.handleSubmitFormClicked,
      submitEnabled: !!this.state.selectedOption,
    };
    const form = this.renderForm();

    return (
      <Modal {...modalProps}>
        <FlashMessage />
        {form}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
  submitOrder: (payload, version, channel) =>
    dispatch(submitOrder(payload, version, channel)),
});

export default connect(null, mapDispatchToProps)(ProductsModal);
