import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip';
import ConfirmationModal from '../ConfirmationModal';

const TestDataSection = (props) => {
  // state
  const [visible, setVisible] = useState(props.hasData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let onRemoveTestData = () => {
    setVisible(false);
    props.onTestDataRemove(props.elementId, props.env);
    setShowDeleteModal(false);
  };
  let renderDeleteModal = () => {
    if (showDeleteModal) {
      const modalProps = {
        onClose: () => setShowDeleteModal(false),
        onConfirm: onRemoveTestData,
        submitButtonText: 'Remove test data',
        message:
          'Proceeding will remove the key and value for this environment.',
      };
      return <ConfirmationModal {...modalProps} />;
    }
  };

  let onAddClick = () => {
    props.onTestDataAdd(props.elementId, props.env);
    setVisible(true);
  };
  return (
    <div className="test-data-section">
      <div className="section-header">
        <h2 className="section-title">{props.env}</h2>
        {!visible && (
          <React.Fragment>
            <button
              onClick={onAddClick}
              title="Add will copy key and values from all the environments"
              className="btn btn-primary actions"
            >
              Add Data
            </button>
            <Tooltip text="Add will copy key and values from all the environments" />
          </React.Fragment>
        )}
        {visible && (
          <React.Fragment>
            <button
              onClick={() => setShowDeleteModal(true)}
              title="Remove will delete all the key and values for this environments"
              className="btn btn-primary actions remove"
            >
              Remove
            </button>
            <Tooltip text="Remove will delete all the key and values for this environments" />
          </React.Fragment>
        )}
      </div>
      {visible && <div>{props.content}</div>}
      {renderDeleteModal()}
    </div>
  );
};

TestDataSection.propTypes = {
  content: PropTypes.object,
  env: PropTypes.string,
  hasData: PropTypes.bool,
  onTestDataRemove: PropTypes.func,
  elementId: PropTypes.string,
  onTestDataAdd: PropTypes.func,
};

TestDataSection.defaultProps = {
  type: '',
};

export default TestDataSection;
