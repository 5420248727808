import { meshTypes } from 'store/actions/mesh_actions';
import { Types } from 'store/actions/quarry_actions';

const meshReducerDefaultState = {
  isSending: false,
  submitRes: '',
  statusCode: '',
  catID: '',
  testCases: [],
  status:'',
  data: null
};

export default (state = meshReducerDefaultState, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case meshTypes.IS_SUBMITING:
      return {
        ...state,
        data: null,
        status: '',
        isSending: true,
      };
    case meshTypes.DONE_SUBMITTING:
      return {
        ...newState,
        result: action.payload,
        isSending: false,
      };
    case Types.RESET_SUCCESS:
      return {
        ...state,
        isSending: action.sending,
        message: action.message,
        statusCode: action.status
      };
    case meshTypes.SET_ID:
      return {
        ...state,
        catID: action.id,
      };
    case meshTypes.SET_MESH_OPTIONS:
      return {
        ...state,
        list: action.list
      };
    case meshTypes.RESET_DATA:
      return {
        ...state,
        list: action.list
      };
    case meshTypes.LIST_LOADING:
      return {
        ...state,
        listLoading: action.loading
      };
    case 'MESH_TESTCASE_REQUEST_COMPLETED':
      return {
        ...state,
        data: action.data,
        isSending: false,
        status: action.status
      };
    case 'MESH_CLEAR_TESTCASES':
      return {
        ...state,
        testCases: [],
        data: null,
        status:''
      };
    default:
      return state;
  }
};
