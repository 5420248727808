import axios from 'axios';
import Cache from '../Cache';
import endpoints from './endpoints';
import { getState, dispatch } from '../../store';
import { TYPES } from '../session.service';

const excludedCacheUrls = ['datastore/testdata/', 'datastore/masterdata'];

const cache = new Cache();

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use((request) => {
  const { user } = getState();
  if (user?.auth?.expires_at < +new Date()) {
    dispatch({
      type: TYPES.LOGOUT_PROCESS,
    });
  }
  if (
    request.method === 'get' &&
    process.env.REACT_APP_ENABLE_CACHE === 'true'
  ) {
    const { url, status, statusText, headers } = request;
    let cachedRequestData;

    cachedRequestData = cache.get(url);

    if (cachedRequestData) {
      request.data = cachedRequestData;

      request.adapter = () => {
        return Promise.resolve({
          data: cachedRequestData,
          status,
          statusText,
          headers,
          config: request,
          request: request,
        });
      };
    }
  }

  return request;
});

axios.interceptors.response.use((response) => {
  const { baseURL, url, cacheable, expiresIn, method } = response.config;
  if (
    method === 'get' &&
    cacheable &&
    process.env.REACT_APP_ENABLE_CACHE === 'true'
  ) {
    const isExcludedUrl = excludedCacheUrls.some((url) =>
      response.config.url.startsWith(url)
    );
    if (isExcludedUrl) return response;

    cache.set(url.replace(baseURL, ''), response.data, expiresIn);
  } else {
    cache.delete(url.replace(baseURL, ''));
  }

  if (response.status === 401) {
    dispatch({
      type: TYPES.LOGOUT_PROCESS,
    });
  }

  return response;
});

const NetworkRequest = {
  config: {
    credentials: {},
    defaultParams: {
      cacheable: true,
      crossDomain: true,
      expiresIn: 3600 * 24 * 1000, // 1 day
    },

    setCredentials: function (newConfig) {
      this.credentials = Object.assign({}, newConfig);
    },
  },

  cancelSource: axios.CancelToken.source(),

  replaceURLParams: function (url, params = {}) {
    Object.keys(params).forEach((key) => {
      url = url.replace(`:${key}`, params[key]);
    });

    return url;
  },

  abort: function (message) {
    this.cancelSource.cancel(message);
  },

  request: function (endpoint, params = {}, headers = {}) {
    const {
      user: { auth: user },
    } = getState();
    let url = endpoints[endpoint].url;

    if (params.params) {
      url = this.replaceURLParams(url, params.params);

      delete params.params;
    }

    return axios.request({
      url,
      ...(url.includes('hosting/v1/playwright/report') && {
        withCredentials: true,
      }),
      method: endpoints[endpoint].method || 'get',
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken,
        ...headers,
      },
      cancelToken: this.cancelSource.token,
      ...Object.assign({}, this.config.defaultParams, params),
    });
  },
};

export default NetworkRequest;
