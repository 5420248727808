import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
  getTestSuites,
  getTestSuite,
  postNewTestSuite,
  postPublishTestSuite,
  putUpdateTestSuite,
  getServiceEnvironments,
  putTestSuiteSchedule,
  postRunTestSuite,
  deleteTestSuiteSchedule,
} from 'utils/api.service';
import {
  getMeshTestCases,
  submitMeshTestCases,
} from 'store/actions/mesh_actions';
import { addNewNotification } from 'store/actions/notification_actions';
import { submitTestCases } from 'store/actions/hosting_actions';
import { getHostingProjectTestCases, deleteTestSuite } from 'utils/api.service';

export const Types = {
  SET_TEST_SUITES: 'suites/SET_TEST_SUITES',
  TEST_SUITE_SCHEDULE_UPDATED: 'suites/TEST_SUITE_SCHEDULE_UPDATED',
  TEST_SUITE_REMOVED: 'suites/TEST_SUITE_REMOVED',
};

export const getAllTestSuites = (services) => {
  return (dispatch) => {
    const requests = services.map((service) =>
      getTestSuites(service.servicename)
    );

    return Promise.all(requests).then((responses) => {
      const suites = responses
        .map((response) => response.data)
        .filter((response) => response.testsuites);

      dispatch({
        type: Types.SET_TEST_SUITES,
        suites,
      });

      return true;
    });
  };
};

export const getTestSuiteDetails = (id) => {
  return getTestSuite(id);
};

export const getEnvironments = (service) => {
  return getServiceEnvironments(service);
};

export const updateTestSuite = (id, payload) => {
  return (dispatch) => {
    return putUpdateTestSuite(id, payload)
      .then((response) => {
        dispatch(addNewNotification(response.data.detail, 'success'));

        return response;
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};

export const executeTestSuite = (payload, service, executionname) => {
  return (dispatch) => {
    // format: 3db2c7ad-7e9f-4ef9-a34a-f9579f31ba2e
    const executionid = uuidv4();
    const requests = [];

    payload.category
      .map((c) => c.subcategory)
      .flat()
      .forEach((subcategory) => {
        const environments = {};

        subcategory.testcases.forEach((testcase) => {
          if (!environments[testcase.environment]) {
            environments[testcase.environment] = [];
          }

          // sanitization
          Object.entries(testcase).forEach(([key, value]) => {
            if (!value) {
              delete testcase[key];
            }

            if (!testcase.lineitems) {
              testcase.lineitems = [];
            }
          });

          testcase.params = { ...testcase };

          // checking that every skutype has a lineitem
          if (testcase.skutype && Array.isArray(testcase.skutype)) {
            testcase.skutype.forEach((skutype, skuIndex) => {
              const lineitem = testcase.params.lineitems.find(
                (l) => l && l.skutype === skutype.type
              );

              // if there are no lineitems with the current skutype
              if (!lineitem) {
                let { defaultvalues } = skutype;
                let newLineItem = {};

                if (!defaultvalues) {
                  defaultvalues = {
                    partnumber: 'default',
                    quantity: 1,
                  };
                }

                newLineItem = {
                  partnumber: defaultvalues.partnumber,
                  quantity: parseInt(defaultvalues.quantity),
                  skutype: skutype.type,
                  displayname: skutype.displayname,
                };

                if (testcase.ismultiple) {
                  testcase.params.lineitems.push(newLineItem);
                } else {
                  testcase.params.lineitems[skuIndex] = newLineItem;
                }
              }
            });
          }

          environments[testcase.environment].push(testcase);
        });

        Object.entries(environments).forEach(([env, items]) => {
          let data = {
            executionname,
            executionid,
            env,
            items,
            notificationemail: payload.notificationEmail || '',
          };
          let submit;

          if (service === 'cjt') {
            submit = submitMeshTestCases(data, subcategory.subcategoryname);
          } else {
            const project = data.items[0].projectname;

            data.workstreamname = data.items[0].workstreamname;
            data.testcases = [...data.items];

            delete data.items;

            submit = submitTestCases(project, data);
          }

          requests.push(submit(dispatch));
        });
      });

    return Promise.all(requests)
      .then(() => {
        dispatch(
          addNewNotification(
            <span>
              Test suite executed successfully.{' '}
              <Link to="/jobsqueue">Go to Jobs Queue</Link>
            </span>,
            'success'
          )
        );
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};

export const submitNewTestSuite = (service, payload) => {
  return (dispatch) => {
    return postNewTestSuite(service, payload)
      .then((response) => {
        dispatch(addNewNotification(response.data.detail, 'success'));
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};

export const shareTestSuite = (id, emails) => {
  const payload = {
    users: emails.map((user) => ({
      user: user.email,
      role: user.role,
    })),
  };

  return (dispatch) => {
    return postPublishTestSuite(id, payload)
      .then((response) => {
        dispatch(addNewNotification(response.data.status, 'success'));
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};

export const scheduleTestSuite = (id, payload) => {
  return (dispatch) => {
    return putTestSuiteSchedule(id, payload)
      .then((response) => {
        dispatch(addNewNotification(response.data, 'success'));
        dispatch(updateTestSuiteSchedule(id, payload));
      })
      .catch((error) => {
        dispatch(
          addNewNotification(
            error?.response.data.detail.split(':').slice(2).join(':') ||
              error.message,
            'danger'
          )
        );
      });
  };
};

export const runTestSuite = (id, payload) => {
  return (dispatch) => {
    return postRunTestSuite(id, payload)
      .then((_) => {
        dispatch(
          addNewNotification(
            <span>
              Test suite executed successfully.{' '}
              <Link to="/jobsqueue">Go to Jobs Queue</Link>
            </span>,
            'success'
          )
        );
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};

export const getMetafields = (data) => {
  const subcategories = [];

  const requests = data.payload.category
    .map((category) => {
      return category.subcategory.map((subcategory) => {
        subcategories.push(subcategory.subcategoryid);

        if (data.category === 'cjt') {
          return getMeshTestCases(
            category.categoryid,
            subcategory.subcategoryid
          );
        } else {
          const item = subcategory.testcases[0];

          return getHostingProjectTestCases(
            data.category,
            item.workstreamname,
            item.projectname
          );
        }
      });
    })
    .flat();

  return Promise.all(requests).then((responses) => {
    const metafields = {};

    responses
      .map((response) => response.data)
      .forEach((response, index) => {
        const id = response.subcategoryid || subcategories[index];
        let meta = response.metafields;

        if (!meta) {
          meta = { default: response.metafield };
        }

        metafields[id] = meta;
      });

    return metafields;
  });
};

export const removeTestSuite = (testSuiteId) => {
  // remove testsuite from state upon successful deletion request
  return (dispatch) => {
    return deleteTestSuite(testSuiteId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            addNewNotification('Testsuite successfully removed', 'success')
          );
          dispatch({ type: Types.TEST_SUITE_REMOVED, testSuiteId });
        }
      })
      .catch((error) => {
        dispatch(addNewNotification(error.response.data.detail, 'danger'));
      });
  };
};

export const updateTestSuiteSchedule = (testSuiteId, payload) => {
  // update testsuite state with schedule data
  return {
    type: Types.TEST_SUITE_SCHEDULE_UPDATED,
    testSuiteId,
    payload,
  };
};

export const removeTestSuiteSchedule = (testSuiteId) => {
  return (dispatch) => {
    return deleteTestSuiteSchedule(testSuiteId)
      .then((response) => {
        dispatch(addNewNotification(response.data, 'success'));
        dispatch(updateTestSuiteSchedule(testSuiteId, {}));
      })
      .catch((error) => {
        dispatch(addNewNotification(error.response.data.detail, 'danger'));
      });
  };
};
