const defaultState = {
  category: '',
  categories: {},
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'menu/SET_CATEGORIES':
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};
