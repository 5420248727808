import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Modal from 'components/Modal';
import LoadingSpinner from 'components/LoadingSpinner';
import CollapsibleContent from 'components/CollapsibleContent';
import Password from 'components/Password';

import { getTestCaseStatus, getTestDataStatus, getHostingStatus } from 'utils/api.service';
import downloadFile from 'utils/downloadFile';
import Attachment from "../../components/Attachment";

class JobsQueueModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    service: PropTypes.string.isRequired,
    record: PropTypes.object,
  }

  static defaultProps = {
    record: {},
  }

  state = {
    content: {},
    isLoading: true,
    name: '',
    screenshotInfo: {
      content: "",
      isVisible: false,
      title: "Screenshots",
      showScreenshotColumn: false
    }
  };


  constructor() {
    super();

    this.formatResponse = this.formatResponse.bind(this);
    this.renderPlain = this.renderPlain.bind(this);
    this.renderExecutions = this.renderExecutions.bind(this);
    this.onAnchorClicked = this.onAnchorClicked.bind(this);
    this.renderValidation = this.renderValidation.bind(this);
  }

  componentDidMount() {
    this.fetchDataStatus();
  }

  fetchDataStatus() {
    const {props} = this;
    const resultFn = {
      cjt: getTestCaseStatus,
      testdata: getTestDataStatus,
      default: getHostingStatus,
    };
    const fn = resultFn[props.service] || resultFn.default;

    if (!['submitted', 'in-progress'].includes(props.record.status)) {
      this.formatResponse({data: props.record});
    } else {
      (props.record.sharedby) ? 
      fn(props.record.executionid, props.record.transactionid,props.record.sharedby).then(this.formatResponse) :
      fn(props.record.executionid, props.record.transactionid).then(this.formatResponse) 
    }
  }

  onAnchorClicked(e) {
    e.preventDefault();

    downloadFile.fromURL(e.target.href);
  };

  formatResponse({data}) {
    const content = {};

    // if request returned
    if (data) {
      content.overall = [{
        key: 'Overall status',
        value: data.status,
      }];

      content.screenshots = data.screenshots

      if (data.message && data.message.includes('contact is not mapped')) {
        content.overall = [{
          key: 'Overall status',
          value: 'failed',
        }];
      }

      content.overall.push({key: 'Message', value: data.message});

      if(data.screenshots && data.screenshots.length > 0) {
        content.overall.push({
          key: 'Screenshots',
          value: data.screenshots.join("__")});
      }
      if(data.attachments && Object.keys(data.attachments).length > 0) {
        content.overall.push({
          key: 'Attachments',
          value: data.attachments});
      }

      if(data.outputfiles && Object.keys(data.outputfiles).length > 0) {
        content.overall.push({
          key: 'OutputFiles',
          value: data.outputfiles});
      }


      if (data.userinput && typeof data.userinput !== 'string') {
        let userInput = data.userinput;

        if (!Array.isArray(data.userinput) && typeof data.userinput === 'object') {
          userInput = [];

          Object.entries(data.userinput).forEach(([key, value]) => {
            Object.entries(value).forEach(([key, val]) => {
              if (key==='testdatafile' || key === 'testiterationfile') {  userInput.push({name: key, value: val});}
            })
            delete value.testdatafile;
            delete value.testiterationfile
            userInput.push({name: key, value: JSON.stringify(value)});
          });
        }

        content.userinput = userInput.map((entry) => {
          return { key: entry.name, value: entry.value };
        });
      }

      if (data.testdata) {
        let testdata = data.testdata;

        if (!Array.isArray(testdata)) {
          testdata = Object.entries(testdata).map((e) => ({name: e[0], value: e[1]}));
        }

        content.meta = testdata.map(entry => ({key: entry.name, value: entry.value}));
      }

      if (data.syncstatus) {
        content.syncstatus = data.syncstatus.map(entry => ({key: entry.sync, value: entry.status, screenshots: entry.screenshots}));
      }

      if (data.validations) {
        content.validations = data.validations.map(entry => {
          return {
            key: entry.validation || entry.sync || entry.name,
            value: entry.status,
            screenshots: entry.screenshots
          }
        });
      }

      if (data.steps) {
        content.steps = data.steps.map(entry => ({key: entry.name, value: entry.status,
          screenshots: entry.screenshots}));
      }

      if(data.executiontime && Object.keys(data.executiontime).length > 0) {
        content.executiontime =  Object.entries(data.executiontime).map(entry => ({key: entry[0], value: entry[1]}));
      }

      if (data.output) {
        content.output = data.output.map(entry => ({key: entry.name, value: entry.value}));
      }

      if (data.executions) {
        content.executions = data.executions.map(entry => ({
          key: entry.iterationame,
          value: entry.steps.map(step => ({key: step.name, value: step.status}))
        }));
      }

      if (data.logid) {
        content.logid = [{
          key: 'Log ID',
          value: data.logid,
        }];
      }

      if (data.ec2_ipaddress) {
        content.ec2_ipaddress = [{
          key: 'EC2 IP Address',
          value: data.ec2_ipaddress,
        }];
      }

      if (data.networklogs) {
        content.networklogs = [{
          key: 'Network Logs',
          value: data.networklogs,
        }];
      }

      this.setState({
        name: data.name,
        isLoading: false,
        content,
      });
    }

    return content;
  }

  renderObjectAsList(object) {
    if (object === null) {
      return '';
    }

    const listElements = Object.entries(object).map(([key, value]) => {
      let renderValue = value;

      if (typeof renderValue === 'object') {
        return this.renderObjectAsList(renderValue);
      }

      return <li><strong>{key}</strong>: {renderValue}</li>;
    });

    return (
      <ul className='jobs-queue-list'>
        {listElements}
      </ul>
    );
  }



  renderPlain(row) {
    let rowContent = row.value;

    try {
      rowContent = JSON.parse(row.value);
    } catch {
      rowContent = row.value;
    }

    if (rowContent && typeof rowContent === 'object') {
      rowContent = this.renderObjectAsList(rowContent);
    } else {
      if (['password', 'pwd', 'contact password'].includes(row.key.toLowerCase())) {
        rowContent = <Password value={row.value} />;
      }
    }

    if (row.key.toLowerCase() === 'testdatafile' || row.key.toLowerCase() === 'testiterationfile') {
      const onAnchorClicked = (e) => {
        e.preventDefault();

        downloadFile.fromURL(e.target.href);
      };
        const splittedValue = row.value.split('/');
        rowContent = <strong><a href={row.value} onClick={onAnchorClicked} download>{ splittedValue[splittedValue.length - 1] }</a></strong>;
    }

    if (row.key.toLowerCase() === 'screenshots') {
      rowContent = <Link to={`/screenshots?id=${row.value}`} target='_blank'>Screenshot</Link>;
    }
    if (row.key.toLowerCase() === 'attachments' && Object.keys(row.value).length > 0) {
      rowContent = Object.entries(row.value).map(([key, value]) => {
        return (<Attachment fileKey={value['key']} fileName={value['name']} fileExt={value['ext']} />);
      });

      rowContent = <div className='attachments'>{ rowContent }</div>;
    }

    if (row.key.toLowerCase() === 'outputfiles' && Object.keys(row.value).length > 0) {
      rowContent = Object.entries(row.value).map(([key, value]) => {
        return (<Attachment fileKey={value['key']} fileName={value['name']} fileExt={value['ext']} />);
      });

      rowContent = <div className='attachments'>{ rowContent }</div>;
    }

    return (
      <tr key={row.key}>
        <th scope='row'>{row.key}</th>
        <td>{rowContent}</td>
      </tr>
    );
  }


  renderValidation(row) {
    let queryParams = row.screenshots && row.screenshots.length >0 ? row.screenshots.join('__'): ''
    let screenshotColumn ='';
    if((row.screenshots && row.screenshots.length > 0) || this.state.screenshotInfo.showScreenshotColumn) {
      if(!this.state.screenshotInfo.showScreenshotColumn) {
        this.setState({screenshotInfo: { showScreenshotColumn: true}})
      }
      if(row.screenshots && row.screenshots.length > 0) {
        screenshotColumn = <td><Link to ={"/screenshots?id="+queryParams} target="_blank">Screenshot</Link></td>
      } else {
        screenshotColumn = <td></td>
      }
    }
    return (
      <tr key={row.key}>
        <th scope='row'>{row.key}</th>
        <td><span className={`job-status ${row.value}`}></span>{row.value}</td>
        {screenshotColumn}
      </tr>
    );
  }

  renderExecutions(row) {
    const rows = row.value.map(this.renderValidation);

    return (
      <CollapsibleContent title={row.key} key={row.key}>
        <table className='table table-bordered table-hover'>
          <tbody>
          {rows}
          </tbody>
        </table>
      </CollapsibleContent>
    );
  }

  renderTables(content) {
    const displayNames = {
      overall: '',
      userinput: 'User input',
      meta: 'Output',
      syncstatus: 'Validations',
      validations: 'Validations',
      steps: 'Steps',
      executiontime: 'Execution Time',
      output: 'Output',
      executions: 'Iterations',
      logid: '',
      ec2_ipaddress: '',
      networklogs: 'Network Logs',
    };
    const tables = [];

    Object.keys(displayNames).forEach((entry) => {
      if (content[entry] && content[entry].length && content[entry]) {
        if (entry !== 'executions') {
          const renderFn = {
            validations: this.renderValidation,
            steps: this.renderValidation,
            syncstatus: this.renderValidation,
            default: this.renderPlain,
            executiontime: this.renderPlain,
            networklogs: this.renderPlain,
          };
          const rows = content[entry].map(renderFn[entry] || renderFn.default);

          tables.push((
            <section className='jobs-queue-table'>
              {displayNames[entry] && (
                <h4>{displayNames[entry]}</h4>
              )}

              <table className='table table-bordered table-hover' key={entry}>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </section>
          ));
        } else {
          tables.push((
            <section className='jobs-queue-table'>
              {displayNames[entry] && (
                <h4>{displayNames[entry]}</h4>
              )}

              {content[entry].map(this.renderExecutions)}
            </section>
          ));
        }
      }
    });

    return (
      <div className='modal-content-wrapper'>
        {tables}
      </div>
    );
  }

  render() {
    const { props, state } = this;
    const modalProps = {
      cancelButtonText: 'Done',
      onClose: props.onClose,
      open: props.open,
      title: !state.name ? 'Getting data...' : state.name,
    };
    const tables = this.renderTables(state.content);
    const table = !state.isLoading ? tables : <LoadingSpinner text='Loading' />;

    return (
      <Modal {...modalProps}>
        { table }
      </Modal>
    );
  }

}

export default JobsQueueModal;
