import React, { useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/Modal';
import '@weave-design/fonts/build/ArtifaktElement.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cronstrue from 'cronstrue';
import {
  scheduleTestSuite,
  removeTestSuiteSchedule,
} from '../../store/actions/test_suites_actions';
import Tooltip from 'components/Tooltip';

// Custom input components for ReactDatePicker integration with Formik
const DatePickerField = ({ name, value, onChange, setFieldTouched }) => {
  return (
    <ReactDatePicker
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        onChange(name, val);
      }}
      placeholderText="Select date"
      holidays={[]}
      className="form-control form-group"
      showIcon
      popperPlacement="right"
      onChangeRaw={(e) => {
        setFieldTouched(name, true, true);
      }}
    />
  );
};

const validationSchema = Yup.object().shape({
  disabled: Yup.boolean(),
  cronExpression: Yup.string()
    .required('Cron Expression is required')
    .test('cronExpression', 'Invalid Cron Expression', (value) => {
      try {
        cronstrue.toString(value);
        return true;
      } catch (error) {
        return false;
      }
    })
    .test(
      'dayOfMonth',
      'Invalid combination. For Day of month and Day of week, if you specify a value or a * in one of the fields, you must use a ? in the other.',
      function (value) {
        const { dayOfWeek, dayOfMonth } = this.parent;
        if (
          (dayOfMonth !== '?' && dayOfWeek !== '?') ||
          (dayOfMonth === '*' && dayOfWeek === '*')
        )
          return false;
        return true;
      }
    ),
  dateStart: Yup.date()
    .required('Start Date is required')
    .min(
      new Date(new Date().setHours(0, 0, 0, 0)),
      'Start Date cannot be in the past'
    ),
  dateEnd: Yup.date().when('dateStart', (dateStart, schema) => {
    if (dateStart) {
      const dayAfter = new Date(
        new Date(dateStart).setDate(new Date(dateStart).getDate() + 1)
      );
      return schema.min(dayAfter, 'End Date cannot be before Start Date');
    }

    return schema;
  }),
});

const ScheduleModal = ({
  onClose,
  testSuiteId,
  schedule: { disabled, cronExpression, dateStart, dateEnd } = null,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initialValues = useMemo(() => {
    const parsedCronExpression =
      cronExpression?.split('(')[1].split(')')[0] || '';
    const cronExpressionFields = parsedCronExpression.split(' ');
    return {
      disabled: disabled || false,
      cronExpression: parsedCronExpression,
      minutes: cronExpressionFields[0] || '',
      hours: cronExpressionFields[1] || '',
      dayOfMonth: cronExpressionFields[2] || '',
      month: cronExpressionFields[3] || '',
      dayOfWeek: cronExpressionFields[4] || '',
      year: cronExpressionFields[5] || '',
      dateStart: dateStart
        ? new Date(dateStart.split(' ')[0] + 'T00:00:00')
        : new Date(),
      dateEnd: dateEnd
        ? new Date(dateEnd + 'T00:00:00')
        : new Date(new Date().setDate(new Date().getDate() + 1)),
    };
  }, [disabled, cronExpression, dateStart, dateEnd]);

  const onSubmit = async () => {
    formRef.current.submitForm();
    const errors = await formRef.current.validateForm();
    if (Object.keys(errors).length === 0) {
      // call api to save schedule
      setLoading(true);
      const updatedSchedule = {
        cron_expression: formRef.current.values.cronExpression,
        start_date: formRef.current.values.dateStart
          .toISOString()
          .split('T')[0],
        end_date: formRef.current.values.dateEnd.toISOString().split('T')[0],
        isdisabled: formRef.current.values.disabled,
      };
      dispatch(scheduleTestSuite(testSuiteId, updatedSchedule))
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onDelete = async () => {
    setLoading(true);
    dispatch(removeTestSuiteSchedule(testSuiteId)).then(() => {
      setLoading(false);
      onClose();
    });
  };

  const buildCronExpression = (values) => {
    const { minutes, hours, dayOfMonth, month, dayOfWeek, year } = values;
    const cronExpression = `${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek} ${year}`;
    return cronExpression;
  };

  return (
    <Modal
      title="Schedule a Test Suite"
      submitButtonText={'Save'}
      submitEnabled
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
      onDelete={cronExpression ? onDelete : null}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={(_) => {}}
      >
        {({ values, setFieldValue, errors, setFieldTouched }) => (
          <Form>
            <label>
              <Field type="checkbox" name="disabled" /> Disable
            </label>
            <br />

            <label>
              Cron Expression{' '}
              <Tooltip text="Valid cron expression. E.g., 0 8 1 * ? * (Run at 8:00 am UTC every 1st day of the month)" />
            </label>
            <div className="cron-section">
              <div className="form-control--cron">
                <label htmlFor="minutes">Minutes</label>
                <Field
                  name="minutes"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('minutes', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        minutes: event.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="form-control--cron">
                <label htmlFor="hours">Hours</label>
                <Field
                  label="Hours"
                  name="hours"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('hours', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        hours: event.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="form-control--cron">
                <label htmlFor="dayOfMonth">Day of Month</label>
                <Field
                  name="dayOfMonth"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('dayOfMonth', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        dayOfMonth: event.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="form-control--cron">
                <label htmlFor="month">Month</label>
                <Field
                  name="month"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('month', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        month: event.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="form-control--cron">
                <label htmlFor="dayOfWeek">Day of Week</label>
                <Field
                  name="dayOfWeek"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('dayOfWeek', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        dayOfWeek: event.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="form-control--cron">
                <label htmlFor="year">Year</label>
                <Field
                  name="year"
                  type="text"
                  className="form-control"
                  onChange={(event) => {
                    setFieldValue('year', event.target.value);
                    setFieldValue(
                      'cronExpression',
                      buildCronExpression({
                        ...values,
                        year: event.target.value,
                      })
                    );
                  }}
                />
              </div>
            </div>
            <Field
              type="hidden"
              name="cronExpression"
              onChange={(event) => {
                setFieldValue('cronExpression', event.target.value);
              }}
            />
            <ErrorMessage
              name="cronExpression"
              component="div"
              className="has-error-reason"
            />
            <label>Start Date</label>
            <br />
            <DatePickerField
              name="dateStart"
              value={values.dateStart}
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
            <ErrorMessage
              name="dateStart"
              component="div"
              className="has-error-reason"
            />
            <br />

            <label>End Date</label>
            <br />
            <DatePickerField
              name="dateEnd"
              value={values.dateEnd}
              onChange={setFieldValue}
              disabled={values.neverEnd}
              setFieldTouched={setFieldTouched}
            />
            <ErrorMessage
              name="dateEnd"
              component="div"
              className="has-error-reason"
            />
            <br />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ScheduleModal;
