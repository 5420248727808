import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class MenuDropdown extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  }

  static defaultProps = {
    items: [],
    title: '',
  }

  state = {
    open: false,
  }

  constructor() {
    super();

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    this.setState(state => ({ open: !state.open }));
  }

  render() {
    const { props, state } = this;
    const dropdownProps = {
      className: classname({
        'menu-dropdown': true,
        'open': state.open,
        'collapsed': !state.open,
      }),
    };
    const caretProps = {
      className: 'menu-dropdown-trigger',
      src: 'https://testing-hub-static-assets.s3.amazonaws.com/images/icons/arr-d.png',
    };

    return (
      <div {...dropdownProps}>
        <div className='menu-dropdown-title' onClick={this.toggleDropdown}>
          <h6 className='h6'>{ props.title }</h6>
          <img {...caretProps} alt='' />
        </div>

        <div className='menu-dropdown-body'>
          <ul className='menu-dropdown-items'>
            { props.items }
          </ul>
        </div>
      </div>
    );
  }
}

export default MenuDropdown;
