import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MultipleSelection = (props) => {
  // state
  const [elements, setElements] = useState(props.initialValue);
  const [numberOfElements, setNumberOfElements] = useState({});
  // user actions
  const simulateEvent = (value) => {
    return {
      target: {
        name: props.name,
        value,
      }
    };
  };
  const onChange = ({ target }) => {
    const { value } = target

    if (value && !elements.includes(value)) {
      elements.push(value);

      setElements(elements);
      setNumberOfElements(elements.length);
    }

    props.onChange(simulateEvent(elements));
  };
  const onRemoveClick = (element) => {
    return () => {
      const updatedElements = elements.filter(e => e !== element);

      setElements(updatedElements);
      props.onChange(simulateEvent(updatedElements));
    };
  };
  // render methods
  const options = props.options.map(o => <option value={o}>{o}</option>);
  const currentValues = elements.map(e => {
    return (
      <span key={e} className='list-input-element'>
        <strong>{ e }</strong>
        <button onClick={onRemoveClick(e)} className='btn'><i className='bi bi-x'></i></button>
      </span>
    );
  });

  return (
    <React.Fragment>
      <select data-number={numberOfElements} className='form-control' name={props.name} onChange={onChange}>
        <option></option>
        { options }
      </select>

      <div className='list-input-values'>
        { currentValues }
      </div>
    </React.Fragment>
  );
};

MultipleSelection.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.string),
};

MultipleSelection.defaultProps = {
  initialValue: [],
};

export default MultipleSelection;
