import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class Channels extends React.PureComponent {
  static propTypes = {
    channels: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired,
    selectedChannel: PropTypes.string.isRequired,
    environment: PropTypes.string,
    actions: PropTypes.array,
    activeChannelSecondaryAction: PropTypes.object,
  }

  static defaultProps = {
    environment: '',
    actions: [],
    activeChannelSecondaryAction: null,
  }

  handleClick(subcategoryid) {
    return () => {
      this.props.onClick(subcategoryid)
    }
  }

  render() {
    const actions = this.props.actions.map(action => {
      const buttonProps = {
        key: `action-${action.name}`,
        className: 'action-button',
        onClick: action.onClick,
      };

      return <button {...buttonProps}>{ action.text }</button>
    });
    const channels = this.props.channels.map(channel => {
      const { subcategoryname, subcategoryid } = channel;
      const buttonProps = {
        key: `channel-${subcategoryid}`,
        onClick: this.handleClick(subcategoryid),
      };
      const wrapperProps = {
        key: subcategoryid,
        className: classname({
          'channel-wrapper': true,
          'active': channel.subcategoryid === this.props.selectedChannel,
        }),
      };
      let secondaryAction = null;

      if (channel.subcategoryid === this.props.selectedChannel && this.props.activeChannelSecondaryAction) {
        const secondaryActionProps = {
          className: 'secondary-action-button',
          onClick: this.props.activeChannelSecondaryAction.onClick,
        };

        secondaryAction = <button {...secondaryActionProps}>{ this.props.activeChannelSecondaryAction.text }</button>
      }

      if (this.props.environment && channel.status[this.props.environment] === 'disabled') {
        buttonProps.onClick = () => {};
        buttonProps.className += ' disabled';
      }

      return (
        <div {...wrapperProps}>
          <button {...buttonProps}>{ subcategoryname }</button>
          { secondaryAction }
        </div>
      );
    });

    return (
      <section className='channels'>
        { actions }
        { channels }
      </section>
    );
  }
}

export default Channels;
