import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

import ListItem from 'components/ListItem';

function List(props) {
  // state
  const initialValue = props.singleValue ? [] : {};
  const [elements, setElements] = useState(props.initialValue || initialValue);
  const [lastUpdate, setLastUpdate] = useState(0);
  // refs
  const keyInput = useRef(null);
  const valueInput = useRef(null);
  const selectInput = useRef(null);
  // methods
  const onAddClick = () => {
    if (!(props.name === 'supportedenv' && elements.includes(keyInput.current.value))) {
      // remove error class
      keyInput.current.classList.remove('is-invalid');

      if (keyInput.current.value) {
        if (props.singleValue) {
          elements.push(keyInput.current.value);
        } else {
          const value = valueInput.current ? valueInput.current.value : selectInput.current.value;

          elements[keyInput.current.value] = value;
        }

        setElements(elements);
        setLastUpdate(+new Date());

        keyInput.current.value = '';

        if (!props.singleValue && valueInput.current) {
          valueInput.current.value = '';
        }

        // simulate native input response
        props.onChange({
          target: { name: props.name, objectPath: props.objectPath, elements, value: (props.singleValue ? elements.filter(e => e) : elements) },
        });
      }
    } else {
      // value is duplicated
      keyInput.current.classList.add('is-invalid');
    }
  };
  const onListItemChange = (key) => {
    return (value) => {
      elements[key] = value;

      setElements(elements);
      setLastUpdate(+new Date());
    };
  };
  const onListItemRemove = (key) => {
    return () => {
      let value = elements;

      if (props.singleValue) {
        value.splice(parseInt(key), 1);
      } else {
        delete value[key];
      }

      setElements(value);
      setLastUpdate(+new Date());

      if (props.singleValue) {
        value = value.filter(e => e)
      }

      // simulate native input response
      props.onChange({
        target: { name: props.name, value, objectPath: props.objectPath },
      });
    };
  };
  // render methods
  const currentValues = Object.entries(elements).map(([key, value]) => {
    if (typeof value === 'string') {
      return (
        <ListItem
          key={key}
          itemKey={key}
          onRemove={onListItemRemove(key)}
          onChange={onListItemChange(key)}
          readOnly={props.readOnly}
          isSubmitWindow={props.isSubmitWindow}
          editable={props.editable}
          singleValue={props.singleValue}
          value={value}
        />
      );
    }

    return null;
  });
  let secondaryField;

  if (props.options) {
    const options = props.options.map((option) => <option value={option}>{ option }</option>);

    secondaryField = (
      <select className='form-control' ref={selectInput}>
        { options }
      </select>
    );
  } else {
    secondaryField = <input className='form-control' ref={valueInput} placeholder='value' readOnly={props.readOnly} />
  }

  return (
    <React.Fragment>
      { !props.isSubmitWindow && <div className={classname({ 'list-input-wrapper': true, 'disabled': props.readOnly })}>
        { !props.readOnly && <input className='form-control' ref={keyInput} placeholder={props.keyLabel} readOnly={props.readOnly} /> }

        { !props.readOnly && !props.singleValue && secondaryField }

        { !props.readOnly && <button onClick={onAddClick} className='btn btn-primary'>Add</button> }
      </div>}

      { !props.isSubmitWindow  && !props.readOnly && <small className='form-text text-muted'>Click on *add* to save an element.</small> }

      <div className={classname({ 'list-input-values': true, 'disabled': props.readOnly })} data-last-update={lastUpdate}>
        { currentValues }
      </div>
    </React.Fragment>
  );
}

List.propTypes = {
  initialValue: PropTypes.object,
  keyLabel: PropTypes.string,
};

List.defaultProps = {
  keyLabel: 'Key',
};

export default List;
