import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

const NotificationField = (props) => {
    // state
  const [notifyMe, setNotifyMe] = useState(true);
  const [notifyAll, setNotifyAll] = useState(false);
  const [emailList, setEmailList] = useState([props.value.trim()]);
  const [ele, setEle] = useState();
  let textarea =  useRef();
  const [cursorPosition, setCursorPosition] = useState(false);

  const onNotifyMeClick = () => {
    setNotifyMe(!notifyMe);

        if (!notifyMe) {
            if (emailList.join(",").indexOf(props.loggedInUserEmail.trim()) === -1) {
                setEmailList(
                    [props.loggedInUserEmail, ...props.value.split(",")].filter(e=>e));
            }
        } else {
            if (emailList.join(",").indexOf(props.loggedInUserEmail.trim()) !== -1) {
                setEmailList(
                    [...props.value.split(",").filter(e => e.trim() !== props.loggedInUserEmail.trim()).filter(e=>e)]);
            }
        }

    };

    const onNotifyAllClick = () => {
        setNotifyAll(!notifyAll);

        if (!notifyAll) {
            if(props.notifyAllValue.indexOf(props.loggedInUserEmail.trim()) === -1) {
              setEmailList([...props.value.split(","), ...props.notifyAllValue.split(",")].filter(e=>e))
            } else {
              setNotifyMe(true)
              setEmailList([...props.notifyAllValue.split(",")].filter(e=>e))
            }

        } else {
            setEmailList([...props.value.split(",").filter(e => {
                if (e.trim() === props.loggedInUserEmail.trim()) return e;
                return null;
            }).filter(e=>e)])
        }
    };

    const onChange = (e) => {
        let element = e.target
        let value = element.value

        setCursorPosition(element.selectionStart);

        if(!value || value.indexOf(props.loggedInUserEmail) === -1) {
            setNotifyMe(false)
        }

        if(value.indexOf(props.loggedInUserEmail) !== -1) {
            setNotifyMe(true)
        }

        setEle(element)
        setEmailList(value.split(","));

    }

    useEffect(() => {
        props.onChange(emailList.join(",").toLowerCase())

        window.requestAnimationFrame(() => {
            if(ele && cursorPosition) {
                ele.selectionStart = cursorPosition
                ele.selectionEnd = cursorPosition
            }
        })
    }, [emailList]);

    useEffect(()=>{

      if(props.value.split(",").length > 1 ||
        (props.value && props.value.split(",").length === 1 && props.value !== props.loggedInUserEmail)) {
        setNotifyAll(true)
      }

      if(props.value.indexOf(props.loggedInUserEmail) === -1){
        setNotifyMe(false)
      }
    }, [props.value])

    // render

    let checkBoxField = (
        <div className='form-group'>
            <label htmlFor={props.name}>Report Notification</label>
            <div className='notification-field-checkbox-group'>
                <div className='notification-field-checkbox form-group'>
                    <input name='notifyMe' checked={notifyMe} type='checkbox' onChange={onNotifyMeClick}/>
                    <label htmlFor='notification'>Notify Me</label>
                </div>
                <div className='notification-field-checkbox form-group'>
                    <input name='notifyAll' checked={notifyAll} type='checkbox' onChange={onNotifyAllClick}/>
                    <label htmlFor='notification'>Notify Others</label>
                </div>
            </div>
        </div>
    )
    let field = checkBoxField

    if (notifyAll) {
        field = (
            <div className='notification'>
                {checkBoxField}
                <div className='form-group'>
                    <label htmlFor={props.name}>Emails</label>
                    <textarea ref={textarea} className='form-control' name={props.name} type='text' value={props.value}
                              onChange={onChange}/>
                </div>
            </div>
        );
    }

    return field;
};

NotificationField.propTypes = {
  notifyAllValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  loggedInUserEmail: PropTypes.string
};

NotificationField.defaultProps = {
  notifyAllValue: '',
  optional: true,
  value: ''
};

export default NotificationField;
