import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

import EnvironmentSwitch from 'components/EnvironmentSwitch';
import TestCase from 'components/TestCase';
import LoadingSpinner from 'components/LoadingSpinner';
import filterList from 'selectors/search';
import ProductsModal from './modals/ProductsModal';

class Products extends React.Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    testCases: PropTypes.array.isRequired,
    metaFields: PropTypes.array.isRequired,
    handleChannelButtonClicked: PropTypes.func.isRequired,
    loadingChannels: PropTypes.bool.isRequired,
    loadingTestCases: PropTypes.bool.isRequired,
    selectedChannelID: PropTypes.string.isRequired,
  };

  state = {
    searchValue: '',
    showModal: false,
    selectedProduct: null,
    testCase: null,
    productFields: [],
    productOptions: [],
    loadingFields: false,
    options: [],
  };

  constructor() {
    super();

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeEnvironment !== this.props.activeEnvironment) {
      this.props.onEnvironmentChange();

      // if (this.state.selectedTestCaseID) {
      //   this.getTestCaseFields(this.state.selectedTestCaseID, true);
      // }
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSearchInputChange({ target }) {
    this.setState({ searchValue: target.value });
  }

  handleTestCaseButtonClicked(testCase) {
    return () => {
      const fields = this.props.metaFields.map((f) => {
        if (f.type === 'select' && f.field === 'term') {
          f.default = testCase.offers[0].code;
        }
        return f;
      });
      this.setState({
        selectedProduct: testCase.offeringName,
        testCase: testCase,
        productFields: fields,
        loadingFields: false,
        showModal: true,
        options: testCase.offers,
      });
    };
  }

  renderFiltersAndButtons() {
    const { props, state } = this;
    const buttons = [];
    const containerProps = {
      className: classname({
        'orders-search-filter-actions': true,
        empty: !props.testCases.length,
      }),
    };
    let search = null;

    if (props.testCases.length) {
      const searchProps = {
        type: 'search',
        onChange: this.handleSearchInputChange,
        value: this.state.searchValue,
        name: 'search',
        className: 'form-control',
        placeholder: 'Search tests',
      };

      search = (
        <div className="form-group col-md-3 pl-0">
          <input {...searchProps} />
        </div>
      );
    }

    if (state.selectedProduct) {
      if (state.loadingFields) {
        buttons.push(
          <LoadingSpinner text="Loading fields" key="loading-spinner" />
        );
      }
    }

    return (
      <div {...containerProps}>
        {search}

        <div className="orders-actions">{buttons}</div>
      </div>
    );
  }

  renderTestCases(testCases) {
    if (this.props.loadingTestCases && !testCases.length) {
      return <LoadingSpinner text="Loading products" />;
    } else {
      const tests = filterList(
        testCases,
        this.state.searchValue,
        'offeringName'
      ).map((testCase) => {
        const testCaseProps = {
          checked: testCase.offeringName === this.state.selectedProduct,
          name: testCase.offeringName,
          testcaseid: '04563e84-97b8-4b90-9131-4367823c46c9',
          onItemSelected: this.handleTestCaseButtonClicked(testCase),
        };

        if (testCaseProps.checked && !this.state.loadingFields) {
          testCaseProps.customLabel = 'Select a version to test';
          testCaseProps.customLabelOnClick =
            this.handleTestCaseButtonClicked(testCase);
        }

        return (
          <div
            className="scenario"
            key={testCase.offeringName.split(' ').join('-')}
          >
            <TestCase radio {...testCaseProps} />
          </div>
        );
      });
      const emptyItems = [
        ...Array(Math.ceil(tests.length / 3) * 3 - tests.length).keys(),
      ];
      const placeholders = emptyItems.map((idx) => (
        <div className="scenario empty" key={`empty-${idx}`}></div>
      ));

      return [...tests, ...placeholders];
    }
  }

  renderModal() {
    const { props, state } = this;

    if (this.state.showModal) {
      const modalProps = {
        fields: state.productFields,
        testCase: state.testCase,
        selectedProduct: state.selectedProduct,
        history: props.history,
        onClose: this.closeModal,
        loggedInUserEmail: this.props.userInfo.emailid,
        options: this.state.options,
      };

      return <ProductsModal {...modalProps} />;
    }

    return null;
  }

  render() {
    const buttons = this.renderFiltersAndButtons();
    const channels = this.props.renderChannels(this.props.channels);
    const testCases = this.renderTestCases(this.props.testCases);
    const modal = this.renderModal();
    const environmentProps = {
      environments: this.props.environments,
      context: 'Orders',
      value: this.props.activeEnvironment,
    };

    return (
      <div className="products-container">
        <section className="section-title">
          <div className="section-title-group">
            <h2 className="title">{this.props.pageHeader}</h2>
            <a
              href="https://wiki.autodesk.com/display/DES/Test+Data+Use+Cases"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is this?
            </a>
          </div>

          <EnvironmentSwitch {...environmentProps} />
        </section>

        <section className="products-channels">{channels}</section>

        <section className="products-action-buttons">{buttons}</section>

        <div className="products-test-cases">{testCases}</div>

        {modal}
      </div>
    );
  }
}

export default Products;
