import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { submitMeshTestCases } from 'store/actions/mesh_actions';

import Loader from 'components/Loader';
import { SuccessIcon, ErrorIcon } from './Icons';

class Submit extends React.Component {
  constructor() {
    super();

    this.retryFailedTests = this.retryFailedTests.bind(this);
  }

  retryFailedTests() {
    const testcases = this.props.testCases.filter(test => test.status === 'rejected');

    testcases.forEach(t => {
      if (!t.attempt) {
        t.attempt = 1;
      }

      t.attempt += 1;
    });

    this.props.submitTests({ testcases });
  }

  renderMessage() {
    const { props } = this;
    const data = props.data
    let icon = <Loader />;
    let message = 'Processing requests';
    let reason = null;

    if ((data && data.title === 'error') || props.status > 299 ||
      (data && data.message && data.message.toLowerCase() === 'network error')) {
      icon = <ErrorIcon color='#f44236' />;
      message = 'Request failed';
      reason = data.detail || data.message;
    } else {
      if (!props.isLoading) {
        icon = <SuccessIcon color='#73af55' />;
        message = data.detail;
      }
    }

    return (
      <React.Fragment>
        { icon }

        <hgroup>
          <h5 className='success'>{ message }</h5>
          <h6 className='success'>{ reason }</h6>
        </hgroup>
      </React.Fragment>
    );
  }

  renderActionButtons(failedTests) {
    const buttonProps = {
      type: 'button',
      className: 'button button-primary',
    };
    let tryAgainButton = null;
    let goBackButton = null;
    let jobsQueueButton = null;
    
    // show only when tests failed
    if (failedTests.length) {
      tryAgainButton = <button {...buttonProps} onClick={this.retryFailedTests}>Try again</button>
    }
    
    // show only when all tests passed
    if (!this.props.isLoading && !failedTests.length) {
      const linkProps = {
        to: '/jobsqueue',
        className: 'button button-primary',
      };
      const query = queryString.parse(window.location.search);
      const buttonText = `Submit more ${query.from || ''}`;

      jobsQueueButton = <Link {...linkProps}>Go to Jobs Queue</Link>;
      goBackButton = <button {...buttonProps} onClick={this.props.history.goBack}>{ buttonText }</button>;
    }

    return (
      <React.Fragment>
        { tryAgainButton }
        { goBackButton }
        { jobsQueueButton }
      </React.Fragment>
    );
  }

  renderStatus(test) {
    switch(test.title) {
      case 'success':
        return 'Success';
      default:
        return <span className='status-failed'>Failed</span>;
    }
  }

  renderTests(tests) {
    return tests.map((test) => {
      const status = this.renderStatus(test);
      let reason = '';

      reason =test.detail || test.title;

      return (
        <div className='submit-test' key={test.testcaseid}>
          <span className='status'>{ status }</span>
          <span className='name'>{ test.testcasename }</span>
          <span className='reason'>{ reason }</span>
        </div>
      );
    });
  }

  render() {
    const { props } = this;
    let failedTests = []
    if(props.data && props.data.error && props.data.error.length > 0){
      failedTests = props.data.error
    }
    // const failedTests = errors.filter(test => test.status === 'rejected');
    const message = this.renderMessage(props.response);
    const tests = this.renderTests(failedTests);
    const buttons = this.renderActionButtons(failedTests);

    return (
      <React.Fragment>
        <div className='status-indicator'>
          { message }
        </div>

        <div className='submit-buttons'>
          { buttons }
        </div>

        {tests.length > 0 && (
          <div className='submit-tests-container'>
            { tests }
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.mesh.isSending || state.quarry.isSending || state.hosting.isSubmitting,
  data: state.mesh.data || state.quarry.data || state.hosting.data,
  status: state.mesh.status || state.quarry.status || state.hosting.status,

  //below can be removed in future if we decide to remove retry button code completely
  message: state.mesh.message || state.quarry.message || state.hosting.message,
  testCases: state.mesh.testCases
});

const mapDispatchToProps = (dispatch) => ({
  submitTests: (tests) => dispatch(submitMeshTestCases(tests, 'e2e')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
