import React from 'react';
import ReactTooltip from 'react-tooltip';

const TestData = React.memo(
  ({ testDataEntries, onInputChange, onDeleteTestData, isDeleteDisabled }) => {
    const handleInput = (key, e) => {
      const newValue = e.target.value;
      onInputChange(key, newValue);
    };
    const testDataElements = Object.entries(testDataEntries);

    if (testDataElements.length === 0) {
      return null;
    }

    return (
      <div className="test-data-group">
        <ReactTooltip id="remove-tooltip" place="top" effect="solid">
          Remove test data clone
        </ReactTooltip>
        <button
          onClick={onDeleteTestData}
          className="test-data-remove"
          disabled={isDeleteDisabled}
          data-tip
          data-for="remove-tooltip"
        >
          +
        </button>
        {testDataElements
          .sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
          .map(([key, value], index) => (
            <div
              className={
                testDataElements.length === 1 ? 'col-sm-12' : 'col-sm-6'
              }
              key={index}
            >
              <div className="form-group form-group-inline">
                <label>{key}: </label>{' '}
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleInput(key, e)}
                  className="form-control"
                />
              </div>
            </div>
          ))}
      </div>
    );
  }
);

export default TestData;
