import React from 'react';

import './LoadingSpinner.sass';

const LoadingSpinner = ({ text }) => {
  return (
    <div className='loading-spinner-wrapper'>
      <span className='loading-spinner'></span>
      <span>{ text }</span>
    </div>
  );
}

export default LoadingSpinner;
