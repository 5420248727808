import React, { useCallback } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import LoadingSpinner from 'components/LoadingSpinner';

const TestCasesTable = ({ data, columns, isLoading }) => {
  const noDataComponent = useCallback(
    () => (
      <div className="table-loading-placeholder">
        {isLoading ? (
          <LoadingSpinner text="Loading records" />
        ) : (
          'No records to show'
        )}
      </div>
    ),
    [isLoading]
  );

  return (
    <ReactTable
      data={data}
      columns={columns}
      showPagination={false}
      pageSize={data.length}
      className="-highlight -darker"
      resizable={false}
      NoDataComponent={noDataComponent}
    />
  );
};

export default TestCasesTable;
