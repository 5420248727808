import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setDefaultFilter } from 'store/actions/jobsQueue_actions';
import { submitOrder } from 'store/actions/quarry_actions';
import CustomSKUInput from 'components/CustomSKUInput';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import FlashMessage from 'components/FlashMessage';
import NotificationField from '../../../components/NotificationField';

class OrderModal extends React.Component {
  static propTypes = {
    channelName: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object),
    fulfillment: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedTest: PropTypes.object.isRequired,
    environment: PropTypes.string.isRequired,
  };

  state = {
    fields: [],
    lineitems: [],
    temporaryLineItems: {},
    notificationEmail: '',
    errors: {},
    accounttype: 'existing',
  };

  mounted = false;

  constructor() {
    super();

    this.handleFormField = this.handleFormField.bind(this);
    this.handleSKUs = this.handleSKUs.bind(this);
    this.handleSubmitFormClicked = this.handleSubmitFormClicked.bind(this);
    this.handleInitialOrder = this.handleInitialOrder.bind(this);
    this.updateTemporaryLineItem = this.updateTemporaryLineItem.bind(this);
    this.onNotificationFieldChange = this.onNotificationFieldChange.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    let fields = {};
    let salesorg, store;

    if (props.fulfillment === 'prefill') {
      salesorg = props.fields.find((f) => f.field === 'salesorg') || {
        default: '3000',
      };
      store = props.fields.find((f) => f.field === 'store') || {};
    }

    // This loops through the field props and adds the default values into the state.
    props.fields.forEach((field) => {
      if (
        props.fulfillment === 'prefill' &&
        field.field !== 'addseatquantity'
      ) {
        return;
      }

      if (field.type === 'checkbox') {
        fields[field.field] = field.default;
      } else {
        fields[field.field] = field.default || '';
      }
    });

    if (salesorg || store) {
      fields = {
        ...fields,
        ...{ salesorg: salesorg.default, store: store.default },
      };
    }

    this.mounted = true;
    this.setState({
      ...fields,
      notificationEmail: this.props.userInfo.emailid || '',
    });
  }

  updateTemporaryLineItem(selectedOption, quantity, skuType) {
    const key = this.props.channelName === 'BIC' ? 'priceid' : 'partnumber';
    const temp = this.state.temporaryLineItems;

    if (selectedOption.sku) {
      temp[skuType] = {
        [key]: selectedOption.sku,
        quantity: +quantity,
        skutype: skuType,
        productname: selectedOption.name,
      };
    } else {
      delete temp[skuType];
    }

    this.setState({ temporaryLineItems: temp });
  }

  onNotificationFieldChange(value) {
    this.setState({ notificationEmail: value });
  }

  canSubmitForm() {
    const isFormValid = this.validate(null, true);
    const tempLineItems = Object.keys(this.state.temporaryLineItems).length;
    const lineItems = Object.keys(this.state.lineitems).length;

    if (
      [
        'dpeskuoffering',
        'addseat',
        'return',
        'renewal',
        'aoe_addseat',
        'pws_addseat',
        'reference',
        'switch',
        'validation',
        'refund',
        'creditmemo',
        'tokenconsumption',
        'productorder',
      ].includes(this.props.selectedTest.ordertype)
    ) {
      return isFormValid;
    }

    return isFormValid && (lineItems > 0 || tempLineItems > 0);
  }

  validate(field = null, silent = false) {
    const { props, state } = this;
    const errors = Object.assign({}, state.errors);
    let isFormValid = true;

    // validating a single field
    if (field) {
      const fieldProps = props.fields.find((f) => f.field === field.field);

      if (fieldProps) {
        if (fieldProps.mandatory === 'true' && !state[field.field]) {
          errors[field.field] = { hasError: true };
          isFormValid = false;
        } else {
          delete errors[field.field];
        }

        if (fieldProps.mandatory && fieldProps.mandatory.field) {
          let obj = fieldProps.mandatory.field;
          const isMandatory = Object.keys(obj).every(
            (k) => this.state[k] === obj[k]
          );
          if (isMandatory && !state[field.field]) {
            errors[field.field] = { hasError: true };
            isFormValid = false;
          } else {
            delete errors[field.field];
          }
        }
      }
    } else {
      if (props.fulfillment === 'custom') {
        props.fields.forEach((f) => {
          // check if form can be submitted
          if (f.mandatory === 'true' && !state[f.field]) {
            errors[f.field] = { hasError: true };

            isFormValid = false;
          } else {
            delete errors[f.field];
          }

          if (f.mandatory && f.mandatory.field) {
            let obj = f.mandatory.field;
            const isMandatory = Object.keys(obj).every(
              (k) => this.state[k] === obj[k]
            );
            if (isMandatory && !state[f.field]) {
              errors[f.field] = { hasError: true };
              isFormValid = false;
            } else {
              delete errors[f.field];
            }
          }
        });

        if (state.salesorg && state.salesorg !== '3000' && !state.contact) {
          errors.contact = { hasError: true };

          isFormValid = false;
        } else {
          delete errors.contact;
        }
      } else {
        // special rules
        if (['addproduct', 'addseat'].includes(props.selectedTest.ordertype)) {
          if (
            !state.initialorderpartnumber ||
            state.initialorderpartnumber === 'none'
          ) {
            errors.initialorderpartnumber = { hasError: true };

            isFormValid = false;
          }
        } else {
          delete errors.initialorderpartnumber;
        }
      }
    }

    if (!silent) {
      this.setState({ errors, canSubmitForm: isFormValid });
    }

    return isFormValid;
  }

  handleInitialOrder(e) {
    this.setState({ initialorderpartnumber: e.target.value });
  }

  handleFormField({ target }) {
    const { props } = this;
    const { name, options, selectedIndex } = target;
    let { value } = target;

    target.type === 'checkbox' && (value = target.checked);

    if (name === 'salesorg') {
      const selectedOption = options[selectedIndex];
      const salesorg = props.fields.find((f) => f.field === 'salesorg');
      const defaultValues = salesorg.options.find((option) => {
        const key = selectedOption.textContent;

        if (option[key]) {
          return option[key].salesorg === value;
        }

        return false;
      });

      if (defaultValues) {
        this.setState(defaultValues[selectedOption.textContent]);
      }
    }

    if (name === 'store') {
      const selectedOption = options[selectedIndex];
      const store = props.fields.find((f) => f.field === 'store');
      const defaultValues = store.options.find((option) => {
        const key = selectedOption.textContent;
        if (option[key]) {
          return option[key].store === value;
        }
        return false;
      });

      if (defaultValues) {
        this.setState(defaultValues[selectedOption.textContent]);
      }
    }

    if (name === 'accounttype') {
      this.setState({ accounttype: value });
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validate({ field: name, value });
      }
    );
  }

  handleSKUs(lineitems) {
    this.setState({ lineitems });
  }

  handleSubmitFormClicked() {
    const { props, state } = this;
    const formFields = {};
    let tempLineItems = [];
    let payload = {
      env: props.environment,
      fulfillment: props.fulfillment === 'prefill' ? 'pre-fill' : 'custom',
      subcategoryname: props.channelName,
      ordertype: props.selectedTest.ordertype,
      orderid: props.selectedTest.orderid,
      // region: 'AMER',
      // channel: props.channelName,
      origin: 'quarry/orders',
      notificationemail: this.state.notificationEmail,
    };
    let hasEnoughLineItems = true;
    let lineitems = [];
    let version = 'v1';

    props.selectedTest.skutype.forEach((s) => {
      if (state.temporaryLineItems[s.type]) {
        tempLineItems.push(state.temporaryLineItems[s.type]);
      }
    });

    // assigning form values based on available fields
    props.fields.forEach((field) => {
      if (state[field.field]) {
        formFields[field.field] = state[field.field];
      }
    });

    // updating payload to include form values
    lineitems = [...state.lineitems, ...tempLineItems];

    if (
      ![
        'addseat',
        'aoe_addseat',
        'pws_addseat',
        'return',
        'reference',
        'switch',
      ].includes(props.selectedTest.ordertype)
    ) {
      if (lineitems.length < props.selectedTest.skutype.length) {
        hasEnoughLineItems = false;
      }
    }

    if (this.validate() && hasEnoughLineItems) {
      if (props.fulfillment === 'prefill') {
        formFields.initialorderpartnumber = state.initialorderpartnumber;

        if (!payload.salesorg) {
          payload.salesorg = state.salesorg;
        }
      }

      // creating custom payload
      if (props.selectedTest.isbulk || props.categoryname === 'Simulation') {
        payload.items = [{ ...formFields, lineitems }];
        version = 'v2';
      } else {
        payload = { ...payload, ...formFields, lineitems };
      }

      props.submitOrder(payload, version, props.channelName.toLowerCase());
      props.setDefaultFilter('testdata');
      props.history.push('/submit?from=orders&f=true');
    }
  }

  renderPrefillOrder() {
    const { props, state } = this;
    let inputs = [];

    if (state.salesorg || state.store) {
      const customSKUInputProps = {
        channel: props.channelName,
        onLineItemsUpdated: this.handleSKUs,
        isMultiple: false,
        skutype: [
          {
            type: props.selectedTest.skutype[0].type,
            displayname: 'Base Product',
          },
        ],
        region: state.salesorg || state.store,
        environment: props.environment,
        updateTemporaryLineItem: this.handleInitialOrder,
        fulfillment: props.fulfillment,
      };

      inputs.push(
        <div
          className="col-sm-12"
          key={`select-${props.selectedTest.skutype[0].type}`}
        >
          <CustomSKUInput simple {...customSKUInputProps} />
        </div>
      );
    }

    return inputs;
  }

  renderNotificationFields() {
    if (!this.mounted) return;
    return (
      <div className="col-sm-8 form-group" key="notificationemail">
        <NotificationField
          value={this.state.notificationEmail}
          notifyAllValue={this.state.notificationEmail}
          name="notificationemail"
          onChange={this.onNotificationFieldChange}
          loggedInUserEmail={this.props.userInfo.emailid}
        />
      </div>
    );
  }

  renderFields(fields) {
    const types = {
      contact: 'email',
      password: 'password',
      default: 'text',
    };

    return fields.map((field) => {
      const customPlaceholders = {
        contact: field.default ? `e.g: ${field.default}` : '',
        shiptocsn: field.default ? `e.g: xxxxxx${field.default.slice(-4)}` : '',
        soldtocsn: field.default ? `e.g: xxxxxx${field.default.slice(-4)}` : '',
        accountcsn: field.default
          ? `e.g: xxxxxx${field.default.slice(-4)}`
          : '',
        partneremail: field.default
          ? `e.g: xxxxxx${field.default.slice(-16)}`
          : '',
        password: field.default ? `Enter password` : '',
      };
      let input = null;

      if (
        this.props.fulfillment === 'prefill' &&
        field.field !== 'salesorg' &&
        field.field !== 'addseatquantity'
      ) {
        return null;
      }

      switch (field.type) {
        case 'string':
        case 'number':
        case 'date':
          if (field.disable) {
            input = (
              <input
                data-lpignore="true"
                name={field.field}
                type={types[field.field] ? types[field.field] : types.default}
                placeholder={customPlaceholders[field.field] || field.default}
                value={this.state[field.field] || ''}
                onChange={this.handleFormField}
                className={
                  this.isDisable(field.disabled, this.state)
                    ? 'form-control hidden'
                    : 'form-control visible'
                }
              />
            );
          } else {
            input = (
              <input
                data-lpignore="true"
                name={field.field}
                type={types[field.field] ? types[field.field] : types.default}
                placeholder={customPlaceholders[field.field] || field.default}
                value={this.state[field.field] || ''}
                onChange={this.handleFormField}
                className="form-control"
              />
            );
          }
          break;
        case 'select':
          const selectProps = {
            value: this.state[field.field],
            name: field.field,
            onChange: this.handleFormField,
            disabled: this.props.fulfillment === 'prefill',
            className: 'form-control',
          };
          let options = [];

          options = field.options.map((option) => {
            const region = Object.keys(option)[0];
            const value =
              option[region].salesorg ||
              (option[region].store ? option[region].store : option[region]);

            return (
              <option key={option[region].salesorg} value={value}>
                {region}
              </option>
            );
          });

          input = <select {...selectProps}>{options}</select>;

          break;
        case 'checkbox':
          input = (
            <input
              name={field.field}
              type="checkbox"
              value={this.state[field.field] || false}
              onChange={this.handleFormField}
              className="form-control"
            />
          );
          break;
        default:
          break;
      }

      if (input) {
        let optional;
        const tooltip = field.description ? (
          <Tooltip text={field.description} />
        ) : null;
        optional =
          field.mandatory === 'false' ? (
            <span className="optional-field">Optional</span>
          ) : (
            <span className="mandatory-field">*</span>
          );

        if (field.mandatory && field.mandatory.field) {
          let obj = field.mandatory.field;
          const isMandatory = Object.keys(obj).every(
            (k) => this.state[k] === obj[k]
          );
          optional = isMandatory === false ? <span>Optional</span> : null;
        }

        const hasError = this.state.errors[field.field] ? ' has-error' : '';
        let labelClassName = '';

        if (field.disable) {
          labelClassName = this.isDisable(field.disable, this.state)
            ? 'hidden'
            : 'visible';
        }

        return (
          <div className={`col-sm-6 ${labelClassName}`}>
            <div className={`form-group${hasError}`} key={field.field}>
              <label htmlFor={field.field}>
                {field.name}
                {optional}
                {tooltip}
              </label>
              {input}
              {hasError && (
                <span className="has-error-reason">
                  {field.name} cannot be empty
                </span>
              )}
            </div>
          </div>
        );
      }

      return null;
    });
  }

  isDisable(disableCond, state) {
    if (!disableCond) return false;
    let disable = false;
    if (disableCond.hasOwnProperty('field')) {
      disable = Object.entries(disableCond['field']).every((e) => {
        if (Array.isArray(e[1])) {
          return e[1].indexOf(state[e[0]]) !== -1;
        } else {
          return state[e[0]] === e[1];
        }
      });
    }
    if (disableCond.hasOwnProperty('and')) {
      disable = Object.entries(disableCond['and']).every((e) => {
        if (Array.isArray(e[1])) {
          return e[1].indexOf(state[e[0]]) !== -1;
        } else {
          return state[e[0]] === e[1];
        }
      });
    }
    if (disableCond.hasOwnProperty('or')) {
      disable = Object.entries(disableCond['or']).some((e) => {
        if (Array.isArray(e[1])) {
          return e[1].indexOf(state[e[0]]) !== -1;
        } else {
          return state[e[0]] === e[1];
        }
      });
    }
    return disable;
  }
  render() {
    const { props, state } = this;
    const modalProps = {
      onClose: this.props.onClose,
      onSubmit: this.handleSubmitFormClicked,
      submitButtonText: 'Submit order',
      title: props.title,
      submitEnabled: this.canSubmitForm(),
    };

    const fields = this.renderFields(props.fields);
    const notificationFields = this.renderNotificationFields();
    let prefillFields = [];
    let customSKU = null;

    if (props.fulfillment === 'prefill') {
      if (['addseat', 'addproduct'].includes(props.selectedTest.ordertype)) {
        prefillFields = this.renderPrefillOrder();
      }
    }

    if (
      (state.salesorg || state.store) &&
      props.selectedTest.ordertype !== 'addseat'
    ) {
      const isMultiple =
        props.fulfillment === 'prefill' &&
        props.selectedTest.ordertype !== 'addproduct'
          ? false
          : props.selectedTest.ismultiple;
      const customSKUInputProps = {
        channel: props.channelName,
        onLineItemsUpdated: this.handleSKUs,
        skutype: props.selectedTest.skutype,
        region:
          props.channelName === 'BIC' ||
          props.channelName === 'PARTNERCENTER' ||
          props.selectedTest.ordertype === 'pws_cpqquoteapi'
            ? state.store
            : state.salesorg,
        environment: props.environment,
        updateTemporaryLineItem: this.updateTemporaryLineItem,
        fulfillment: props.fulfillment,
        isMultiple,
      };

      customSKU = <CustomSKUInput {...customSKUInputProps} />;
    }

    return (
      <Modal {...modalProps}>
        <FlashMessage />
        <div className="modal-form">
          <div className="row">
            {notificationFields}
            {[...prefillFields, ...fields]}
            <div className="col-sm-12">{customSKU}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
  submitOrder: (payload, version, channel) =>
    dispatch(submitOrder(payload, version, channel)),
});

export default connect(null, mapDispatchToProps)(OrderModal);
