import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

function ConfirmationModal(props) {
  return (
    <Modal
      title={props.title}
      onClose={props.onClose}
      submitEnabled={true}
      onSubmit={props.onConfirm}
      loading={props.loading}
      submitButtonText={props.submitButtonText}
    >
      { props.message }
      { props.children }
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  title: 'Confirmation',
  submitButtonText: 'Confirm',
};

export default ConfirmationModal;
