export const Types = {
  SET_VISIBLE: 'LIGHTBOX/SET_VISIBLE',
};

export const setLightboxState = (state) => (dispatch) => {
  dispatch({
    type: Types.SET_VISIBLE,
    state,
  });
};
