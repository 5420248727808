import React from 'react';
import { Switch } from 'react-router-dom';

import Menu from 'components/Menu';
import Heading from 'components/Heading';
import Lightbox from 'components/Lightbox';

import routes from 'routes';

const Default = () => (
  <div className='Dash'>
    <Lightbox />

    <div>
      <Menu />
    </div>

    <div>
      <main className='AppContent'>
        <Heading />

        <Switch>
          { routes }
        </Switch>
      </main>
    </div>
  </div>
);

export default Default;
