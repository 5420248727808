import Session from 'utils/session.service';

export const startLoginProcess = (username, password) => async (dispatch) => {
  const session = new Session(dispatch);

  try {
    await session.create(username, password);
  } catch (e) {
    return null;
  }
};

export const startLogoutProcess = (dispatch) => () => {
  const session = new Session(dispatch);

  session.clear();
};
