import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import LoadingPage from 'components/LoadingPage';
import { appDoneLoading } from 'store/actions/app_actions';
import { fetchNavigationCategories } from 'store/actions/menu_actions';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  // replace with useSelector
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  // react lifecycle
  useEffect(() => {
    // initial load
    if (user.isLoggedIn) {
      Promise.all([dispatch(fetchNavigationCategories())]).then(() => {
        dispatch(appDoneLoading());
      });
    }
  }, []);
  // render methods
  const renderFn = (props) => {
    if (user.isLoggedIn) {
      if (app.minimumContentLoaded) {
        return <Component {...props} />;
      }

      return <LoadingPage message="Loading categories" />;
    }

    // redirect to the referer URL
    sessionStorage.setItem(
      'th-referer',
      `${props.location.pathname}${props.location.search}`
    );

    return <Redirect to="/auth" />;
  };

  return (
    <>
      <Route {...rest} render={renderFn} />;
    </>
  );
};

export default PrivateRoute;
