class Cache {
  constructor() {
    this.cacheName = 'th-cache';
    this.cache = this.readFromLocalStorage();
  }

  // read previous cache stored in localStorage
  readFromLocalStorage() {
    const savedCache = localStorage.getItem(this.cacheName);

    if (savedCache) {
      return JSON.parse(savedCache);
    }

    return {};
  }

  set(path, response, expirationTime = 900000) {
    const today = +new Date();

    this.cache[path] = {
      response,
      expires_at: today + expirationTime,
    };

    localStorage.setItem(this.cacheName, JSON.stringify(this.cache));
  }

  get(path) {
    const cachedRequest = this.cache[path];
    const today = +new Date();

    // if the request has been cached before and hasn't expired
    if (cachedRequest && cachedRequest.expires_at > today) {
      return cachedRequest.response;
    }

    // return an empty object if the request hasn't been cached before or
    // if it already expired
    return null;
  }

  delete(path) {
    delete this.cache[path];
  }
}

export default Cache;
