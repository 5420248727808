const filtersReducerDefaultState ={
    search: '',
    profilesId: ''
};

export default (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_TEXT_FILTER':
            return{
                ...state,
                search: action.search
            };
        case 'SET_JOB_ID':
            return {
                ...state,
                profilesId: action.id
            };
        default:
            return state;
    }
}