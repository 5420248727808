import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

const Notification = (props) => {
  // state
  const [visible, setVisible] = useState(true);
  // user actions
  const onClose = () => {
    setVisible(false);
  };
  // render methods
  const toastProps = {
    className: classname({
      'toast': true,
      'hide': !visible,
      [props.type]: true,
    }),
  };
  const toastType = {
    'success': 'bi-check',
    'danger': 'bi-x',
  };
  let message = props.message;

  // hide element after 5 seconds if not danger
  if (props.type !== 'danger') {
    setTimeout(onClose, 5000);
  }

  if (Array.isArray(message)) {
    message = message.map((m) => <p>- { m }</p>);
  }

  return (
    <div {...toastProps} role='alert' aria-live='assertive' aria-atomic='true'>
      <div className='toast-header'>
        <i className={`bi ${toastType[props.type]}`}></i>
        <strong className='ml-4 mr-auto'>Testing Hub</strong>

        <button onClick={onClose} type='button' className='ml-2 mb-1 close' aria-label='Close'>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>

      <div className='toast-body'>
        { message }
      </div>
    </div>
  );
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Notification.defaultProps = {
  type: '',
};

export default Notification;
