import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Login from "../Login";
import PrivateRoute from "./CustomRoutes/PrivateRoute";
import PublicRoute from "./CustomRoutes/PublicRoute";

import resolveLayout from 'routes/layouts/resolveLayout';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact
            path='/auth'
            component={Login} />
          <PrivateRoute
            path='/'
            component={resolveLayout} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
