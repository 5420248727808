import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classname from 'classname';

import { getQuarryTestCaseFields } from 'utils/api.service';

import EnvironmentSwitch from 'components/EnvironmentSwitch';
import TestCase from 'components/TestCase';
import LoadingSpinner from 'components/LoadingSpinner';
import BulkUploadModal from 'components/BulkUploadModal';
import TotalSelectedBadge from 'components/TotalSelectedBadge';
import OrderModal from './modals/OrderModal';
import filterList from 'selectors/search';

import { setDefaultFilter } from 'store/actions/jobsQueue_actions';
import { submitOrder } from 'store/actions/quarry_actions';

class Orders extends React.Component {
  static propTypes = {
    category: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    testCases: PropTypes.array.isRequired,
    handleChannelButtonClicked: PropTypes.func.isRequired,
    loadingChannels: PropTypes.bool.isRequired,
    loadingTestCases: PropTypes.bool.isRequired,
    selectedChannelID: PropTypes.string.isRequired,
  }

  state = {
    searchValue: '',
    showModal: false,
    selectedTestCaseID: null,
    testCaseFields: [],
    loadingButtons: false,
  }

  constructor() {
    super();

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleTestCaseClicked = this.handleTestCaseClicked.bind(this);
    this.handleShowModalClicked = this.handleShowModalClicked.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitBulkOrder = this.submitBulkOrder.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeEnvironment !== this.props.activeEnvironment) {
      this.props.onEnvironmentChange();

      if (this.state.selectedTestCaseID) {
        this.getTestCaseFields(this.state.selectedTestCaseID, true);
      }
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSearchInputChange(e) {
    this.setState({ searchValue: e.target.value });
  }

  handleTestCaseClicked(testCaseID) {
    return () => {
      this.getTestCaseFields(testCaseID);
    };
  }

  handleShowModalClicked(type) {
    return () => {
      this.setState({
        showModal: true,
        modalName: type,
        fulfillment: type,
      });
    };
  }

  getTestCaseFields(testCaseID, updatedEnvironment=false) {
    if (testCaseID !== this.state.selectedTestCaseID || updatedEnvironment) {
      const { props } = this;
      const testCase = props.testCases.find(t => t.orderid === testCaseID);

      if (testCase) {
        this.setState({
          selectedTestCaseID: testCaseID,
          testCaseFields: [],
          loadingButtons: true,
        });

        getQuarryTestCaseFields(props.category, props.selectedChannelID, testCase.metafield, props.activeEnvironment).then(response => {
          this.setState({
            testCaseFields: response.data,
            loadingButtons: false,
          });
        });
      } else {
        this.setState({ selectedTestCaseID: null });
      }
    }
  }

  submitBulkOrder(orders) {
    const { props, state } = this;
    const selectedChannel = props.channels.find(c => c.subcategoryid === props.selectedChannelID);
    const selectedTest = props.testCases.find(t => t.orderid === state.selectedTestCaseID);
    const payload = {
      env: props.activeEnvironment,
      subcategoryname: selectedChannel.subcategoryname,
      ordertype: selectedTest.ordertype,
      orderid: selectedTest.orderid,
      origin: 'quarry/orders',
      fulfillment: 'custom',
      items: [...orders],
    };
    const channel = props.channels.find((c) => c.subcategoryid === props.selectedChannelID);

    payload.items.map((item) => {
      const skulist = Object.keys(item).filter((key) => (key.includes('sku') || key.includes('price')));

      if (!item.lineitems) {
        item.lineitems = [];
      }

      skulist.forEach(header => {
        const regExp = new RegExp(/\(([^)]+)\)/);
        const skutype = regExp.exec(header);

        item[header].forEach(([partnumber, quantity]) => {
          const partnumberKey = channel.subcategoryname === 'BIC' ? 'priceid' : 'partnumber';

          item.lineitems.push({
            [partnumberKey]: partnumber,
            quantity,
            skutype: skutype[1],
          });
        });

        delete item[header];
      });

      delete item.testdata;

      return item;
    });

    return new Promise((resolve, reject) => {
      let hasEmptyValues = false;

      payload.items.some(item => {
        const values = Object.values(item).filter(i => i === '');

        if (values.length > 0) {
          hasEmptyValues = true;

          return true;
        }

        return false;
      });

      if (!hasEmptyValues) {
        props.submitOrder(payload, 'v2', payload.subcategoryname.toLowerCase());
        props.setDefaultFilter('testdata');
        props.history.push('/submit?from=orders&f=true');

        resolve({ data: { message: 'Tests submitted successfully.' }});
      } else {
        reject({ message: 'All fields are mandatory. Please check CSV file for missing values.' });
      }
    });
  }

  renderFiltersAndButtons() {
    const { props, state } = this;
    const buttons = [];
    const testCase = props.testCases.find(t => t.orderid === state.selectedTestCaseID);
    const containerProps = {
      className: classname({
        'orders-search-filter-actions': true,
        'empty': !props.testCases.length,
      }),
    };
    const availableTestCases = filterList(props.testCases, this.state.searchValue).filter(((test) => {
      return test.status[this.props.activeEnvironment] !== 'disabled';
    }));
    const selectedTest = props.testCases.find(t => t.orderid === state.selectedTestCaseID);
    let search = null;

    if (props.testCases.length) {
      const searchProps = {
        type: 'search',
        onChange: this.handleSearchInputChange,
        value: state.searchValue,
        name: 'search',
        className: 'form-control',
        placeholder: 'Search tests',
      };

      search = (
        <div className='form-group col-md-3 pl-0'>
          <input {...searchProps} />
        </div>
      );
    }

    if (testCase) {
      const buttonProps = {
        className: 'button button-primary',
      };

      if (state.loadingButtons) {
        buttons.push(<LoadingSpinner text='Loading fields' key='loading-spinner' />);
      } else {
        if (testCase.isbulk) {
          buttons.push(<button key='button-bulk' {...buttonProps} onClick={this.handleShowModalClicked('bulk')}>Bulk order</button>);
        }

        if (testCase.supportsPrefillOrder) {
          buttons.push(<button key='button-prefill' {...buttonProps} onClick={this.handleShowModalClicked('prefill')}>Prefill</button>);
        }
  
        if (testCase.supportsCustomOrder) {
          buttons.push(<button key='button-custom' {...buttonProps} onClick={this.handleShowModalClicked('custom')}>Custom</button>);
        }
      }
    }

    return (
      <div {...containerProps}>
        { search }

        <div className='orders-actions'>
          <TotalSelectedBadge available={availableTestCases.length} selected={selectedTest ? 1 : 0} total={props.testCases.length} />

          { buttons }
        </div>
      </div>
    );
  }

  renderTestCases(testCases) {
    if (this.props.loadingTestCases && !testCases.length) {
      return <LoadingSpinner text='Loading tests' />;
    } else {
      const tests = filterList(testCases, this.state.searchValue).map((testCase, index) => {
        if (testCase.status[this.props.activeEnvironment] === 'disabled') {
          return null;
        } else {
          const testCaseProps = {
            checked: testCase.orderid === this.state.selectedTestCaseID,
            description: testCase.description,
            name: testCase.name,
            testcaseid: testCase.orderid,
            onItemSelected: this.handleTestCaseClicked(testCase.orderid),
            disabled: testCase.status[this.props.activeEnvironment] === 'disabled',
            learnMoreUrl: 'https://wiki.autodesk.com/display/DES/CJT+Test+Case+Steps+-+Order+Fulfillment',
          };

          return (
            <div className='scenario' key={`${testCase.name.split(' ').join('-')}-${index}`}>
              <TestCase radio {...testCaseProps} />
            </div>
          );
        }
      });
      const emptyItems = [...Array((Math.ceil(tests.filter(t => t).length / 3) * 3) - tests.filter(t => t).length).keys()];
      const placeholders = emptyItems.map(idx => <div className='scenario empty' key={`empty-${idx}`}></div>);

      return [...tests, ...placeholders];
    }
  }

  renderModal() {
    const { props, state } = this;
    const selectedTest = props.testCases.find(t => t.orderid === state.selectedTestCaseID);

    if (this.state.showModal) {
      let modalProps = {
        onClose: this.closeModal,
        environment: props.activeEnvironment,
      };

      switch (this.state.modalName) {
        case 'bulk':
          const channel = props.channels.find(c => c.subcategoryid === props.selectedChannelID);
          const skuLabel = channel.subcategoryname === 'BIC' ? 'priceid' : 'sku';
          let skulist = [];

          if (selectedTest.skutype && selectedTest.skutype.length) {
            const field = channel.subcategoryname === 'BIC' ? 'priceid' : 'skulist';

            skulist = selectedTest.skutype.map(l => `${field}(${l.type})`)
          }

          modalProps = {
            ...modalProps,
            onSubmit: this.submitBulkOrder,
            fields: [...this.state.testCaseFields.map(f => f.field), ...skulist],
            instructions: (
              <React.Fragment>
                To create bulk orders please use the provided CSV template. For multiple products for an order please use below example. At the moment Testing Hub only supports CSV files with up to 100 test orders. Additional test cases will be ignored.

                <br />
                <br />

                <code>{ skuLabel }=quantity|{ skuLabel }=quantity</code>
              </React.Fragment>
            ),
          };

          return <BulkUploadModal {...modalProps} />
        default:
          const selectedChannel = props.channels.find(c => c.subcategoryid === props.selectedChannelID);

          modalProps = {
            ...modalProps,
            channelName: selectedChannel.subcategoryname,
            fields: state.testCaseFields,
            fulfillment: state.fulfillment,
            history: props.history,
            selectedTest,
            categoryname: props.pageHeader,
            userInfo: props.userInfo,
            title: selectedTest.name,
          };

          return <OrderModal {...modalProps} />;
      }
    }

    return null;
  }

  render() {
    const { props } = this;
    const buttons = this.renderFiltersAndButtons();
    const channels = props.renderChannels(props.channels);
    const testCases = this.renderTestCases(props.testCases);
    const environmentProps = {
      environments: props.environments,
      context: 'Orders',
      value: props.activeEnvironment,
    };
    const modal = this.renderModal();

    return (
      <div className='orders-container'>
        <section className='section-title'>
          <div className='section-title-group'>
            <h2 className='title'>{ props.pageHeader }</h2>
            <a href='https://wiki.autodesk.com/display/DES/Test+Data+Use+Cases' target='_blank' rel='noopener noreferrer'>What is this?</a>
          </div>

          <EnvironmentSwitch {...environmentProps} />
        </section>

        <section className='orders-channels'>
          { channels }
        </section>

        <section className='orders-action-buttons'>
          { buttons }
        </section>

        <div className='orders-test-cases'>
          { testCases }
        </div>

        { modal }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDefaultFilter: source => dispatch(setDefaultFilter(source)),
  submitOrder: (payload, version, channel) => dispatch(submitOrder(payload, version, channel)),
});

export default connect(null, mapDispatchToProps)(Orders);
