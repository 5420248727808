import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CollapsibleContent from 'components/CollapsibleContent';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';

import { getMeshTestCases } from 'store/actions/mesh_actions';
import { getHostingTestCases } from 'store/actions/hosting_actions';
import pluralize from 'utils/pluralize';
import NotificationField from "../NotificationField";

function  TestSelection(props) {
  // state
  const [testcases, setTestcases] = useState([]);
  const [loadingTestCases, setLoadingTestCases] = useState(false);
  const [selectedTestCases, setSelectedTestCases] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [testSuiteName, setTestSuiteName] = useState('');
  const [notificationEmail, setNotificationEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [environment, setEnvironment] = useState('');
  const [metafields, setMetafields] = useState({});
  const [modalData, setModalData] = useState({});
  const numberOfChannels = props.channels.length;
  // refs
  const fileInputRef = React.createRef();

  // component lifecycle
  useEffect(() => {
    // if creating a new test suite
    if (!props.selectedTestCases.length) {
      if (props.environment !== environment) {
        setEnvironment(props.environment);
        setSelectedTestCases([]);
  
        return;
      }

      if (props.service.servicename !== serviceName) {
        setServiceName(props.service.servicename);
        setSelectedTestCases([]);
      }
    } else {
      if (props.testSuiteName && props.selectedTestCases.length !== selectedTestCases.length) {
        setTestSuiteName(props.testSuiteName);
        setSelectedTestCases(props.selectedTestCases);
      }

      if (props.environment !== environment) {
        setEnvironment(props.environment);
      }

      setNotificationEmail(props.notificationEmail || '');
    }

    if (numberOfChannels > 0) {
      let requests;

      setTestcases([]);
      setLoadingTestCases(true);

      if (props.service.servicename === 'cjt') {
        // loop and get testcase list from api call in function getTestCases
        requests = props.channels.map((channel) => getMeshTestCases(channel.categoryid, channel.subcategoryid));
      } else {
        requests = props.channels.map((project) => getHostingTestCases(project.org, project.workstream, project.projectname));
      }

      // responses is an array of testcases by channel
      Promise.all(requests).then((responses) => {
        const responseMetafields = {...metafields};
        const testcases = responses.reduce((accumulator, response) => {
          let envlist = props.environments;

          response.data.testcases.forEach((testcase) => {
            testcase.subcategoryid = response.data.subcategoryid;

            if (testcase.testdata) {
              let envObjExist = envlist.some((env) => {
                const data = testcase.testdata;
                const envName = env.displayname.toUpperCase();

                return data.hasOwnProperty(envName) && typeof data[envName] === 'object';
              });

              if (Object.keys(testcase.testdata).length > 0 && !envObjExist) {
                const oldTestData = testcase.testdata;

                testcase.testdata = {};

                envlist.forEach((e) => {testcase.testdata[e.displayname.toUpperCase()] = oldTestData});
              } else if (Object.keys(testcase.testdata).length === 0) {
                envlist.forEach((e) => {testcase.testdata[e.displayname.toUpperCase()] = {}});
              }
            }

          });

          responseMetafields[response.data.subcategoryid] = response.data.metafields;

          return [...accumulator, ...response.data.testcases];
        }, []);

        setTestcases(testcases);
        setLoadingTestCases(false);
        setMetafields(responseMetafields);
      });
    } else {
      setTestcases([]);
    }
  }, [numberOfChannels, props.testSuiteName, props.service.servicename, props.environment]);
  const onNotificationFieldChange = (value) => {
    setNotificationEmail(value);
  };

  // misc
  const addTestToPayload = (payload, test, csvData) => {
    let testcase = {...test};
    const category = payload.category.find((c) => c.categoryid === testcase.categoryid || c.categoryid === testcase.workstreamname);
    const defaultRegion = 'AMER-3000';

    if (category) {
      const subcategory = category.subcategory.find((s) => s.subcategoryid === testcase.subcategoryid || s.subcategoryid === testcase.projectname);

      if (subcategory) {
        const meta = metafields[subcategory.subcategoryid];

        if (meta && meta[testcase.testype]) {
          // set default values
          meta[testcase.testype].forEach((metafield) => {
            if (!testcase[metafield.field]) {
              testcase[metafield.field] = metafield.default;
            } else if (metafield.field === 'usertype') {
              if (testcase.usertype === 'all') {
                testcase.usertype = metafield.default;
              }
            }
          });

          // checking for environment specific default values
          const salesorg = meta[testcase.testype].find(f => f.field === 'salesorg');

          // if the metafields contain salesorg
          if (salesorg) {
            const environmentOptions = salesorg.options[props.environment.toLowerCase()];

            // if salesorg has environment specific options
            if (environmentOptions) {
              const regionOptions = environmentOptions.find(o => o[defaultRegion])[defaultRegion];

              // if environment options contains options for a specific region
              if (regionOptions) {
                // update the payload with whatever comes in salesorg
                testcase = Object.assign({}, testcase, regionOptions);
              }
            }
          }
        }

        if (csvData && Array.isArray(csvData)) {
          csvData.forEach((testdata) => {
            testcase.testdata = testdata;

            subcategory.testcases.push(testcase);
          });
        } else {
          subcategory.testcases.push(testcase);
        }
      } else {
        category.subcategory.push({
          subcategoryid: testcase.projectname || testcase.subcategoryid,
          subcategoryname: testcase.projectdisplayname || testcase.subcategoryname,
          testcases: [],
        });

        addTestToPayload(payload, testcase, csvData)
      }
    } else {
      payload.category.push({
        categoryid: testcase.categoryid || testcase.workstreamname,
        categoryname: testcase.category || testcase.workstreamdisplayname,
        subcategory: [],
      });

      addTestToPayload(payload, testcase, csvData)
    }
  };
  const toggleModal = (name) => {
    if (!name) {
      setModalData({ visible: false });
    }

    return () => {
      setModalData({ visible: true, name });
    };
  };
  const processCSVFile = () => {
    const file = fileInputRef.current.files[0];

    if (file) {
      const reader = new FileReader();

      reader.readAsText(file);
      reader.onload = (content) => {
        const csv = content.target.result.split('\n');
        const payload = [];
        let keys = [];

        csv.forEach((row, rowIndex) => {
          if (rowIndex === 0) {
            // keys
            keys = row.split(',');
          } else {
            // values
            payload[rowIndex - 1] = {};

            row.split(',').forEach((cell, cellIndex) => {
              payload[rowIndex - 1][keys[cellIndex]] = cell;
            });
          }
        });

        onSubmitClick(payload);
      };
    }
  };
  // user actions
  const onAddTestClick = (e) => {
    const testcase = testcases.find((testcase) => testcase.testcaseid === e.target.dataset.testcaseId);

    if (testcase) {
      testcase.environment = props.environment.toLowerCase();
      testcase.channel = testcase.subcategoryname.toLowerCase();

      if(testcase.testdata) {
          testcase.testdata = {[props.environment.toUpperCase()]:testcase.testdata[props.environment.toUpperCase()]}
      }

      selectedTestCases.push(testcase);

      setSelectedTestCases(selectedTestCases);
      setLastUpdate(+new Date());
    }
  };
  const onRemoveTestClick = (e) => {
    const testcaseIndex = selectedTestCases.findIndex((testcase) => testcase.testcaseid === e.target.dataset.testcaseId);

    if (testcaseIndex >= 0) {
      selectedTestCases.splice(testcaseIndex, 1);

      setSelectedTestCases(selectedTestCases);
      setLastUpdate(+new Date());
    }
  };
  const onTestSuiteNameChange = (e) => {
    setTestSuiteName(e.target.value);
  };
  const onSubmitClick = (csvData) => {
    if (selectedTestCases.length > 0) {
      // payload generation
      const payload = {
        testsuitename: testSuiteName,
        environment: props.environment,
        service: props.service.servicename,
        category: [],
        emailrecipients: notificationEmail
      };

      setIsSubmitting(true);

      selectedTestCases.forEach((testcase) => {
        addTestToPayload(payload, testcase, csvData);
      });

      // submission
      props.onSubmit(props.service.servicename, payload).then(() => {
        setSelectedTestCases([]);
        setIsSubmitting(false);
      });
    }
  };
  // render methods
  const renderModal = () => {
    let modalProps = {
      onClose: () => toggleModal(),
      loading: isSubmitting,
    };

    if (modalData.visible) {
      switch (modalData.name) {
        case 'create-test-suite-from-csv':
          modalProps = {
            ...modalProps,
            submitButtonText: 'Create test suite',
            title: 'Create test suite from CSV file',
            onSubmit: processCSVFile,
          };

          return (
            <Modal {...modalProps}>
              <div className='form-group'>
                <label for='csv-field'>Select CSV File</label>
                <input ref={fileInputRef} type='file' className='form-control-file' id='csv-field' />
              </div>
            </Modal>
          );
        default:
          return null;
      }
    }
  };
  const renderTestSelectionSection = () => {
    let heading = props.channels.map((c) => c.subcategoryname);
    let availableTestCases = [];

    if (heading.length > 1) {
      const lastChannel = heading.pop();

      heading = [heading.join(', '), lastChannel].join(' & ');
    }

    if (numberOfChannels > 0) {
      if (loadingTestCases) {
        availableTestCases = <LoadingSpinner text='Loading test cases' />;
      } else {
        const filteredTests = {};

        testcases.forEach((testcase) => {
          const key = testcase.projectdisplayname || testcase.subcategoryname;
          if (key && !filteredTests[key]) {
            filteredTests[key] = [];
          }

          filteredTests[key].push(testcase);
        });

        Object.keys(filteredTests).forEach((subcategoryname) => {
          const title = `${subcategoryname} (${filteredTests[subcategoryname].length})`;
          const tests = renderTestCases(filteredTests[subcategoryname], 'add test', onAddTestClick);

          availableTestCases.push(
            <CollapsibleContent title={title}>
              { tests }
            </CollapsibleContent>
          );
        });
      }

      return (
        <ul className='test-selection-testcases'>
          <h6>Click on an item below to display its test cases</h6>

          { availableTestCases }
        </ul>
      );
    } else {
      return (
        <div className='test-selection-testcases-empty'>
          please select a test-group from the menu on the left
        </div>
      );
    }
  };
  const renderTestCases = (testcases, buttonText, buttonOnClick) => {
    return testcases.map((testcase) => {
      const alreadyAdded = selectedTestCases.find(t => t.testcaseid === testcase.testcaseid);
      let button = <button className='btn btn-link' data-testcase-id={testcase.testcaseid} onClick={buttonOnClick}>{ buttonText }</button>;

      if (testcase.status && testcase.status[props.environment.toLowerCase()] !== 'disabled') {
        if (buttonText === 'add test' && alreadyAdded) {
          button = <button className='btn btn-link' disabled>added</button>;
        }

        return (
          <li className='testcase' key={testcase.testcaseid}>
            <div className='testcase'>
              <strong>{ testcase.projectdisplayname || testcase.subcategoryname }</strong>
              { testcase.name }
            </div>

            { button }
          </li>
        );
      } else {
        return null;
      }
    });
  };
  const selectedTestCasesListItems = renderTestCases(selectedTestCases, 'remove test', onRemoveTestClick);
  const testSelectionSection = renderTestSelectionSection();
  const modal = renderModal();

  if (environment && props.service.servicename) {
    return (
      <div className='test-selection-container' data-last-update={lastUpdate}>
        <section>
          { testSelectionSection }
        </section>

        <section className='selected-test-cases'>
          <div className='form-group test-suite-title'>
            <label htmlFor='testsuitename'>Test suite name <span className="mandatory"> *</span></label>
            <input className='form-control' name='testsuitename' placeholder="Enter Test Suite Name" type='text' required value={testSuiteName} onChange={onTestSuiteNameChange} />
          </div>

          <NotificationField value={notificationEmail}
                             notifyAllValue={props.notificationEmail || ''}
                             name='notificationemail'
                             onChange={onNotificationFieldChange}
                             loggedInUserEmail={props.userInfo ? props.userInfo.emailid : ''}
          />

          <ul className='test-selection-testcases margin-fix'>
            { selectedTestCasesListItems }
          </ul>

          <div className='test-suite-submittion'>
            <div className='submittion-count'>
              <span>{selectedTestCases.length} { pluralize(selectedTestCases.length, 'item', 'items') } in test suite</span>
            </div>

            <div className='submittion-actions'>
              { !props.testSuiteName && (
                <button className='btn btn-primary mb-2' onClick={toggleModal('create-test-suite-from-csv')} disabled={selectedTestCases.length !== 1 || isSubmitting || !testSuiteName}>
                  { isSubmitting ? 'Processing' : 'Create Test Suite from CSV' }
                </button>
              )}

              <button className='btn btn-primary' onClick={onSubmitClick} disabled={selectedTestCases.length === 0 || isSubmitting || !testSuiteName}>
                { isSubmitting ? 'Processing' : (props.testSuiteName ? 'Update Test Suite' : 'Create Test Suite') }
              </button>
            </div>
          </div>
        </section>

        { modal }
      </div>
    );
  }

  return null;
}

TestSelection.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.object),
  service: PropTypes.object.isRequired,
  environment: PropTypes.string.isRequired,
  selectedTestCases: PropTypes.arrayOf(PropTypes.object),
  environments: PropTypes.arrayOf(PropTypes.object)
};

TestSelection.defaultProps = {
  channels: [],
  selectedTestCases: [],
};

export default TestSelection;
