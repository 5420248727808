import React from 'react';

import Channels from 'components/Channels';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  getProducts,
  getPublicKey,
  getQuarryChannels,
  getQuarryTestCases,
} from 'utils/api.service';

const withChannelsAndTestCases = (config) => {
  return (Component) => {
    return class extends React.Component {
      state = {
        environments: [],
        channels: [],
        loadingChannels: false,
        loadingTestCases: false,
        selectedChannelID: '',
        selectedChannelName: '',
        testCases: [],
        metaFields: [],
      };

      constructor() {
        super();

        this.handleChannelButtonClicked =
          this.handleChannelButtonClicked.bind(this);
        this.onEnvironmentChange = this.onEnvironmentChange.bind(this);
        this.renderChannels = this.renderChannels.bind(this);
      }

      componentDidMount() {
        this.loadChannels();
      }

      loadChannels() {
        this.setState({
          loadingChannels: true,
        });

        getQuarryChannels(config.category).then((response) => {
          const component = config.component.toLowerCase();
          let environments = '';
          let selectedEnvironment = '';
          let subCategory = [];

          if (response.data[component]) {
            environments = response.data[component].envlist;
            subCategory = response.data[component][config.accessor];
          } else {
            environments = response.data.envlist;
            subCategory = response.data[config.accessor];
          }

          selectedEnvironment = environments ? environments[0].env : 'stg';

          const channels = subCategory.filter(
            (c) => c.status[selectedEnvironment] === 'enabled'
          );
          const activeChannel = channels[0];

          this.setState({
            selectedChannelID: activeChannel.subcategoryid,
            selectedChannelName: activeChannel.subcategoryname,
            loadingChannels: false,
            loadingTestCases: true,
            environments,
            channels,
          });

          // retrieving first channel test cases
          this.loadTestCases(
            config.category,
            activeChannel.subcategoryid,
            activeChannel.subcategoryname
          );
        });
      }

      loadTestCases(category, channelID, channelName) {
        if (config.component === 'Products') {
          getPublicKey(); //this will get public key and store in session for later use
          getProducts(channelName, this.props.activeEnvironment).then(
            (response) => {
              let testCases = response.data.results;
              let metaFields = response.data.metafields;
              testCases = testCases.map((t) => ({
                ...t,
                orderid: response.data.orderid,
                ordertype: response.data.ordertype,
              }));
              this.setState({
                loadingTestCases: false,
                testCases,
                metaFields,
              });
            }
          );
        } else {
          getQuarryTestCases(
            category,
            channelID,
            this.props.activeEnvironment
          ).then((response) => {
            let testCases = response.data.testorders;
            testCases = testCases.filter((t) => t.status !== 'disabled');
            this.setState({
              loadingTestCases: false,
              testCases,
            });
          });
        }
      }

      checkSelectedItems() {
        const testCases = this.state.testCases.map((test) => {
          return test.status[this.props.activeEnvironment] === 'enabled'
            ? test
            : { ...test, checked: false };
        });

        this.setState({
          testCases,
        });
      }

      onEnvironmentChange() {
        this.checkSelectedItems();
      }

      handleChannelButtonClicked(channelID) {
        // prevent fetching test cases for the currently selected channel
        if (channelID !== this.props.selectedChannelID) {
          this.setState({
            loadingTestCases: true,
            selectedChannelID: channelID,
            selectedTestCaseID: null,
            testCases: [],
          });

          this.loadTestCases(
            config.category,
            channelID,
            this.state.channels.filter((c) => c.subcategoryid === channelID)[0]
              .subcategoryname
          );
        }
      }

      renderChannels(channels) {
        const channelsProps = {
          channels,
          onClick: this.handleChannelButtonClicked,
          selectedChannel: this.state.selectedChannelID,
          environment: this.props.activeEnvironment,
        };

        if (this.state.loadingChannels && !channels.length) {
          return <LoadingSpinner text="Loading channels" />;
        }

        return <Channels {...channelsProps} />;
      }

      render() {
        const componentProps = {
          handleChannelButtonClicked: this.handleChannelButtonClicked,
          handleTestCaseButtonClicked: this.handleTestCaseButtonClicked,
          onEnvironmentChange: this.onEnvironmentChange,
          renderChannels: this.renderChannels,
          ...this.state,
          ...this.props,
          pageHeader: config.component,
        };

        return <Component {...componentProps} />;
      }
    };
  };
};

export default withChannelsAndTestCases;
