import React from 'react';

import LoadingPage from 'components/LoadingPage';

function Login() {
  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get('code');
  let message = 'Initializing';

  if (authorizationCode) {
    message = 'Authenticating';
  }

  return <LoadingPage message={message} />;
}

export default Login;
