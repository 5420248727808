import React from 'react';
import { NavLink } from 'react-router-dom';

const name = ({ original }) => {
  const recordName =
    original.name === undefined ? original.executionname : original.name;
  return <span title={recordName}>{recordName}</span>;
};
const status = ({ original }) => {
  const currentStatus = original.overall_status || original.status;

  if (currentStatus) {
    const parsedStatus =
      currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1);

    return (
      <div>
        <span className={`status status-${currentStatus}`}></span>
        {parsedStatus}
      </div>
    );
  }

  return null;
};
const formatDateAndTime = ({ original }) => {
  const date = new Date(original.createddate);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
const transaction = ({ original }) => {
  const { service, executionid, transactionid, tools } = original;

  if (tools && tools === 'playwright') {
    return (
      <NavLink
        to={`/playwright/report/${executionid}/${transactionid}`}
        target="_blank"
      >
        {transactionid}
      </NavLink>
    );
  } else if (service && executionid && transactionid) {
    return (
      <NavLink
        to={`/view/${service}/${executionid}?transactionid=${transactionid}`}
      >
        {transactionid}
      </NavLink>
    );
  }

  return transactionid;
};
const execution = ({ original }) => {
  const { executionid, tools, totalcount, overall_status, status } = original;
  if (
    tools &&
    tools === 'playwright' &&
    totalcount !== '1' &&
    (['failed', 'success'].indexOf(overall_status) !== -1 ||
      ['failed', 'success'].indexOf(status) !== -1)
  ) {
    return (
      <NavLink to={`/playwright/report/${executionid}`} target="_blank">
        {executionid}
      </NavLink>
    );
  } else {
    return executionid;
  }
};
const tableDimensions = {
  name: {
    width: 300,
  },
  status: {
    width: 110,
  },
  environment: {
    width: 50,
  },
  executionID: {
    width: 300,
  },
  createddate: {
    width: 150,
  },
  sharedby: {
    width: 250,
  },
};

const columns = {
  name: {
    id: 'name',
    Header: 'Name',
    accessor: 'executionname',
    width: tableDimensions.name.width,
    Cell: name,
    resizable: true,
  },
  status: {
    id: 'status',
    Header: 'Status',
    accessor: 'overall_status',
    width: tableDimensions.status.width,
    Cell: status,
    resizable: true,
  },
  environment: {
    id: 'env',
    Header: 'Env',
    accessor: 'env',
    width: tableDimensions.environment.width,
    resizable: true,
  },
  executionid: {
    id: 'id',
    Header: 'Execution ID',
    accessor: 'executionid',
    Cell: execution,
    width: tableDimensions.executionID.width,
    resizable: true,
  },
  createdtime: {
    id: 'date',
    Header: 'Created time',
    accessor: 'createddate',
    Cell: formatDateAndTime,
    width: tableDimensions.createddate.width,
    resizable: true,
  },
  total: {
    Header: 'Total',
    accessor: 'totalcount',
    className: 'text-center',
  },
  sharedby: {
    Header: 'SharedBy',
    accessor: 'sharedby',
    width: tableDimensions.sharedby.width,
  },
};

const testcaseColumns = {
  scenario: {
    id: 'name',
    Header: 'Scenario',
    accessor: 'name',
    width: tableDimensions.name.width,
    Cell: name,
  },
  sharedby: {
    width: tableDimensions.sharedby.width,
  },
  status: {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    width: tableDimensions.status.width,
    Cell: status,
  },
  environment: {
    id: 'env',
    width: tableDimensions.environment.width,
  },
  trasactionid: {
    id: 'id',
    Header: 'Transaction ID',
    accessor: 'transactionid',
    width: tableDimensions.executionID.width,
    Cell: transaction,
  },
  created: {
    id: 'date',
    Header: 'Created time',
    accessor: 'createddate',
    Cell: formatDateAndTime,
    width: tableDimensions.createddate.width,
  },
  total: {},
};

const schema = {
  executions: [
    columns.name,
    columns.status,
    columns.environment,
    columns.executionid,
    columns.createdtime,
    columns.total,
  ],
  testCases: [
    testcaseColumns.scenario,
    testcaseColumns.status,
    testcaseColumns.environment,
    testcaseColumns.trasactionid,
    testcaseColumns.created,
    testcaseColumns.total,
  ],
  sharedreportExecutions: [
    columns.name,
    columns.sharedby,
    columns.status,
    columns.environment,
    columns.executionid,
    columns.createdtime,
    columns.total,
  ],
  sharedreportTestcases: [
    testcaseColumns.scenario,
    testcaseColumns.sharedby,
    testcaseColumns.status,
    testcaseColumns.environment,
    testcaseColumns.trasactionid,
    testcaseColumns.created,
    testcaseColumns.total,
  ],
};

export default schema;
