import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/LoadingSpinner';

import { getScreenshot } from 'utils/api.service';
import downloadFile from 'utils/downloadFile';

function Attachment(props) {
  // state
  const [isLoading, setIsLoading] = useState();
  // methods
  const downloadAttachment = () => {
    const request = getScreenshot(props.fileKey);

    setIsLoading(true);

    request.then((responses)=>{
      const content = responses.data;

      if (content.length) {
        const csvFile = `data: ${getFileMIMEType()};base64,${content}`;
        const csvURI = encodeURI(csvFile);

        downloadFile.fromContent(props.fileName, csvURI);
      }
    }).finally(()=>{
      setIsLoading(false);
    })
  };
  const getFileMIMEType = () => {
    switch (props.fileExt.toLowerCase()){
      case 'pdf':
        return 'application/pdf';
      case 'csv':
        return 'text/csv';
      case 'gif':
        return 'image/gif';
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpg';
      case 'xlsx' :
        return 'application/vnd.ms-excel';
      default:
        return 'text/text';
    }
  };

  return (
    <div className='row'>
      <div>
        <button className='button button-link no-padding' onClick={downloadAttachment}>{props.fileName}</button>
      </div>

      {isLoading ? <LoadingSpinner text='Downloading...'/> : null}
    </div>
  );
}

Attachment.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileKey: PropTypes.string.isRequired,
  fileExt: PropTypes.string.isRequired,
};

Attachment.defaultProps = {
  fileName: 'attachment',
};

export default Attachment;
