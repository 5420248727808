import React from 'react';
import { Switch, NavLink } from 'react-router-dom';

import Heading from 'components/Heading';

import routes from 'routes';

const Default = () => {
  return (
    <div className='Dash compact'>
      <div>
        <div className='fake-menu'>
          <img src='https://testing-hub-static-assets.s3.amazonaws.com/images/testinghub-logo-2.png' alt='' />
          <NavLink to='/testsuites'>{'< Back'}</NavLink>
        </div>
      </div>

      <div>
        <main className='AppContent'>
          <Heading />

          <Switch>
            { routes }
          </Switch>
        </main>
      </div>
    </div>
  );
}

export default Default;
