import { types } from 'store/actions/notification_actions';

const initialState = {
  notifications: [],
  lastNotificationAt: 0,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case types.PUSH_NOTIFICATION:
      const { notifications } = state;

      notifications.push({
        message: action.message,
        type: action.category,
        createdAt: +new Date(),
      });

      return {
        ...state,
        notifications,
        lastNotificationAt: +new Date(),
      };
    default:
      return state;
  }
};
