import { getNavigationData } from 'utils/api.service';
import { updateAvailableCategories } from 'store/actions/app_actions';
import { addNewNotification } from './notification_actions';

export const Types = {
  SET_CATEGORIES: 'menu/SET_CATEGORIES',
};

const setNavigationCategories = (categories) => {
  return {
    type: Types.SET_CATEGORIES,
    categories,
  };
};

export const fetchNavigationCategories = () => {
  return (dispatch, getState) => {
    const state = getState();
    const done = (data) => {
      dispatch(setNavigationCategories(data));
      dispatch(updateAvailableCategories(data));
    }

    if (state.menu && Object.entries(state.menu.categories).length > 0) {
      return Promise.resolve(() => {
        done(state.menu.categories);
      });
    }

    return getNavigationData().then((response) => {
      done(response.data);
    }).catch((error) => {
      dispatch(addNewNotification(error.message, 'danger'));
    });
  };
};
