/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AWS from 'aws-sdk';

import App from './containers/App/App';

import generateStore from './store';
import './styles/theme.scss';

// expression import
import './vendor/qualtrics';

AWS.config.update({
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }),
  region: 'us-east-1',
});

generateStore().then((store) => {
  const app = (
    <Provider store={store}>
      <App />
    </Provider>
  );

  ReactDOM.render(app, document.getElementById('root'));
});
