import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Attachment from 'components/Attachment';
import LoadingSpinner from 'components/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import Password from 'components/Password';
import {
  getCJTExecutionStatus,
  getTestdataExecutionStatus,
  getHostingStatus,
} from 'utils/api.service';
import downloadFile from 'utils/downloadFile';

const Transaction = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { executionid, service } = props.match.params;
  const transactionid = urlParams.get('transactionid');
  // state
  const [transactionData, setTransactionData] = useState({});
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [screenshotData, setScreenshotData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const stepRowRefs = useRef({});
  let showStepDetailButtonColumn;

  // lifecycle
  useEffect(() => {
    const endpoint = {
      cjt: getCJTExecutionStatus,
      testdata: getTestdataExecutionStatus,
      default: getHostingStatus,
    };
    const fn = endpoint[service] || endpoint.default;

    setIsRequestInProgress(true);

    fn(executionid, transactionid)
      .then(formatResponse)
      .then(() => {
        setIsRequestInProgress(false);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.detail);
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  }, []);

  const toggleStepDetailsSection = (event) => {
    // let key = event.target.parentElement.attributes['data-key'].value
    const element = event.target;
    element.classList.toggle('open');
    let key = element.attributes['data-key'].value;
    if (key) {
      const rowRef = stepRowRefs.current[key];
      if (rowRef) {
        if (rowRef.classList.contains('show')) {
          rowRef.classList.remove('show');
        } else {
          rowRef.classList.add('show');
        }
      }
    }
  };

  // methods
  const formatResponse = ({ data }) => {
    const response = {};

    // if request returned
    if (data) {
      response.name = data.name;
      response.status = data.status;
      response.createddate = data.createddate;
      response.message = data.message;
      response.tools = data.tools;
      response.reportUrl = data.reportUrl;

      response.overall = [
        {
          key: 'Overall status',
          value: data.status,
        },
      ];

      response.screenshots = data.screenshots;

      if (data.message && data.message.includes('contact is not mapped')) {
        response.overall = [
          {
            key: 'Overall status',
            value: 'failed',
          },
        ];
      }

      response.overall.push({ key: 'Message', value: data.message });

      if (data.screenshots && data.screenshots.length > 0) {
        response.overall.push({
          key: 'Screenshots',
          value: data.screenshots.join('__'),
        });
      }
      if (data.attachments && Object.keys(data.attachments).length > 0) {
        response.overall.push({
          key: 'Attachments',
          value: data.attachments,
        });
      }

      if (data.outputfiles && Object.keys(data.outputfiles).length > 0) {
        response.overall.push({
          key: 'OutputFiles',
          value: data.outputfiles,
        });
      }
      if (data.userinput && typeof data.userinput !== 'string') {
        let userInput = data.userinput;

        if (
          !Array.isArray(data.userinput) &&
          typeof data.userinput === 'object'
        ) {
          userInput = [];

          Object.entries(data.userinput).forEach(([key, value]) => {
            Object.entries(value).forEach(([key, val]) => {
              if (key === 'testdatafile' || key === 'testiterationfile') {
                userInput.push({ name: key, value: val });
              }
            });
            delete value.testdatafile;
            delete value.testiterationfile;
            userInput.push({ name: key, value: JSON.stringify(value) });
          });
        }

        response.userinput = userInput.map((entry) => {
          return { key: entry.name, value: entry.value };
        });
      }

      if (data.testdata) {
        let testdata = data.testdata;

        if (!Array.isArray(testdata)) {
          testdata = Object.entries(testdata).map((e) => ({
            name: e[0],
            value: e[1],
          }));
        }

        response.meta = testdata.map((entry) => ({
          key: entry.name,
          value: entry.value,
        }));
      }

      if (data.syncstatus) {
        response.syncstatus = data.syncstatus.map((entry) => ({
          key: entry.sync,
          value: entry.status,
          screenshots: entry.screenshots,
        }));
      }

      if (data.validations) {
        response.validations = data.validations.map((entry) => {
          return {
            key: entry.validation || entry.sync || entry.name,
            value: entry.status,
            screenshots: entry.screenshots,
          };
        });
      }

      if (data.steps) {
        response.steps = data.steps.map((entry) => ({
          key: entry.name,
          value: entry.status,
          screenshots: entry.screenshots,
          assertions: entry.assertions,
        }));
      }

      if (data.executiontime && Object.keys(data.executiontime).length > 0) {
        response.executiontime = Object.entries(data.executiontime).map(
          (entry) => ({ key: entry[0], value: entry[1] })
        );
      }

      if (data.output) {
        response.output = data.output.map((entry) => ({
          key: entry.name,
          value: entry.value,
        }));
      }

      if (data.executions) {
        response.executions = data.executions.map((entry) => ({
          key: entry.iterationame,
          value: entry.steps.map((step) => ({
            key: step.name,
            value: step.status,
          })),
        }));
      }

      if (data.logid) {
        response.logid = [
          {
            key: 'Log ID',
            value: data.logid,
          },
          {
            key: 'Created by',
            value: data.executedby || 'N/A',
          },
          {
            key: 'Created date',
            // convert date to YYYY-MM-DD format
            value: data.createddate.split('.')[0] || 'N/A',
          },
          {
            key: 'Last Modified date',
            value: data.modifieddate.split('.')[0] || 'N/A',
          },
        ];
      }

      if (data.ec2_ipaddress) {
        response.ec2_ipaddress = [
          {
            key: 'EC2 IP Address',
            value: data.ec2_ipaddress,
          },
        ];
      }

      setTransactionData(response);
    }

    return response;
  };
  // render methods
  const renderStatus = (status) => {
    if (status) {
      // bootstrap colors
      const colors = {
        failed: 'danger',
        success: 'success',
        completed: 'success',
        'in-progress': 'warning',
        queued: 'info',
      };
      const parsedStatus = status.charAt(0).toUpperCase() + status.slice(1);

      return (
        <div>
          <h4>
            <span class={`badge badge-${colors[status]}`}>{parsedStatus}</span>
          </h4>
        </div>
      );
    }

    return null;
  };
  const renderObjectAsList = (object) => {
    const listElements = Object.entries(object).map(([key, value]) => {
      let renderValue = value;

      if (typeof renderValue === 'object' && renderValue !== null) {
        return renderObjectAsList(renderValue);
      }

      return (
        <li>
          <strong>{key}</strong>: {renderValue}
        </li>
      );
    });

    return <ul className="list-group list-group-flush">{listElements}</ul>;
  };
  const renderPlain = (row) => {
    let rowContent = row.value;

    try {
      rowContent = JSON.parse(row.value);
    } catch {
      rowContent = row.value;
    }

    if (rowContent && typeof rowContent === 'object') {
      rowContent = renderObjectAsList(rowContent);
    } else {
      const passwordFields = ['password', 'pwd'];

      if (passwordFields.includes(row.key.toLowerCase())) {
        rowContent = <Password value={row.value} />;
      }
    }

    if (
      row.key.toLowerCase() === 'testdatafile' ||
      row.key.toLowerCase() === 'testiterationfile'
    ) {
      const onAnchorClicked = (e) => {
        e.preventDefault();

        downloadFile.fromURL(e.target.href);
      };
      const splittedValue = row.value.split('/');
      rowContent = (
        <strong>
          <a href={row.value} onClick={onAnchorClicked} download>
            {splittedValue[splittedValue.length - 1]}
          </a>
        </strong>
      );
    }

    if (row.key.toLowerCase() === 'screenshots') {
      rowContent = (
        <Link to={`/screenshots?id=${row.value}`} target="_blank">
          Screenshot
        </Link>
      );
    }

    if (
      row.key.toLowerCase() === 'attachments' &&
      Object.keys(row.value).length > 0
    ) {
      rowContent = Object.entries(row.value).map(([key, value]) => {
        return (
          <Attachment
            fileKey={value['key']}
            fileName={value['name']}
            fileExt={value['ext']}
          />
        );
      });

      rowContent = <div className="attachments">{rowContent}</div>;
    }

    if (
      row.key.toLowerCase() === 'outputfiles' &&
      Object.keys(row.value).length > 0
    ) {
      rowContent = Object.entries(row.value).map(([key, value]) => {
        return (
          <Attachment
            fileKey={value['key']}
            fileName={value['name']}
            fileExt={value['ext']}
          />
        );
      });
      rowContent = <div className="attachments">{rowContent}</div>;
    }

    return (
      <tr key={row.key}>
        <th scope="row">{row.key}</th>
        <td>{rowContent}</td>
      </tr>
    );
  };
  const renderValidation = (row) => {
    const queryParams =
      row.screenshots && row.screenshots.length > 0
        ? row.screenshots.join('__')
        : '';
    let screenshotColumn = null;
    let assertions = null;

    if (
      (row.screenshots && row.screenshots.length > 0) ||
      screenshotData.showScreenshotColumn
    ) {
      if (!screenshotData.showScreenshotColumn) {
        setScreenshotData({ showScreenshotColumn: true });
      }

      if (row.screenshots && row.screenshots.length > 0) {
        screenshotColumn = (
          <td>
            <Link to={`/screenshots?id=${queryParams}`} target="_blank">
              Screenshot
            </Link>
          </td>
        );
      } else {
        screenshotColumn = <td></td>;
      }
    }
    if (row.assertions && row.assertions.length > 0) {
      let assertionRows = row.assertions.map((a) => (
        <tr>
          <td className="col-9">{a.message}</td>
          <td className="col-1">
            <span className={`job-status ${a.status}`}></span>
            {a.status}
          </td>
        </tr>
      ));
      assertions = (
        <table>
          <thead>
            <tr>
              <th className="col-8">Message</th>
              <th className="col-2">Status</th>
            </tr>
          </thead>
          <tbody>{assertionRows}</tbody>
        </table>
      );
    }
    return (
      <React.Fragment>
        <tr key={row.key}>
          <th scope="row">
            <div className="step-view-button">
              <div
                data-key={row.key}
                className={
                  assertions ? 'step-toggle enable' : 'step-toggle disable'
                }
                onClick={toggleStepDetailsSection}
              ></div>
              <div>{row.key}</div>
            </div>
          </th>
          <td>
            <span className={`job-status ${row.value}`}></span>
            {row.value}
          </td>
          {screenshotColumn}
        </tr>
        {assertions && (
          <tr
            className="step-details-section hide"
            ref={(el) => (stepRowRefs.current[row.key] = el)}
            key={row.key + '_assertion'}
          >
            <td className="header" colSpan="4">
              {assertions}
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };
  const renderTables = (content) => {
    const displayNames = {
      overall: '',
      userinput: 'User input',
      meta: 'Output',
      syncstatus: 'Validations',
      validations: 'Validations',
      steps: 'Steps',
      executiontime: 'Execution Time',
      output: 'Output',
      executions: 'Iterations',
      logid: '',
      ec2_ipaddress: '',
    };
    const tables = [];

    Object.keys(displayNames).forEach((entry) => {
      if (content[entry] && content[entry].length && content[entry]) {
        if (entry !== 'executions') {
          const renderFn = {
            validations: renderValidation,
            steps: renderValidation,
            syncstatus: renderValidation,
            default: renderPlain,
            executiontime: renderPlain,
          };

          showStepDetailButtonColumn =
            content && content['steps']
              ? content['steps'].some(
                  (e) => e.assertions && e.assertions.length > 0
                )
              : false;
          console.log(
            'showStepDetailButtonColumn: ' + showStepDetailButtonColumn
          );
          const rows = content[entry].map(renderFn[entry] || renderFn.default);

          tables.push(
            <section className="jobs-queue-table">
              {displayNames[entry] && <h4>{displayNames[entry]}</h4>}

              <table className="table table-bordered table-hover" key={entry}>
                <tbody>{rows}</tbody>
              </table>
            </section>
          );
        } else {
          tables.push(
            <section className="jobs-queue-table">
              {displayNames[entry] && <h4>{displayNames[entry]}</h4>}

              {content[entry].map(this.renderExecutions)}
            </section>
          );
        }
      }
    });

    return tables;
  };
  // render
  let body;
  let status;

  // loading
  if (isRequestInProgress) {
    return <LoadingSpinner text="Loading transaction" />;
  }

  // error
  if (errorMessage) {
    return (
      <div class="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  body = renderTables(transactionData);
  status = renderStatus(transactionData.status);

  return (
    <div className="mb-5">
      <PageTitle
        title={transactionData.name}
        description={transactionData.message}
      >
        {status}
      </PageTitle>
      {body}
    </div>
  );
};

export default Transaction;
