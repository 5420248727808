import React from 'react';

class Loader extends React.Component {
  render() {
    return (
        <div className="lds-circle">
          <div></div>
        </div>
    );
  }
}

export default Loader;
