import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class CollapsibleContent extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
  }

  static defaultProps = {
    title: '',
  }

  state = {
    open: false,
  }

  constructor() {
    super();

    this.onTitleClick = this.onTitleClick.bind(this);
  }

  onTitleClick() {
    this.setState(state => {
      return { open: !state.open };
    });
  }

  render() {
    const sectionProps = {
      className: classname({
        'collapsable-content': true,
        'open': this.state.open,
      }),
    };
    const text = this.state.open ? 'collapse' : 'expand';

    return (
      <section {...sectionProps}>
        <div className='collapsable-content-title' onClick={this.onTitleClick}>{ this.props.title }</div>
        <h6 className='collapsable-content-info'>click to { text }</h6>

        <div className='collapsable-content-body'>{ this.props.children }</div>
      </section>
    );
  }
}

export default CollapsibleContent;
