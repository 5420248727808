import oauth2 from 'simple-oauth2';
import AWS from 'aws-sdk';

const INTERVAL = 30 * 60 * 1000;

const REDIRECT_URI = `${process.env.REACT_APP_HOST}/auth`;

export const TYPES = {
  LOGIN_ERROR_HANDLER: 'auth/ERROR',
  LOGIN_PROCESS: 'auth/AUTHING',
  LOGOUT_PROCESS: 'auth/LOGOUT',
  LOGIN_SET: 'auth/AUTH_SETTING',
  LOGIN_REFRESH: 'auth/REFRESH',
};

class Session {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.oauth2 = oauth2.create({
      client: {
        id: process.env.REACT_APP_FORGE_APP_ID,
      },
      auth: {
        authorizeHost: 'https://developer.api.autodesk.com',
        authorizePath: '/authentication/v2/authorize',
        tokenHost: 'https://developer.api.autodesk.com',
        tokenPath: '/authentication/v2/gettoken',
      },
    });

    this.login = this.login.bind(this);
    this.renew = this.renew.bind(this);
    this.clear = this.clear.bind(this);
    this.resetInactivityTimer = this.resetInactivityTimer.bind(this);
    this.setLogoutTimeout = this.setLogoutTimeout.bind(this);
  }

  generateStateHash(length) {
    const validCharacters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const numberOfCharacters = validCharacters.length;
    let hash = '';

    for (let i = 0; i < length; i++) {
      const randomCharacterIndex = Math.floor(
        Math.random() * numberOfCharacters
      );

      hash += validCharacters.charAt(randomCharacterIndex);
    }

    return hash;
  }

  login() {
    const scope = 'data:read';
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('code')) {
      const authorizationCode = urlParams.get('code');
      const requestState = urlParams.get('state');
      const previousRequestState = sessionStorage.getItem('th-oauth2-state');

      if (previousRequestState === requestState) {
        const lambda = new AWS.Lambda({
          region: 'us-east-1',
          apiVersion: '2015-03-31',
        });
        const fnParams = {
          FunctionName: process.env.REACT_APP_AUTH_FUNCTION_NAME,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify({
            token_type: 'access',
            token: authorizationCode,
            redirect_uri: REDIRECT_URI,
          }),
        };

        lambda.invoke(fnParams, (error, response) => {
          const payload = JSON.parse(response.Payload);

          if (payload.errorType === 'Exception') {
            this.dispatch({
              type: TYPES.LOGIN_PROCESS,
              user: {},
              err: 'Could not authenticate user. Please try again by refreshing the page.',
              auth: false,
            });
          } else {
            this.dispatch({
              type: TYPES.LOGIN_PROCESS,
              user: {
                attributes: {
                  email: '',
                  email_verified: false,
                  sub: '',
                },
                signInUserSession: {
                  accessToken: {
                    jwtToken: payload.access_token,
                  },
                  idToken: {
                    jwtToken: payload.access_token,
                  },
                  refreshToken: {
                    token: payload.refresh_token,
                  },
                },
                username: '',
                expires_at: +new Date() + payload.expires_in * 1000,
              },
              err: '',
              auth: true,
            });

            this.setRenewInterval();
            this.setLogoutTimeout();
          }
        });
      } else {
        console.error('request state is not valid');
      }
    } else {
      const state = this.generateStateHash(64);
      const authorizationUri = this.oauth2.authorizationCode.authorizeURL({
        redirect_uri: REDIRECT_URI,
        scope,
        state,
        authoptions: '{"ld":"autodesk.com"}',
      });

      sessionStorage.setItem('th-oauth2-state', state);

      window.location.href = authorizationUri;
    }
  }

  resetInactivityTimer() {
    window.clearTimeout(this.logoutTimeout);

    this.logoutTimeout = setTimeout(this.clear, 3600000); // 1 hour
  }

  setRenewInterval() {
    this.renewalInteval = setInterval(this.renew, INTERVAL);
  }

  setLogoutTimeout() {
    document.addEventListener('mousemove', this.resetInactivityTimer);
    document.addEventListener('mousedown', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
    document.addEventListener('touchmove', this.resetInactivityTimer);

    this.logoutTimeout = setTimeout(this.clear, 3600000);
  }

  renew() {
    const { refreshToken } = JSON.parse(localStorage.getItem('th-state')).user
      .auth.signInUserSession;
    const lambda = new AWS.Lambda({
      region: 'us-east-1',
      apiVersion: '2015-03-31',
    });
    const fnParams = {
      FunctionName: process.env.REACT_APP_AUTH_FUNCTION_NAME,
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
        token_type: 'refresh',
        token: refreshToken.token,
      }),
    };

    lambda.invoke(fnParams, (error, response) => {
      const payload = JSON.parse(response.Payload);

      // if renew fails then reload entire page
      if (error) {
        // if (e.message === 'Network Error') {
        localStorage.clear();
        window.location.reload();
        // }
      }

      if (!payload.errorType) {
        this.dispatch({
          type: TYPES.LOGIN_REFRESH,
          user: {
            attributes: {
              email: '',
              email_verified: false,
              sub: '',
            },
            signInUserSession: {
              accessToken: {
                jwtToken: payload.access_token,
              },
              idToken: {
                jwtToken: payload.access_token,
              },
              refreshToken: {
                token: payload.refresh_token,
              },
            },
            username: '',
            expires_at: +new Date() + payload.expires_in * 1000,
          },
        });
      }
    });
  }

  clear() {
    localStorage.clear();

    this.dispatch({
      type: TYPES.LOGOUT_PROCESS,
    });

    this.resetTimers();
  }

  resetTimers() {
    clearInterval(this.renewalInteval);
    clearTimeout(this.logoutTimeout);

    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('mousedown', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
    document.removeEventListener('touchmove', this.resetInactivityTimer);
  }
}

export default Session;
