export const Types = {
  DONE_LOADING: 'app/done_loading',
  UPDATE_ENVIRONMENT: 'app/update_environment',
  UPDATE_MENU_CATEGORY_ID: 'app/update_menu_category_id',
  UPDATE_MENU_AVAILABLE_CATEGORIES: 'app/UPDATE_MENU_AVAILABLE_CATEGORIES',
  UPDATE_USER_INFO: 'app/UPDATE_USER_INFO'
};

export const appDoneLoading = () => ({
  type: Types.DONE_LOADING,
});

export const updateEnvironment = (context, environment) => dispatch => {
  dispatch({
    type: Types.UPDATE_ENVIRONMENT,
    context,
    environment,
  });
};

export const updateMenuCategoryID = (service, category, categoryName) => ({
  type: Types.UPDATE_MENU_CATEGORY_ID,
  service,
  category,
  categoryName,
});

export const updateUserInfo = (userInfo) => ({
  type: Types.UPDATE_USER_INFO,
  userInfo,
});

export const updateAvailableCategories = (categories) => ({
  type: Types.UPDATE_MENU_AVAILABLE_CATEGORIES,
  categories,
});
