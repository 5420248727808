import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getNavigationData } from 'utils/api.service';
import {
  updateMenuCategoryID,
  updateAvailableCategories,
  updateUserInfo,
} from 'store/actions/app_actions';
import { updateUserRole } from 'store/actions/hosting_actions';

import MenuDropdown from 'components/MenuDropdown';
import schema from './schema';

class Menu extends React.Component {
  state = {
    drawers: {},
  };

  constructor() {
    super();

    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount() {
    getNavigationData().then((response) => {
      this.setState({ navigation: response.data });

      this.props.updateAvailableCategories(response.data);
      this.props.updateUserInfo(response.data.userinfo);
    });
  }

  toggleDrawer(drawer) {
    this.setState((state) => ({
      drawers: { ...state.drawers, [drawer]: !state.drawers[drawer] },
    }));
  }

  setSelectedCategory(service, id) {
    const hostingWorkstream = this.props.categories.projects.find(
      (p) => p.org === service
    );
    let categoryName;

    if (hostingWorkstream) {
      categoryName = hostingWorkstream.workstream.find(
        (w) => w.workstreamname === id
      ).displayname;

      this.props.updateUserRole(hostingWorkstream.role);
    } else {
      const service = this.props.categories.thservices.find(
        (s) => s.servicename === service
      );

      if (service) {
        const category = service.categories.find((c) => c.categoryid === id);

        categoryName = category.categoryname;
      }
    }

    this.props.updateMenuCategoryID(service, id, categoryName);
  }

  renderMenuCategories(items = [], baseUrl, serviceName) {
    return items.map((item) => {
      const categoryIdentifier = item.categoryname || item.workstreamname;
      const parsedCategoryName = categoryIdentifier
        .toLowerCase()
        .split(' ')
        .join('_');
      const categoryName =
        serviceName === 'testdata'
          ? item.categoryname
          : item.categoryid || item.workstreamname;
      const itemProps = {
        id: item.categoryid || item.id,
        to: `${baseUrl}/${parsedCategoryName}`,
        onClick: () => this.setSelectedCategory(serviceName, categoryName),
      };
      let navItem = (
        <NavLink {...itemProps}>
          <span>{item.categoryname || item.displayname}</span>
        </NavLink>
      );

      if (item.status === 'disabled') {
        navItem = navItem = (
          <span className="disabled">
            {item.categoryname || item.displayname}
          </span>
        );
      }

      return <li key={itemProps.id}>{navItem}</li>;
    });
  }

  renderDropdowns(categories) {
    return schema.map((service) => {
      // hosting has a different structure
      if (service.servicename === 'hosting') {
        const hostedServices = categories[service.accessor];

        if (hostedServices) {
          const items = hostedServices.map((hostedService) => {
            const dropdownProps = {
              items: this.renderMenuCategories(
                hostedService[service.children],
                service.baseUrl,
                hostedService.name
              ),
              title: hostedService.displayName,
            };

            return (
              <MenuDropdown
                key={hostedService.servicename}
                {...dropdownProps}
              />
            );
          });
          const dropdownProps = {
            items,
            title: service.displayName,
          };

          if (items.length) {
            return (
              <MenuDropdown key={service.servicename} {...dropdownProps} />
            );
          }
        }

        return null;
      } else {
        if (service.accessor && categories[service.accessor]) {
          const category = categories[service.accessor].find(
            (c) => c.servicename === service.servicename
          );
          const subcategories = category[service.children];
          const dropdownProps = {
            items: this.renderMenuCategories(
              subcategories,
              service.baseUrl,
              service.servicename
            ),
            title: service.displayName,
          };

          return <MenuDropdown key={service.servicename} {...dropdownProps} />;
        }

        return (
          <NavLink to={service.baseUrl} key={service.servicename}>
            <span>{service.displayName}</span>
          </NavLink>
        );
      }
    });
  }

  render() {
    const navigation = this.renderDropdowns(this.props.categories);

    return (
      <nav className="menu">
        <NavLink className="menu-brand" to="/">
          <img
            className="logo"
            src="https://testing-hub-static-assets.s3.amazonaws.com/images/ADSK_LOGO_NEW.png"
            alt="Autodesk"
          />
        </NavLink>

        <div className="menu-section">{navigation}</div>

        <div className="menu-links">
          <NavLink to="/jobsqueue">View jobs queue</NavLink>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.menu.categories,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserRole: (role) => dispatch(updateUserRole(role)),
  updateUserInfo: (userInfo) => dispatch(updateUserInfo(userInfo)),
  updateAvailableCategories: (categories) =>
    dispatch(updateAvailableCategories(categories)),
  updateMenuCategoryID: (service, id, categoryName) =>
    dispatch(updateMenuCategoryID(service, id, categoryName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
