import { Types } from 'store/actions/quarry_actions';

const quarryReducerDefaultState = {
  error: "",
  isSending: false,
  submitRes: "",
  statusCode: "",
  data: null,
  status:''
};

export default (state = quarryReducerDefaultState, action) => {
  switch (action.type) {
    case Types.FETCH_OPTIONS:
      return {
        ...state,
        error: action.err,
        meta: action.result
      };
    case Types.FETCH_META_VALS:
      return {
        ...state,
        fields: action.res
      };
    case Types.IS_SENDING:
      return {
        ...state,
        isSending: action.sending,
        status: action.status,
        data: action.data,
      };
    case Types.RESET_SUCCESS:
      return {
        ...state,
        isSending: action.sending,
        submitRes: action.message,
        statusCode: action.status,
        status: null,
        message: null,
        data: null
      };
    default:
      return state;
  }
};
