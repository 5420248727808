import React, { useState } from 'react';
import PropTypes from 'prop-types';

const JSONFields = (props) => {
  // state
  const [values, setValues] = useState(JSON.parse(props.json));
  // user actions
  const onJSONFieldChanged = (e) => {
    const updatedJSON = {...values, ...{ [e.target.name]: e.target.value }};

    setValues(updatedJSON);

    props.onChange({
      target: {
        name: props.name,
        value: JSON.stringify(updatedJSON),
      }
    });
  };
  // render methods
  const renderFields = (json) => {
    return Object.entries(values).map(([key, value]) => (
      <div className='col form-group' key={key}>
        <label htmlFor={key}>{ key }</label>
        <input className='form-control' name={key} type='text' value={values[key]} onChange={onJSONFieldChanged} />
      </div>
    ));
  };
  // render
  const fields = renderFields(props.json);

  return (
    <div className='json-fields-wrapper'>
      { fields }
    </div>
  );
};

JSONFields.propTypes = {
  json: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default JSONFields;
