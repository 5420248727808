import { Types } from 'store/actions/jobsQueue_actions';

const jobsQueueReducerDefaultState = {
  defaultSource: "",
  defaultFilter: "",
};

export default (state = jobsQueueReducerDefaultState, action) => {
  switch (action.type) {
    case Types.FETCH_INTIAL_SET:
      return {
        ...state,
        data: action.res,
        next: action.pag
      };
    case Types.FETCH_VALIDATIONS:
      return {
        ...state,
        validations: action.payload,
        noValMsg: action.msg
      };
    case Types.TRIGGER_VAL_LOADING:
      return {
        ...state,
        isLoadingValidations: action.isLoading
      };
    case Types.SET_DEFAULT_FILTERS:
      return {
        ...state,
        defaultSource: action.source
      };
    case Types.RESET_DEFAULTS:
      return {
        ...state,
        defaultSource: "",
        defaultFilter: ""
      };
    default:
      return state;
  }
};
