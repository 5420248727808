import React, { useState } from 'react';
import PropTypes from 'prop-types';

const JiraFields = (props) => {
  // state
  const [updateJira, setUpdateJira] = useState(false);
  // user actions
  const onJiraCheckboxClicked = (e) => {
    if (!e.target.checked) {
      props.onChange({
        target: {
          name: 'jiraTestCycleId',
          value: undefined,
        }
      });
    }

    setUpdateJira(e.target.checked);
  };
  // render
  const renderJiraFields = () => {
    return (
      <div className='row'>
        <div className='col-12 form-group' key='jiraTestCycleId'>
          <label htmlFor='jiraTestCycleId'>JIRA Test Cycle ID <span className='mandatory'>*</span></label>
          <input className='form-control' name='jiraTestCycleId' type='text' onChange={props.onChange} />
        </div>
      </div>
    );
  };

  return (
    <div className='col-12 jira-fields-wrapper'>
      <div className='form-group jira-fields-checkbox' key='updatejira'>
        <input className='form-control' name='updatejira' type='checkbox' onChange={onJiraCheckboxClicked} />
        <label htmlFor='updatejira'>Update Jira</label>
      </div>

      { updateJira ? renderJiraFields() : null }
    </div>
  );
};

JiraFields.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default JiraFields;
