import React from 'react';

import './Tooltip.sass';

class Tooltip extends React.PureComponent {
  render() {
    const icon = this.props.icon || <span className='info-tooltip-icon'>i</span>;

    return (
      <div className='info-tooltip'>
        { icon }

        <p className='info-tooltip-text'>{ this.props.text }</p>
      </div>
    );
  }
}

export default Tooltip;
