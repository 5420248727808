import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateEnvironment } from 'store/actions/app_actions';

class EnvironmentSwitch extends React.PureComponent {
  static propTypes = {
    environments: PropTypes.array.isRequired,
    context: PropTypes.string.isRequired,
  }

  static defaultProps = {
    environments: [],
  }

  constructor() {
    super();

    this.onEnvChange = this.onEnvChange.bind(this);
  }

  componentDidMount() {
    this.checkValidEnvironment();
  }

  componentDidUpdate() {
    this.checkValidEnvironment();
  }

  checkValidEnvironment() {
    const { props } = this;

    // if there are environments available
    if (props.environments.length) {
      // if there aren't any selected environments for context
      if (!props.environment[props.context]) {
        this.props.updateEnvironment(this.props.context, this.props.environments[0].env);
      }

      // if the selected environment is not in the environments array
      if (!props.environments.find(e => e.env === props.environment[props.context])) {
        if (props.environments.length) {
          this.props.updateEnvironment(props.context, props.environments[0].env);
        }
      }
    }
  }

  onEnvChange(e) {
    this.props.updateEnvironment(this.props.context, e.target.value);
  }

  render() {
    const envs = this.props.environments.map(env => {
      return <option key={env.env} value={env.env}>{ env.displayName || env.displayname }</option>;
    });

    return (
      <div className='environment-switching'>
        <label htmlFor='environment'>Environment</label>

        <select className='form-control' name='environment' value={this.props.environment[this.props.context]} onChange={this.onEnvChange}>
          { envs }
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  environment: state.app.environment,
  lastUpdate: state.app.environment.lastUpdate, // hack for react to rerender component
});

const mapDispatchToProps = (dispatch) => ({
  updateEnvironment: (context, environment) => dispatch(updateEnvironment(context, environment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentSwitch);
