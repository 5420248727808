import { addNewNotification } from 'store/actions/notification_actions';
import { getHostingProjectTestCases, postHostingProjectTestCase, deleteHostingProject } from 'utils/api.service';

export const Types = {
  UPDATE_ROLE: 'hosting/UPDATE_ROLE',
  SUBMIT_BEGIN: 'hosting/SUBMIT_BEGIN',
  SUBMIT_END: 'hosting/SUBMIT_END'
};

export const updateUserRole = (role) => ({
  type: Types.UPDATE_ROLE,
  role,
});

export const removeHostingProject = (workstream, project) => (dispatch) => {
  return deleteHostingProject(workstream, project).catch((error) => {
    const { response } = error;

    if (response.data && response.data.detail) {
      dispatch(addNewNotification(response.data.detail, 'danger'));
    }

    return error;
  });
};

export const getHostingTestCases = (org, workstream, project) => {
  return getHostingProjectTestCases(org, workstream, project).then((response) => {
    response.data.testcases = response.data.results.map((result) => {
      result.name = result.displayname;
      result.testcaseid = result.id;
      result.subcategoryname = project;

      return result;
    });

    response.data.workstream = workstream;
    response.data.subcategoryid = workstream;

    return response;
  });
};

export const submitTestCases = (project, payload) => dispatch => {
  dispatch({ type: Types.SUBMIT_BEGIN });
  dispatch({ type: 'quarry/RESETTING_DEFAULT' });
  dispatch({ type: 'MESH_CLEAR_TESTCASES' });

  return postHostingProjectTestCase(project, payload).then(response => {
    dispatch({ type: Types.SUBMIT_END, status: response.status, data: response.data });
  }).catch(error => {
    if(error.response) {
      dispatch({ type: Types.SUBMIT_END, status: error.response.status, data: error.response.data });
    } else {
      dispatch({ type: Types.SUBMIT_END, status: 400, data: {message: error.message} });
    }
  });
};
