import React, { useState } from 'react';
import Modal from '../../components/Modal';

const MoveTestCasesModal = ({
  sourceProject,
  projects = [],
  onClose = () => {},
  onSubmit = () => {},
  loading = false,
  selectedTestCases = [],
}) => {
  const [targetProject, setTargetProject] = useState(sourceProject);
  const onTargetProjectChange = (e) => {
    setTargetProject(e.target.value);
  };

  return (
    <Modal
      title="Move Test Cases in Bulk"
      onClose={onClose}
      onSubmit={() => onSubmit(targetProject)}
      loading={loading}
    >
      <div className="modal-form-common-fields">
        <div className="form-group bulk-modal-form-group">
          <label htmlFor="target-project" className="testcases-list__heading">
            Move to Project
          </label>
          <select
            className="form-control"
            id="target-project"
            name="toproject"
            defaultValue={sourceProject}
            onChange={onTargetProjectChange}
          >
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.displayname}
              </option>
            ))}
          </select>
        </div>
        <span className="testcases-list__heading">
          <h6>{`${selectedTestCases.length} test cases selected`}</h6>
        </span>
        <hr />
        {selectedTestCases.map((testCaseName) => (
          <div className="testcase-list-item">{testCaseName}</div>
        ))}
      </div>
    </Modal>
  );
};
export default MoveTestCasesModal;
