import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Session from 'utils/session.service';

class PublicRoute extends React.Component {
  componentDidMount() {
    if (!this.props.isAuthenticated) {
      const session = new Session(this.props.dispatch);

      session.login();
    }
  }

  render() {
    const { props } = this;
    const Component = props.component;

    if (props.isAuthenticated) {
      const redirect = sessionStorage.getItem('th-referer');

      // clear referer
      sessionStorage.removeItem('th-referer');

      return <Redirect to={redirect} />
    }

    return <Route {...props} render={<Component {...props} />} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(PublicRoute);
