function debouncePromise(fn, delay) {
  let timeout;

  return function(...args) {
    const context = this;

    clearTimeout(timeout);

    return new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        const response = await fn.apply(context, args).catch(error => {
          reject(error);
        });

        resolve(response);
      }, delay);
    });
  };
}

export default debouncePromise;
