import React from 'react';
import PropTypes from 'prop-types';

import SKUFieldGroup from 'components/SKUFieldGroup';
import LoadingSpinner from 'components/LoadingSpinner';

import { getQuarrySKUs } from 'utils/api.service';

class CustomSKUInput extends React.Component {
  static propTypes = {
    updateTemporaryLineItem: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,

    autoload: PropTypes.bool,
    placeholder: PropTypes.string,
    upwards: PropTypes.bool,
    lineitems: PropTypes.array,
  }

  static defaultProps = {
    autoload: true,
    placeholder: '',
    upwards: false,
    lineitems: [],
  }

  state = {
    lineitems: [],
    skus: new Map(),
    requestMade: false,
  }

  options = {}

  constructor() {
    super();

    this.updateLineItems = this.updateLineItems.bind(this);
    this.removeLineItem = this.removeLineItem.bind(this);
    this.handleInputValue = this.handleInputValue.bind(this);
    this.loadSKUs = this.loadSKUs.bind(this);
  }

  componentDidMount() {
    // set default values
    if (this.props.default) {
      const key = this.props.channel === 'BIC' ? 'priceid' : 'partnumber';
      const lineitems = [];
      let defaultValues = this.props.default;

      if (!this.props.isMultiple && this.props.skutype.length === 1) {
        defaultValues = defaultValues.slice(-1);
      }

      defaultValues.forEach((lineitem) => {
        lineitems.push({
          [key]: lineitem.partnumber,
          quantity: +lineitem.quantity,
          skutype: lineitem.skutype,
          displayname: lineitem.displayname,
          productname: lineitem.productname,
        });
      });

      this.setState({ lineitems }, () => {
        this.props.onLineItemsUpdated(this.state.lineitems);
      });
    }

    if (this.props.autoload) {
      this.loadSKUs();
    } else {
      if (Array.isArray(this.props.skutype)) {
        this.props.skutype.forEach((skutype) => {
          this.options[skutype.type] = [];
        });
      }

      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region) {
      this.loadSKUs();
    }
  }

  loadSKUs() {
    const { props } = this;
    const skuMap = new Map(this.state.skus);

    this.setState({ requestMade: true });

    this.props.skutype.forEach((skutype) => {
      const fulfillment = props.fulfillment === 'prefill' ? 'pre-fill' : props.fulfillment;
      const channel = props.channel.toLowerCase();

      getQuarrySKUs(skutype.type, fulfillment, props.region.toLowerCase(), channel, props.environment).then(response => {
        const prefilledOptions = response.data.results;

        prefilledOptions.forEach((option) => {
          skuMap.set(option.sku, { type: skutype.type, name: option.name });
        });

        this.options[skutype.type] = prefilledOptions;

        this.setState({ skus: skuMap });
      });
    });
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];

      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  canAddMoreSKUs(displayName) {
    if (this.props.isMultiple) {
      return true;
    } else {
      if (this.state.lineitems.length === 0 ||
        this.state.lineitems.filter(line=>line.displayname === displayName).length === 0) {
        return true
      }
    }
    return false;
  }

  updateLineItems(selectedOption={}, quantity, skuType) {
    this.setState((state) => {
      const { lineitems } = state;
      const { skus } = state;
      const key = this.props.channel === 'BIC' ? 'priceid' : 'partnumber';

      if (!skus.get(selectedOption.sku)) {
        skus.set(selectedOption.sku, { type: skuType, name: selectedOption.sku });
      }

      lineitems.push({
        [key]: selectedOption.sku,
        quantity: +quantity,
        skutype: skuType.type,
        displayname: skuType.displayname,
        productname: selectedOption.name,
      });

      return { lineitems, skus };
    }, () => {
      this.props.onLineItemsUpdated(this.state.lineitems);
    });
  }

  removeLineItem(index) {
    return () => {
      const { state } = this;
      const lineitems = state.lineitems.slice();

      lineitems.splice(index, 1);

      this.setState({ lineitems }, () => {
        this.props.onLineItemsUpdated(this.state.lineitems);
      });
    }
  }

  handleInputValue(inputName) {
    return ({ target }) => {
      this.setState({ [inputName]: target.value });
    }
  }

  renderInputs() {
    const inputs = [];

    for (let i = 0; i < this.props.skutype.length; i++) {
      const skutype = this.props.skutype[i];

      if (this.options[skutype.type]) {
        const fieldGroupOptions = {
          key: `input-${i}`,
          config: this.props.lineitems[i],
          options: this.options[skutype.type],
          displayName: skutype.displayname,
          addLineItem: this.updateLineItems,
          skuType: skutype,
          updateTemporaryLineItem: this.props.updateTemporaryLineItem,
          simple: this.props.simple,
          placeholder: this.props.placeholder,
          upwards: this.props.upwards,
          skuLabel: this.props.channel === 'BIC' ? 'priceid' : 'sku',
          disabled: !this.canAddMoreSKUs(skutype.displayname),
          isMultiple: this.props.isMultiple,
        };

        if (this.props.simple) {
          delete fieldGroupOptions.addLineItem;

          fieldGroupOptions.displayName = 'Base product';
        }

        inputs.push(<SKUFieldGroup {...fieldGroupOptions} />);
      } else if (this.props.autoload) {
        inputs.push(
          <div className='col-sm-12' key={`input-${i}`}>
            <LoadingSpinner text='Loading products' />
          </div>
        );
      }
    }

    return inputs;
  }

  renderLineItems() {
    const { state } = this;

    return state.lineitems.map((lineitem, index) => {
      const key = this.props.channel === 'BIC' ? 'priceid' : 'partnumber';
      const item = state.skus.get(lineitem[key]);
      let name = item ? (item.displayname || item.name) : lineitem[key];

      if (lineitem.productname) {
        name = lineitem.productname;
      }

      if (this.props.skutype.length > 1) {
        name = <span><strong>{lineitem.displayname}</strong> {name}</span>
      }

      return (
        <div className='multiple-skus-lineitem-tag' key={`${lineitem[key]}-${lineitem.quantity}-${index}`}>
          <span className='lineitem-tag-sku'>{ name } ({ lineitem.quantity })</span>
          <button type='button' className='lineitem-tag-remove' onClick={this.removeLineItem(index)}>x</button>
        </div>
      );
    });
  }

  render() {
    const lineItems = this.renderLineItems();
    const onClick = !this.props.autoload && !this.state.requestMade ? this.loadSKUs : () => {};
    const inputs = this.renderInputs();

    return (
      <div className='row' onClick={onClick}>
        { inputs }

        <div className='col-sm-12'>
          { lineItems }
        </div>
      </div>
    );
  }
}

export default CustomSKUInput;
