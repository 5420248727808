import React from 'react';

import Compact from './Compact';
import Default from './Default';
import Screenshot from "../../components/Screenshot";
import PlaywrightReport from "../../components/PlaywrightReport";

const rules = {
  screenshots: Screenshot,
  compact: Compact,
  default: Default,
  playwrightReport: PlaywrightReport
};

function resolveLayout(props) {
  const regEx = new RegExp(/\/(testsuites)\/[a-zA-Z0-9-]{1,}/);
  let Layout = null;

  if (regEx.test(props.location.pathname)) {
    Layout = rules.compact;
  } else if (props.location.pathname === '/screenshots') {
    Layout = rules.screenshots;
  } else if (props.location.pathname.startsWith('/playwright/report/')) {
    Layout = rules.playwrightReport;
  } else {
    Layout = rules.default;
  }

  return <Layout {...props} />;
}

export default resolveLayout;
