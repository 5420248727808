import React from 'react';
import PropTypes from 'prop-types';

const TotalSelectedBadge = (props) => {
  // render
  let message;

  if (props.total === 0) {
    return null;
  }

  if (props.selected) {
    message = `Selecting ${props.selected} out of ${props.total} tests`;
  } else {
    message = `Showing ${props.available} out of ${props.total} tests`;
  }

  return <span className="badge total-select-badge">{ message }</span>;
};

TotalSelectedBadge.propTypes = {
  available: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default TotalSelectedBadge;
