import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import CollapsibleContent from 'components/CollapsibleContent';
import SearchableDropdown from 'components/SearchableDropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import FlashMessage from 'components/FlashMessage';
import Tooltip from 'components/Tooltip';
import CustomSKUInput from 'components/CustomSKUInput';
import { setDefaultFilter } from 'store/actions/jobsQueue_actions';

import { getQuarrySKUs } from 'utils/api.service';
import NotificationField from "components/NotificationField";

class CJTModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    environment: PropTypes.string.isRequired,
    scenarios: PropTypes.array.isRequired,
    metafields: PropTypes.object.isRequired,
  }

  state = {
    latestsUpdate: 0,
    errors: {},
    payload: {},
    temporaryLineItems: {},
    executionName: '',
    notificationEmail: '',
    loading: false,
    accounttype : "existing",
  }

  metafields = {}

  constructor() {
    super();

    this.onFormFieldChange = this.onFormFieldChange.bind(this);
    this.onExecutionNameChange = this.onExecutionNameChange.bind(this);
    this.handleSubmitFormClicked = this.handleSubmitFormClicked.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.updateTemporaryLineItem = this.updateTemporaryLineItem.bind(this);
    this.handleSKUs = this.handleSKUs.bind(this);
    this.onNotificationFieldChange = this.onNotificationFieldChange.bind(this)
  }

  componentDidMount() {
    this.getMetafields();
  }

  getMetafields() {
    const { payload } = this.state;

    this.props.scenarios.forEach(scenario => {
      const metafields = this.props.metafields[scenario.testype];
      const defaultRegion = 'AMER-3000';

      this.metafields[scenario.testcaseid] = metafields;

      // setting the payload to an empty object
      payload[scenario.testcaseid] = {};

      metafields.forEach(field => {
        // setting the payload field to an empty string
        if (field.field) {
          let defaultValue = field.default ? field.default : undefined;

          if (field.field === 'initialorderSKU') {
            this.getSKUs(scenario, field.skutype);
          }

          payload[scenario.testcaseid][field.field] = defaultValue;
        }
      });

      // if the payload was created
      if (payload[scenario.testcaseid]) {
        const salesorg = metafields.find(f => f.field === 'salesorg');

        // if the payload contains salesorg
        if (salesorg) {
          const environmentOptions = salesorg.options[this.props.environment];

          // if salesorg has environment specific options
          if (environmentOptions) {
            const regionOptions = environmentOptions.find(o => o[defaultRegion])[defaultRegion];

            // if environment options contains options for a specific region
            if (regionOptions) {
              // update the payload with whatever comes in salesorg
              payload[scenario.testcaseid] = Object.assign({}, payload[scenario.testcaseid], regionOptions);
            }
          }
        }
      }
    });


    this.setState({ latestsUpdate: +Date.now, payload, notificationEmail:this.props.userInfo.emailid || '' }, this.validateFields);
  }

  getSKUs(scenario, skutype) {
    const skuType = skutype || scenario.skutype[0].type;
    const region = this.state.payload[scenario.testcaseid].salesorg;

    getQuarrySKUs(skuType, 'custom', region, scenario.channel, this.props.environment).then(response => {
      const options = response.data.results;
      const initialorderIdx = this.metafields[scenario.testcaseid].findIndex(f => f.field === 'initialorderSKU');

      if (initialorderIdx >= 0) {
        this.metafields[scenario.testcaseid][initialorderIdx].options = options;
      }

      this.setState({ latestsUpdate: +Date.now() });
    });
  }

  onFormFieldChange(testcaseid) {
    return ({ target }) => {
      const { name, value, options, selectedIndex } = target;

      if (name === 'salesorg') {
        const selectedOption = options[selectedIndex];
        const key = selectedOption.textContent;
        const salesorg = this.metafields[testcaseid].find(f => f.field === 'salesorg');
        const defaultValues = salesorg.options[this.props.environment].find((option) => {
          if (option[key]) {
            return option[key].salesorg === value;
          }

          return false;
        })[key];
        const scenario = this.props.scenarios.find(s => s.testcaseid === testcaseid);

        this.getSKUs(scenario);

        if (defaultValues) {
          defaultValues.salesorg = value;

          this.setState(state => {
            const { payload } = state;

            payload[testcaseid] = Object.assign({}, payload[testcaseid], defaultValues);

            return { payload };
          });
        }
      }

      if (name === 'accounttype') {
        this.setState({ accounttype: value });
      }

      this.validateField(testcaseid, name, value);

      this.setState(state => {
        const id = testcaseid;
        const { payload } = state;

        if (payload[id]) {
          payload[id][name] = value === '' ? undefined : value;
        }

        return { payload };
      });
    }
  }

  onExecutionNameChange(e) {
    this.setState({
      executionName: e.target.value,
    });
  }

  onNotificationFieldChange(value) {
    this.setState({ 'notificationEmail': value });
  }

  handleSubmitFormClicked() {
    this.setState({ loading: true });

    this.submitTests();
  }

  handleSKUs(key) {
    return (lineitems) => {
      this.setState(state => {
        const { payload } = state;

        payload[key].lineitems = lineitems;

        return { payload };
      });
    };
  }

  updateTemporaryLineItem(key) {
    return (selectedOption, quantity, skuType, displayName) => {
      const lineItemKey = this.props.channelName === 'BIC' ? 'priceid' : 'partnumber';
      const { temporaryLineItems } = this.state;

      if (quantity && selectedOption && !selectedOption.sku) {
        selectedOption.sku = 'default';
      }

      if (selectedOption.sku) {
        if (!temporaryLineItems[key]) {
          temporaryLineItems[key] = [];
        }

        const existingLineItemIdx = temporaryLineItems[key].findIndex(l => l.skutype === skuType);

        if (existingLineItemIdx >= 0) {
          temporaryLineItems[key].splice(existingLineItemIdx, 1);
        }

        temporaryLineItems[key].push({
          [lineItemKey]: selectedOption.sku,
          quantity: +quantity,
          skutype: skuType,
          displayname: displayName,
          productname: selectedOption.name,
        });
      } else {
        if(temporaryLineItems[key]) {
          const existingLineItemIdx = temporaryLineItems[key].findIndex(l => l.skutype === skuType);

          if (existingLineItemIdx >= 0) {
            temporaryLineItems[key].splice(existingLineItemIdx, 1);
          }
        }
      }

      this.setState({ temporaryLineItems });
    };
  }

  submitTests() {
    const { props, state } = this;
    const data = props.scenarios;
    const payload = {
      env: props.environment,
      subcategoryname: props.channel,
      items: data.filter((item) => item.checked !== false),
      executionname: this.state.executionName,
      notificationemail: this.state.notificationEmail
    };

    payload.items.forEach(testcase => {
      const tempLineItems = state.temporaryLineItems[testcase.testcaseid] || [];
      const lineItems = state.payload[testcase.testcaseid].lineitems || [];
      const orderedLineItems = [];

      testcase.params = state.payload[testcase.testcaseid];
      testcase.params.lineitems = [...lineItems, ...tempLineItems];
      testcase.params.lineitems = testcase.params.lineitems.filter(l => l);

      // reorder lineitems
      if (!testcase.ismultiple) {
        testcase.params.lineitems.forEach((lineItem) => {
          const skuIndex = testcase.skutype.findIndex(sku => sku.type === lineItem.skutype);

          orderedLineItems[skuIndex] = lineItem;
        });

        testcase.params.lineitems = orderedLineItems;
      }

      // checking that every skutype has a lineitem
      testcase.skutype.forEach((skutype, skuIndex) => {
        const lineitem = testcase.params.lineitems.find(l => l && l.skutype === skutype.type);

        // if there are no lineitems with the current skutype
        if (!lineitem) {
          let { defaultvalues } = skutype;
          let newLineItem = {};

          if (!defaultvalues) {
            defaultvalues = {
              partnumber: 'default',
              quantity: 1,
            };
          }

          newLineItem = {
            partnumber: defaultvalues.partnumber,
            quantity: parseInt(defaultvalues.quantity),
            skutype: skutype.type,
            displayname: skutype.displayname
          };

          if (testcase.ismultiple) {
            testcase.params.lineitems.push(newLineItem);
          } else {
            testcase.params.lineitems[skuIndex] = newLineItem;
          }
        }
      });
    });

    if (payload.items.length > 0) {
      props.submitTests(payload).then(() => {
        this.setState({ loading: false });
        this.props.onClose();
      });
    }
  };

  validateField(testcaseid, field, value, silent) {
    const { props, state } = this;
    const errors = Object.assign({}, state.errors);
    const scenario = props.scenarios.find(s => s.testcaseid === testcaseid);
    const metafields = props.metafields[scenario.testype];
    const metadata = metafields.find(m => m.field === field);

    // initiate errors object
    if (!errors[testcaseid]) {
      errors[testcaseid] = {};
    }

    // if there are validation rules
    if (metadata) {
      let error = false;

      // clear previous errors
      if (errors[testcaseid] && typeof errors[testcaseid][field] === 'string') {
        delete errors[testcaseid][field];
      }

      // if value is valid
      if (value && metadata.format) {
        const regex = RegExp(metadata.format);

        if (!regex.test(value)) {
          error = 'format is not valid';
        }
      }

      if (!value && metadata.mandatory && field !== 'lineitems') {
        error = 'field cannot be empty';
      }

      // special rules
      // remove errors from prepopulated fields
      if (field === 'salesorg') {
        metadata.options[this.props.environment].forEach(fieldGroup => {
          const key = Object.keys(fieldGroup)[0];

          if (key.includes(value)) {
            const fieldsToValidate = Object.keys(fieldGroup[key]);

            fieldsToValidate.forEach(singleField => {
              delete errors[testcaseid][singleField];
            });
          }
        });
      }

      
      // special rules
      // validate matching fields
      if (field === 'enduserFtrade' || field === 'contact') {
        const matchingField = field === 'enduserFtrade' ? 'contact' : 'enduserFtrade';
        const matchingFieldValue = this.state.payload[testcaseid][matchingField];

        if (!matchingFieldValue) {
          errors[testcaseid][matchingField] = 'cannot be empty';
        }

        if (matchingFieldValue && !value) {
          errors[testcaseid][field] = 'cannot be empty';
        }

        // if both fields are empty it is valid
        if (!value && !matchingFieldValue) {
          delete errors[testcaseid][field];
          delete errors[testcaseid][matchingField];
        }
      }

      // if an error has been found
      if (error) {
        errors[testcaseid][field] = error;
      }
    }

    if ((errors[testcaseid][field] || errors[testcaseid][field] === '') && !silent) {
      this.setState({ errors });
    }

    return errors[testcaseid] && typeof errors[testcaseid][field] === 'string';
  }

  validateFields() {
    const errors = Object.assign({}, this.state.errors);

    this.props.scenarios.map(s => s.testcaseid).forEach((id) => {
      if (!errors[id]) {
        errors[id] = {};
      }

      Object.entries(this.state.payload[id]).forEach(([field, value]) => {
        const hasError = this.validateField(id, field, value, true);

        if (hasError) {
          errors[id][field] = '';
        }
      });
    });

    this.setState({ errors });
  }

  validateForm() {
    let isFormValid = true;

    this.props.scenarios.forEach(scenario => {
      const { errors } = this.state;
      const metadata = this.props.metafields[scenario.testype];

      if (metadata) {
        if (errors[scenario.testcaseid]) {
          metadata.forEach(field => {
            if (typeof errors[scenario.testcaseid][field.field] === 'string') {
              isFormValid = false;
            }
          });
        }
      }
    });

    return isFormValid;
  }

  canSubmitForm() {
    return this.validateForm() && !this.state.loading;
  }

  renderInput(field, key,metaprops) {
    const { props, state } = this;
    const types = {
      contact: 'email',
      password: 'password',
      default: 'text',
    };
    const customPlaceholders = {
      contact: field.default ? `e.g: ${field.default}` : '',
      shiptocsn: field.default ? `e.g: xxxxxx${field.default.slice(-4)}` : '',
      soldtocsn: field.default ? `e.g: xxxxxx${field.default.slice(-4)}` : '',
    };
    const value = state.payload[key] ? state.payload[key][field.field] : '';
    let input = null;

    if (field.field === 'lineitems') {
      field.type = 'lineitems';
    }

    switch (field.type) {
      case 'string':
      case 'number':
      case 'date':

        const metadata = metaprops.find(m => m.field === field.field);
        if (metadata.disable) {
          let obj =  metadata.disable.field; 
              if (Object.keys(obj)[0] === 'accounttype'){
                input = <input data-lpignore='true'
                name={field.field}
                type={types[field.field] ? types[field.field] : types.default}
                placeholder={customPlaceholders[field.field] || field.default}
                value={value}
                onChange={this.onFormFieldChange(key)}
                className='form-control'
                disabled = {this.state.accounttype === Object.values(obj)[0] ? true : false}          
                />;
              } 
          }
          else
          {
            input = <input data-lpignore='true'
              name={field.field}
              type={types[field.field] ? types[field.field] : types.default}
              placeholder={customPlaceholders[field.field] || field.default}
              value={value}
              onChange={this.onFormFieldChange(key)}
              className='form-control'
            />;
          }
          break;

      case 'select':
        const selectProps = {
          defaultValue: this.state.payload[key] ? this.state.payload[key][field.field] : '',
          name: field.field,
          onChange: this.onFormFieldChange(key),
          className: 'form-control'
        };
        let options = [];

        if (field.options) {
          let fieldOptions = field.options;

          if (field.field === 'salesorg') {
            fieldOptions = field.options[this.props.environment];
          }

          if (field.field === 'usertype') {
            const scenario = props.scenarios.find(s => s.testcaseid === key);

            if (scenario) {
              if (scenario.usertype === 'all') {
                fieldOptions = ['new', 'existing'];
              } else {
                fieldOptions = [scenario.usertype];
              }
            }
          }

         
          if (field.field === 'initialorderSKU') {
            options = fieldOptions;
          } else {
            options = fieldOptions.map((option) => {
              if (typeof option === 'string') {
                return <option key={option} value={option}>{option}</option>;
              } else if (option && typeof option === 'object') {
                if (option.subcategoryname) {
                  return <option key={option.sku} value={option.sku}>{ option.name }</option>;
                } else {
                  const region = Object.keys(option)[0];
                  const optionProps = {
                    key: `${key}-${field.field}-${region}`,
                    value: option[region].salesorg,
                    selected: region === 'AMER-3000',
                  };

                  return <option {...optionProps}>{region}</option>;
                }
              }

              return null;
            });
          }
        }

        if (field.field === 'initialorderSKU') {
          const onChange = this.onFormFieldChange(key);
          const dropdownProps = {
            handleInputValue: (value) => {
              onChange({
                target: {
                  name: 'initialorderSKU',
                  value: value || 'default',
                }
              });
            },
            clearValue: false,
            placeholder: 'default',
            options,
          };

          input = <SearchableDropdown {...dropdownProps} />;
        } else {
          input = (
            <select {...selectProps}>
              { options }
            </select>
          );
        }

        break;
      case 'lineitems':
        const payload = state.payload[key];

        if (payload && (payload.salesorg || payload.store)) {
          const scenario = this.props.scenarios.find(s => s.testcaseid === key);
          const lineitems = this.metafields[key].find(f => f.field === 'lineitems');
          const customSKUInputProps = {
            channel: props.channel,
            onLineItemsUpdated: this.handleSKUs(key),
            isMultiple: scenario.ismultiple,
            skutype: scenario.skutype,
            region: payload.salesorg || payload.store,
            environment: props.environment,
            updateTemporaryLineItem: this.updateTemporaryLineItem(key),
            fulfillment: 'custom',
            autoload: false,
            placeholder: 'default',
            upwards: true,
            lineitems: lineitems.lineitems,
          };

          return (
            <div className='col-sm-12' key={2}>
              <CustomSKUInput {...customSKUInputProps} />
            </div>
          );
        }

        return null;
      default:
        break;
    }

    if (input) {
      const tooltip = field.description ? <Tooltip text={field.description} /> : null;
      const optional = field.mandatory === 'false' ? <span className='optional-field'>Optional</span> : <span className='mandatory-field'>*</span>;
      const { errors } = this.state;
      const hasError = errors[key] && errors[key][field.field] ? ' has-error' : '';
      let errorMessage = '';

      if (hasError) {
        errorMessage = errors[key][field.field];
      }

      return (
        <div className='col-sm-6' key={field.field}>
          <div className={`form-group${hasError}`}>
            <label htmlFor={field.field}>
              { field.name }{ optional }
              { tooltip }
            </label>

            { input }

            {hasError && (
              <small className='has-error-reason'>{field.name} {errorMessage}</small>
            )}
          </div>
        </div>
      );
    }

    return null;
  }

  renderFields(metafields) {
    const fields = [];
    const numberOfFields = Object.entries(metafields).length;

    Object.entries(metafields).forEach((metafield) => {
      const key = metafield[0];
      const metaprops =  metafield[1]
      const scenario = this.props.scenarios.find(s => s.testcaseid === key);
      const inputs = metafield[1].map(input => this.renderInput(input, key, metaprops));

      if (numberOfFields === 1) {
        fields.push(<div className='row'>{ inputs }</div>);
      } else {
        fields.push(
          <CollapsibleContent key={key} title={scenario.name}>
            <div className='row'>{ inputs }</div>
          </CollapsibleContent>
        );
      }
    });

    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-sm-6 form-group' key='executionname'>
            <label htmlFor='executionname'>Execution name <span className='optional'>Optional</span></label>
            <input className='form-control' name='executionname' type='text' onChange={this.onExecutionNameChange} />
          </div>
          <div className='col-sm-6 form-group' key='notificationemail'>

            <NotificationField value={this.state.notificationEmail}
                               notifyAllValue={this.state.notificationEmail}
                               name='notificationemail'
                               onChange={this.onNotificationFieldChange}
                               loggedInUserEmail = {this.props.userInfo.emailid}
            />
          </div>
        </div>

        { fields }
      </React.Fragment>
    );
  }

  render() {
    const modalProps = {
      onClose: this.props.onClose,
      onSubmit: this.handleSubmitFormClicked,
      submitButtonText: 'Submit',
      title: 'Customize',
      submitEnabled: this.canSubmitForm(),
      loading: this.state.loading,
    };
    const numberOfMetafields = Object.entries(this.metafields).length;
    const fields = numberOfMetafields > 0 ? this.renderFields(this.metafields) : <LoadingSpinner text='Loading fields' />;

    return (
      <Modal {...modalProps}>
        <div className='modal-form modal-content-wrapper'>
          <FlashMessage />
          { fields }
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
});

export default connect(null, mapDispatchToProps)(CJTModal);
