import { Types } from 'store/actions/test_suites_actions';

const initialState = {
  suites: [],
  roles: [],
};

const updateTestSuiteSchedule = (state, action) => {
  const suites = state.suites.map((service) => {
    const suiteIndex = service.testsuites.findIndex(
      (suite) => suite.testsuiteid === action.testSuiteId
    );
    if (suiteIndex === -1) {
      return service;
    }
    return {
      ...service,
      testsuites: [
        ...service.testsuites.slice(0, suiteIndex),
        {
          ...service.testsuites[suiteIndex],
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
          isdisabled: action.payload.isdisabled,
          scheduledtime: action.payload.cron_expression
            ? `cron(${action.payload.cron_expression})`
            : undefined,
        },
        ...service.testsuites.slice(suiteIndex + 1),
      ],
    };
  });
  return {
    ...state,
    suites,
  };
};

const removeTestSuiteFromList = (state, action) => {
  return {
    ...state,
    suites: state.suites.map((service) => {
      return {
        ...service,
        testsuites: service.testsuites.filter(
          (suite) => suite.testsuiteid !== action.testSuiteId
        ),
      };
    }),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_TEST_SUITES:
      return {
        suites: action.suites,
        roles: action.suites[0].roles,
      };
    case Types.TEST_SUITE_SCHEDULE_UPDATED:
      return updateTestSuiteSchedule(state, action);
    case Types.TEST_SUITE_REMOVED:
      return removeTestSuiteFromList(state, action);
    default:
      return state;
  }
};
