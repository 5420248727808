import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getPlaywrightReport } from '../../utils/api.service';
import LoadingSpinner from '../LoadingSpinner';

function PlaywrightReport(props) {
  let [url, setUrl] = useState();
  let [message, setMessage] = useState();
  let [showLoader, setShowLoader] = useState(true);

  async function renderCookies() {
    const pathname = props.location.pathname;
    console.log('pathname', pathname);
    const pathParams = pathname.split('/').filter((param) => param !== '');
    let transactionid = '';
    let executionid = '';
    if (pathParams.length > 3) {
      transactionid = pathParams[pathParams.length - 1];
      executionid = pathParams[pathParams.length - 2];
    } else {
      executionid = pathParams[pathParams.length - 1];
    }
    console.log('executionid', executionid);
    console.log('transactionid', transactionid);
    const response = await getPlaywrightReport(executionid, transactionid);
    console.log(response.data.reportUrl);
    if (response.data.reportUrl) {
      const url = new URL(response.data.reportUrl);
      url.searchParams.set('cache_buster', Date.now());
      setUrl(url.toString());
    }
    setMessage(response.data.message);
    setShowLoader(false);
  }
  useEffect(() => {
    renderCookies();
  }, []);

  let finalContent = showLoader ? (
    <div className="screenshot-body">
      <LoadingSpinner text="Loading reports" />
    </div>
  ) : (
    <div>
      {url ? (
        <iframe
          src={url}
          width="100%"
          height="500px"
          title="Playwright Report"
          style={{ border: 'none', width: '100vw', height: '100vh' }}
        ></iframe>
      ) : (
        <div className="screenshot-body">
          <h2 className="title">404: Report Not Found</h2>
          <span>{message}</span>
        </div>
      )}
    </div>
  );
  return <div>{finalContent}</div>;
}

PlaywrightReport.propTypes = {
  url: PropTypes.string.isRequired,
};

PlaywrightReport.defaultProps = {
  screenshots: [],
};

export default PlaywrightReport;
