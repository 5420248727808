import React from 'react';
import { connect } from 'react-redux';

import { submitMeshTestCases } from 'store/actions/mesh_actions';
import { setDefaultFilter } from 'store/actions/jobsQueue_actions';

const withSubcategory = (subcategory) => {
  return (Component) => {
    const Wrapper = (props) => <Component {...props} context={subcategory} />;
    const mapStateToProps = (state) => ({
      id: state.app.menu.cjt,
      lastUpdate: state.app.environment.lastUpdate, // hack for react to rerender component
      activeEnvironment: state.app.environment[subcategory],
      userInfo: state.app.userInfo,
    });
    const mapDispatchToProps = (dispatch) => ({
      submitTests: tests => dispatch(submitMeshTestCases(tests, subcategory)),
      setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
    });
    
    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
  };
};

export default withSubcategory;
