const types = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const fromContent = (filename, content) => {
  const anchor = document.createElement('a');

  anchor.setAttribute('href', content);
  anchor.setAttribute('download', filename);

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
};

const fromURL = (url) => {
  const splittedUrl = url.split('/');
  const filename = splittedUrl[splittedUrl.length - 1];
  const splittedFileName = filename.split('.');
  const extension = splittedFileName[splittedFileName.length - 1];

  fetch(url).then((response) => {
    return response.clone().arrayBuffer();
  }).then((content) => {
    const byteArray = new Uint8Array(content);
    const blob = new Blob([byteArray], {
      type: types[extension],
    });
    const data = URL.createObjectURL(blob);

    fromContent(filename, data);
  });
};

export default {
  fromContent,
  fromURL,
};
