import React, {Component} from 'react';

class QuarryContent extends Component {
  render() {
    return (
      <div className='content'>
        <section>
          <h2>Test Data</h2>

          <p>
            The test data is available via a REST API and can be easily integrated with an engineering team's automated functional tests to create data on the fly. We believe in the Shift Left Testing paradigm and Testing Hub - Test Data enables teams to create and manage their own test data by integrating earlier within their automated pipelines and reducing dependencies on outside teams.
          </p>
        </section>

        <section className='faq'>
          <h2>F.A.Q</h2>

          <ul>
            <li>
              <h4>What is Testing Hub?</h4>

              <p>
                Testing Hub is a powerful self-service platform which provides software testing services on demand. It helps engineering and business teams create and manage their test data, execute end to end business functional tests and implement virtualized services via their browser as well a REST API.
              </p>
            </li>

            <li>
              <h4>What is the Test Data service?</h4>

              <p>
                A service in Testing Hub which allows anyone at Autodesk to create test data on-demand. Some of examples of business test data would be Accounts, Contacts, Orders and Opportunities.
              </p>
            </li>

            <li>
              <h4>What can I do with Test Data?</h4>

              <p>
                Anyone within Autodesk needing business test data for functional or user acceptance testing can do so via browser or REST API.
              </p>
            </li>

            <li>
              <h4>How do I get started with Test Data?</h4>

              <p>
                You can access the "Test Data" section under Testing Hub to create the available test data. Please refer to our "API Documentation" to execute the same tests via REST API.  You can also reach out to us on Slack in the #des-testinghub-help channel.
              </p>
            </li>

            <li>
              <h4>What benefits does Test Data provide?</h4>

              <p>
                It helps engineering and business teams create and manage their test data for any testing needs.
              </p>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

export default QuarryContent;
