import React from 'react';
import { connect } from 'react-redux';

const FlashMessage = (props) => {
  const notifications = props.notifications.filter((notification) => {
    const currentTime = +new Date();

    // if notification was created less than 2 seconds ago
    if (currentTime - notification.createdAt <= 2000) {
      return notification.type === 'danger';
    }

    return false;
  });


  return notifications.map((notification) => (
    <div class='alert alert-danger' role='alert'>
      { notification.message }
    </div>
  ));
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  lastNotificationAt: state.notifications.lastNotificationAt,
});

export default connect(mapStateToProps)(FlashMessage);
