import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

class SearchableDropdown extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleInputValue: PropTypes.func.isRequired,
    clearValue: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    upwards: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    clearValue: false,
    placeholder: '',
    upwards: false,
    disabled: false,
  }

  state = {
    value: '',
    valueText: '',
    custom: false,
    visibleOptions: false,
  }

  constructor() {
    super();

    this.selectOption = this.selectOption.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
  }

  componentDidUpdate() {
    if (this.props.clearValue) {
      this.setState({ valueText: '', value: '' });

      this.props.updateClearValue(false);
    }
  }

  selectOption(e) {
    const { value } = e.target.dataset;

    this.setState({ value, valueText: e.target.textContent, custom: false }, () => {
      this.props.handleInputValue(this.state.value);
    });
  }

  handleInputChange(e) {
    const { value } = e.target;

    this.setState({ value, valueText: value, custom: true }, () => {
      this.props.handleInputValue(this.state.valueText);
    });
  }

  handleInputFocus() {
    this.setState({ visibleOptions: true });
  }

  handleInputBlur() {
    this.setState({ visibleOptions: false });
  }

  renderOptions() {
    const filteredOptions = this.props.options.filter((option) => {
      const name = option.name.toLowerCase();
      const value = this.state.value.toLowerCase();

      return name.includes(value);
    });

    return filteredOptions.map((option) => {
      return <li key={option.sku} data-value={option.sku} onMouseDown={this.selectOption}>{ option.name }</li>;
    });
  }

  render() {
    const options = this.renderOptions();
    const inputProps = {
      className: 'form-control',
      onChange: this.handleInputChange,
      onFocus: this.handleInputFocus,
      onBlur: this.handleInputBlur,
      value: this.state.valueText,
      placeholder: this.props.placeholder,
      disabled: this.props.disabled,
    };
    const ulProps = {
      className: classname({
        'searchable-dropdown-options': true,
        'upwards': this.props.upwards,
      }),
    };

    return (
      <div className='searchable-dropdown'>
        <input {...inputProps} />

        {this.state.visibleOptions && options.length > 0 && (
          <ul {...ulProps}>
            { options }
          </ul>
        )}
      </div>
    );
  }
}

export default SearchableDropdown;
