import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import reducers from './reducers';
import Session from 'utils/session.service';

export const saveState = (state) => {
  try {
    let stateToBeSaved = JSON.stringify(state);
    stateToBeSaved = JSON.parse(stateToBeSaved);

    // do not persist notifications
    stateToBeSaved.notifications.notifications = [];

    localStorage.setItem('th-state', JSON.stringify(stateToBeSaved));
  } catch {
    // ignore write errors
  }
};

export const loadState = async () => {
  try {
    const serializedState = JSON.parse(localStorage.getItem('th-state'));
    const previousSession = serializedState;

    // if first login
    if (serializedState === null) {
      return {};
    }

    if (previousSession.user.isLoggedIn) {
      return {
        ...serializedState,
        user: {
          ...serializedState.user,
          auth: previousSession.user.auth,
        },
      };
    }

    return serializedState;
  } catch (error) {
    console.error('Error loading state', error);
    return {};
  }
};
let store = {};

export default () => {
  return new Promise(async (resolve) => {
    const initialState = await loadState();

    const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));
    // generate store from previous state
    store = createStore(reducers, initialState, composedEnhancer);
    // initializing token renewal logic
    if (initialState.user && initialState.user.auth) {
      const session = new Session(store.dispatch);

      session.renew();
      session.setRenewInterval();
      session.setLogoutTimeout();
    }

    store.subscribe(() => {
      saveState({ ...store.getState() });
    });

    resolve(store);
  });
};

export const getState = () => store.getState();
export const dispatch = (action) => store.dispatch(action);
