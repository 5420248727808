const schema = [
  {
    baseUrl: '/cjt',
    displayName: 'Customer journey testing',
    servicename: 'cjt',
    accessor: 'thservices',
    children: 'categories',
  },
  {
    baseUrl: '/testdata',
    displayName: 'Test data',
    servicename: 'testdata',
    accessor: 'thservices',
    children: 'categories',
  },
  {
    baseUrl: '/hosting',
    displayName: 'Test hosting',
    servicename: 'hosting',
    accessor: 'projects',
    children: 'workstream',
  },
  {
    baseUrl: '/testsuites',
    displayName: 'Test suites',
    servicename: 'testsuites',
  }
];

export default schema;
