import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import CollapsibleContent from 'components/CollapsibleContent';
import Checkbox from 'components/Checkbox';
import JSONFields from 'components/JSONFields';

import downloadFile from 'utils/downloadFile';

const JobsQueueResubmitModal = (props) => {
  // state
  const [isNewReport, setIsNewReport] = useState(false);
  const [newFormValue, setNewFormValue] = useState({});
  // user actions
  const onResubmitButtonClicked = () => {
    props.onSubmit(props.record, props.executionid, isNewReport, newFormValue);
  };
  const onFieldChanged = (transactionid) => {
    return (e) => {
      const { name, value } = e.target;

      setNewFormValue((state) => {
        const updatedState = state;

        if (!updatedState[transactionid]) {
          updatedState[transactionid] = {};
        }

        updatedState[transactionid][name] = value;

        return updatedState;
      });
    };
  };
  const onNewReportChanged = (e) => {
    setIsNewReport(e.target.checked);
  };
  const onAnchorClicked = (e) => {
    e.preventDefault();

    downloadFile.fromURL(e.target.href);
  };
  // methods
  const formatResponse = (response) => {
    let userInput = [];

    if (response && typeof response !== 'string') {
      let input = response;

      if (!Array.isArray(response) && typeof response === 'object') {
        input = [];

        Object.entries(response).forEach(([key, value]) => {
          input.push({ name: key, value: JSON.stringify(value) });
        });
      }

      userInput = input.map((entry) => ({
        key: entry.fieldname || entry.name,
        value: entry.value,
      }));
    }

    return userInput;
  };
  // render methods
  const renderPlain = (transaction, row) => {
    let rowContent = row.value;

    try {
      rowContent = JSON.parse(row.value);
    } catch {
      rowContent = row.value;
    }

    if (rowContent && typeof rowContent === 'object') {
      rowContent = renderObjectAsList(rowContent);
    }

    // if customer journey testing or test data
    if (['cjt'].includes(props.service)) {
      return (
        <tr key={row.key}>
          <th scope="row">{row.key}</th>
          <td>{rowContent}</td>
        </tr>
      );
    } else {
      let fieldValue = row.value;

      if (
        newFormValue[transaction.transactionid] &&
        newFormValue[transaction.transactionid][row.key]
      ) {
        fieldValue = newFormValue[transaction.transactionid][row.key];
      }

      let field = (
        <input
          className="form-control"
          disabled={['env', 'parameters'].includes(row.key)}
          name={row.key}
          onChange={onFieldChanged(transaction.transactionid)}
          value={fieldValue}
        />
      );

      // if field is testdata
      if (row.key === 'testdata') {
        field = (
          <JSONFields
            name={row.key}
            json={fieldValue}
            onChange={onFieldChanged(transaction.transactionid)}
          />
        );
      }

      return (
        <tr key={row.key}>
          <th scope="row">{row.key}</th>
          <td>{field}</td>
        </tr>
      );
    }
  };
  const renderObjectAsList = (object) => {
    const listElements = Object.entries(object).map(([key, value]) => {
      let renderValue = value;

      if (typeof renderValue === 'object') {
        return renderObjectAsList(renderValue);
      }

      if (value.includes('https://')) {
        renderValue = (
          <a href={value} key={key} onClick={onAnchorClicked} download>
            {value}
          </a>
        );
      }

      return (
        <li>
          <strong>{key}</strong>: {renderValue}
        </li>
      );
    });

    return <ul className="jobs-queue-list">{listElements}</ul>;
  };
  const renderTables = (transactions) => {
    const fields = [];
    const numberOftransactions = Object.entries(transactions).length;

    transactions.forEach((transaction) => {
      const formattedUserInput = formatResponse(transaction.userinput);
      let rows;

      if (formattedUserInput.length > 0) {
        rows = formattedUserInput.map((userinput) =>
          renderPlain(transaction, userinput)
        );
      } else {
        rows = (
          <React.Fragment>
            <tr key={transaction.transactionid}>
              <th scope="row">Channel</th>
              <td> {transaction.channelname}</td>
            </tr>
            <tr key={transaction.transactionid}>
              <th scope="row">Test Environment</th>
              <td> {transaction.env}</td>
            </tr>
          </React.Fragment>
        );
      }

      if (rows) {
        const transactionName = transaction.transactionname || transaction.name;

        if (numberOftransactions === 1) {
          fields.push(
            <section className="jobs-queue-table">
              <div className="collapsable-content-title">{transactionName}</div>
              <h5>User input</h5>
              <table className="table table-bordered table-hover">
                <tbody>{rows}</tbody>
              </table>
            </section>
          );
        } else {
          fields.push(
            <CollapsibleContent
              key={transaction.transactionid}
              title={transactionName}
            >
              <section className="jobs-queue-table">
                <h5>User input</h5>
                <table className="table table-bordered table-hover">
                  <tbody>{rows}</tbody>
                </table>
              </section>
            </CollapsibleContent>
          );
        }
      }
    });

    return <div className="modal-content-wrapper">{fields}</div>;
  };
  // render
  const tables = renderTables(props.record);
  const modalProps = {
    onClose: props.onClose,
    onSubmit: onResubmitButtonClicked,
    submitButtonText: 'Resubmit',
    title: 'Resubmit',
  };

  return (
    <Modal {...modalProps}>
      <div className="form-group" key="executionname">
        <Checkbox checked={isNewReport} onChange={onNewReportChanged} />
        <label htmlFor="executionname" style={{ marginLeft: 25 }}>
          Create new report
        </label>
      </div>

      {tables}
    </Modal>
  );
};

JobsQueueResubmitModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  record: PropTypes.object,
  executionid: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default JobsQueueResubmitModal;
