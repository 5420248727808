import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

import Checkbox from 'components/Checkbox';

function TestCase(props) {
  // methods
  const onItemSelected = testcaseid => {
    props.onItemSelected(testcaseid);
  };
  // user actions
  const onChangeFn = props.disabled ? () => {} : () => onItemSelected(props.testcaseid);
  // render methods
  const inputProps = {
    checked: props.checked && !props.disabled,
  };
  const radioProps = {
    className: classname({
      'radio-button': true,
      'checked': props.checked && !props.disabled,
    }),
  };
  const labelProps = {
    type: 'button',
    className: 'button button-label',
    onClick: props.customLabelOnClick,
  };
  let input;
  let secondaryActions = [];

  if (props.radio) {
    input = <button {...radioProps} onClick={onChangeFn}></button>;
  } else {
    input = <Checkbox {...inputProps} onChange={onChangeFn} />;
  }

  if (props.secondaryActions.length) {
    secondaryActions = props.secondaryActions.map(action => {
      return <button key={action.name} className='dropdown-item' onClick={action.onClick}>{ action.text }</button>;
    });
  }

  return (
    <div className={classname({ 'test-case': true, 'disabled': props.disabled })}>
      { input }

      <div className='test-case-body'>
        <label className='test-case-body-label' onClick={props.onLabelClick}>{props.name}</label>

        { secondaryActions.length > 0 && (
          <div className='test-case-actions'>
            <button type='button' id='test-case-secondary-actions' data-toggle='dropdown'>
              <i className='bi bi-three-dots-vertical'></i>
            </button>

            <div className='dropdown-menu' aria-labelledby='test-case-secondary-actions'>
              { secondaryActions }
            </div>
          </div>
        )}

        {props.customLabel && (
          <button {...labelProps}>{ props.customLabel }</button>
        )}

        {props.description && (
          <div className='test-case-body-description'>
            <p>{ props.description }</p>

            {props.learnMoreUrl && (
              <a href={props.learnMoreUrl} target='_blank' rel='noopener noreferrer'>Learn more</a>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

TestCase.propTypes = {
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  radio: PropTypes.bool,
  customLabel: PropTypes.string,
  customLabelOnClick: PropTypes.func,
  secondaryActions: PropTypes.array,
  learnMoreUrl: PropTypes.string,
  onLabelClick: PropTypes.func,
};

TestCase.defaultProps = {
  radio: false,
  description: null,
  customLabel: null,
  customLabelOnClick: null,
  secondaryActions: [],
  learnMoreUrl: null,
  onLabelClick: () => {},
};

export default TestCase;
