import { connect } from 'react-redux';

import Products from './Products';
import Orders from './Orders';
import withChannelsAndTestCases from 'hocs/withChannelsAndTestCases';

const components = {
  Products,
  Orders,
  Simulation: Orders,
};
const applyQuarryHOC = (component, category, accessor) => {
  const mapStateToProps = state => ({
    category:category,
    activeEnvironment: state.app.environment.Orders,
    userInfo: state.app.userInfo,
  });

  return connect(mapStateToProps)(withChannelsAndTestCases({ component, category, accessor })(components[component]));
};

const products = applyQuarryHOC('Products', 'products', 'results');
const orders = applyQuarryHOC('Orders', 'orders', 'Orders');
const simulation = applyQuarryHOC('Simulation', 'orders', 'Orders');

export { products as Products, orders as Orders, simulation as Simulation };
