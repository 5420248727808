import React, {Component} from 'react';

class MeshContent extends Component {
  render() {
    return (
      <div className='content'>
        <section>
          <h2>Customer Journey Testing</h2>

          <p>
            Testing Hub's Customer Journey Testing helps engineering teams confidently deliver on-demand releases by running a suite of pre-determined tests within a CI/CD pipeline. Testing Hub also provides confidence to Business teams during their UAT period which will result in reduced cycle times.
          </p>
        </section>

        <section className='faq'>
          <h2>F.A.Q</h2>

          <ul>
            <li>
              <h4>What is Testing Hub?</h4>

              <p>
                Testing Hub is a powerful self-service platform which provides software testing services on demand. It helps engineering and business teams create and manage their test data, execute end to end business functional tests and implement virtualized services via their browser as well a REST API.
              </p>
            </li>

            <li>
              <h4>What is Testing Hub's Customer Journey Testing (CJT)?</h4>

              <p>
                Customer Journey Testing (CJT) validates DPE application's most critical business transactions flows from point A to point B. The purpose of this testing is to depict the customer journey and validate the most critical interactions and avoid negative customer impact.
              </p>
            </li>

            <li>
              <h4>What can I do with Customer Journey Testing (CJT)?</h4>

              <p>
                Any engineering or business team within DPE can execute these tests via a browser or REST API for either User Acceptance or Functional Testing. These tests can be easily integrated within an engineering team's CI/CD pipeline for quicker feedback of build quality.
              </p>
            </li>

            <li>
              <h4>How do I get started with Customer Journey Testing (CJT)?</h4>

              <p>
                Anyone within Autodesk can access the "Customer Journey Testing" section in Testing Hub to launch the available tests. You can also reach out to us on the #des-testinghub-help Slack channel.
              </p>
            </li>

            <li>
              <h4>What benefits does Customer Journey Testing (CJT) provide?</h4>

              <p>
                It helps engineering teams confidently deliver on-demand releases by running a suite of business functional tests within their existing CI/CD pipelines. It also enables business teams to reduce their UAT cycles by quickly iterating over the suite of business functional tests.
              </p>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

export default MeshContent;
