import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import downloadFile from 'utils/downloadFile';

function ListItem(props) {
  // state
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(props.value);
  // refs
  const editValueInput = useRef(null);
  // lifecycle
  useEffect(() => {
    if (editing) {
      editValueInput.current.focus();
    }
  });
  //  user actions
  const onBlur = () => {
    if (value !== props.value) {
      props.onChange(value);
    }

    setEditing(false);
  };
  const onChange = () => {
    setValue(editValueInput.current.value);
  }
  const onClick = () => {
    // we don't have editable option at ListItem level, so added condition for 'testdatafile' (key which is create by code)to be not editable in any modal
    if (props.editable && props.itemKey !== 'testdatafile') {
      setEditing(true);
    }
  };
  const onAnchorClicked = (e) => {
    e.preventDefault();

    downloadFile.fromURL(e.target.href);
  };
  // render methods
  let visibleValue = props.value;
  let content;

  if (props.value.includes('https://')) {
    const splittedValue = props.value.split('/');

    visibleValue = <a href={value} onClick={onAnchorClicked} download>{ splittedValue[splittedValue.length - 1] }</a>;
  } else {
    visibleValue = `"${props.value}"`;
  }

  if (props.singleValue) {
    content = <strong>{ visibleValue }</strong>;
  } else {
    if (editing) {
      content = (
        <strong onClick={onClick}>
          {props.itemKey}: <input type='text' value={value} onBlur={onBlur} onChange={onChange} ref={editValueInput} />
        </strong>
      );
    } else {
      content = <strong onClick={onClick}>{props.itemKey}: { visibleValue }</strong>;
    }
  }

  return (
    <span className='list-input-element'>
      { content }

      <button onClick={props.onRemove} disabled={props.readOnly || props.isSubmitWindow} className='btn'>
        <i className='bi bi-x'></i>
      </button>
    </span>
  );
}

ListItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  singleValue: PropTypes.bool,
  value: PropTypes.string,
  isSubmitWindow: PropTypes.bool
};

ListItem.defaultProps = {
  singleValue: false,
  value: '',
};

export default ListItem;
