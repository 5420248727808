import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {getScreenshot} from "../../utils/api.service";
import LoadingSpinner from "../LoadingSpinner";

function Screenshot(props) {

  let [content, setContent] = useState();

  async function renderScreenshot() {
    let screenshots = new URLSearchParams(props.location.search).get("id").split("__")
    let request = screenshots.map(name => getScreenshot(name))
    let temp = await Promise.all(request).then((responses) => {
      let content = responses.reduce((accumulator, content, i) => {
        let imageContent
        if (content.data && content.data.indexOf('NoSuchKey') === -1) {
          imageContent = <img className="screenshot-image" alt='' src={"data:image/png;base64, " + content.data}/>
        } else {
          imageContent = <span> No screenshot found with name <b>{screenshots[i]} </b></span>
        }
        return [...accumulator, <div className="screenshot">
          <div>Screenshot: {screenshots[i]}</div>
          {imageContent}</div>]
      }, []);
      return content;
    })

    setContent(temp);

  }
  useEffect(() => {
   renderScreenshot();
  },[])

  let finalContent =  content ? <div>{content}</div> : <LoadingSpinner text='Loading screenshots' />;
  return (
    <div className="screenshot-body">
      <section className='section-title'>
        <div className='section-title-group'>
          <h2 className='title'>Screenshots</h2>
        </div>

      </section>
      {finalContent}
    </div>
  );
}

Screenshot.propTypes = {
  screenshots: PropTypes.array.isRequired,
};

Screenshot.defaultProps = {
  screenshots: []
};

export default Screenshot;
