import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import List from 'components/List';

import { shareTestSuite } from 'store/actions/test_suites_actions';

function TestSuitePublishModal(props){
  // state
  const [emails, setEmails] = useState({});
  const [loadingRequest, setLoadingRequest] = useState(false);
  // user actions
  const onChange = (e) => {
    setEmails(e.target.value)
  };
  const onSubmit = () => {

    const users = Object.keys(emails)
      .filter(email=>email && email.trim() && email.toLowerCase() !== props.userInfo.emailid.toLowerCase())
      .map((email) => ({email: email.toLowerCase(), 'role': emails[email]}));

    setLoadingRequest(true);

    props.shareTestSuite(props.testsuiteid, users).then(() => {
      setLoadingRequest(false);

      props.onClose();
    });
  };
  // render
  const modalProps = {
    onClose: props.onClose,
    onSubmit: onSubmit,
    submitButtonText: 'Save',
    title: 'Share test suite',
    loading: loadingRequest,
  };
  const roles = props.roles.map((role) => role.rolename);
  const initialValue = {};

  if (props.userlist.users) {
    props.userlist.users.forEach((user) => {
      initialValue[user.user] = user.role;
    });
  }

  useEffect(()=>{
    props.userlist && props.userlist.users && props.userlist.users.forEach((user) => {
      emails[user.user] = user.role
      setEmails(emails)
    });
  }, [])

  return (
    <Modal {...modalProps}>
      <div className='form-group'>
        <div><label> <small className='form-text text-muted'>Note: DL's are not support, please add individual emails.</small></label></div>
        <label htmlFor='emails'>Emails:</label>
        <List name='emails' onChange={onChange} options={roles} initialValue={initialValue} keyLabel='e-mail' />
      </div>
    </Modal>
  );
}

TestSuitePublishModal.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  userlist: PropTypes.object,
};

TestSuitePublishModal.defaultProps = {
  userlist: {},
};

const mapDispatchToProps = (dispatch) => ({
  shareTestSuite: (id, emails) => dispatch(shareTestSuite(id, emails)),
});

export default connect(null, mapDispatchToProps)(TestSuitePublishModal);
