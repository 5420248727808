import React, { useState } from 'react';
import PropTypes from 'prop-types';

function Password (props) {
  // state
  const [isVisible, setIsVisible] = useState(false);
  const value = isVisible ? props.value : '*'.repeat(props.value.length);
  const buttonText = isVisible ? 'Hide' : 'Show';

  // user actions
  const onShowPasswordClicked = () => {
    setIsVisible(!isVisible);
  };

  // render
  return (
    <div className='password-wrapper'>
      { value }
      <button type='button' className='btn btn-link' onClick={onShowPasswordClicked}>{ buttonText }</button>
    </div>
  );
}

Password.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Password;
