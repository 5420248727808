import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

import SearchableDropdown from 'components/SearchableDropdown';

class SKUFieldGroup extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    updateTemporaryLineItem: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    upwards: PropTypes.bool,
    skuLabel: PropTypes.string,
  }

  static defaultProps = {
    config: {},
    placeholder: '',
    upwards: false,
    skuLabel: 'SKU',
  }

  state = {
    quantityValue: 1,
    skuValue: '',
    clearDropdownValue: false,
  }

  constructor() {
    super();

    this.handleInputValue = this.handleInputValue.bind(this);
    this.addLineItem = this.addLineItem.bind(this);
    this.updateClearValue = this.updateClearValue.bind(this);
  }

  componentDidMount() {
    const defaultValues = {};

    if (this.props.skuType.defaultvalues) {
      defaultValues.quantityValue = this.props.skuType.defaultvalues.quantity;
      defaultValues.skuValue = this.props.skuType.defaultvalues.partnumber;
    }

    this.setState({ ...defaultValues });
  }

  updateClearValue(clearDropdownValue) {
    this.setState({
      skuValue: '',
      clearDropdownValue,
    }, () => {
      const { props, state } = this;

      props.updateTemporaryLineItem('', state.quantityValue, props.skuType.type, props.skuType.displayname);
    });
  }

  handleInputValue(inputName, custom=false) {
    return (param) => {
      this.setState({ [inputName]: custom ? param : param.target.value }, () => {
        const { props, state } = this;
        let selectedOption = props.options.find(o => o.sku === state.skuValue);

        // if the user entered their own sku
        if (!selectedOption) {
          selectedOption = {
            sku: state.skuValue,
          };
        }

        props.updateTemporaryLineItem(selectedOption, state.quantityValue, props.skuType.type, props.skuType.displayname);
      });
    }
  }

  isValidSKU(sku) {
    if (this.props.config.partnumber) {
      const format = new RegExp(this.props.config.partnumber.format);

      return sku ? format.test(sku) : true;
    }

    return true;
  }

  addLineItem() {
    let sku = this.state.skuValue;

    if (!this.state.skuValue) {
      sku = 'default';
    }

    let selectedOption = this.props.options.find(o => o.sku === this.state.skuValue);

    // if the user entered their own sku
    if (!selectedOption) {
      selectedOption = { sku };
    }

    this.props.addLineItem(selectedOption, this.state.quantityValue, this.props.skuType);

    this.updateClearValue(true);
  }

  render() {
    const { props } = this;
    const isSKUValid = this.isValidSKU(this.state.skuValue);
    const dropdownProps = {
      options: props.options,
      handleInputValue: this.handleInputValue('skuValue', true),
      updateClearValue: this.updateClearValue,
      clearValue: this.state.clearDropdownValue,
      placeholder: this.props.placeholder,
      upwards: this.props.upwards,
      disabled: this.props.disabled,
    };
    const inputProps = {
      type: 'number',
      value: this.state.quantityValue,
      onChange: this.handleInputValue('quantityValue'),
      className: 'form-control',
      disabled: this.props.disabled,
    };
    const buttonProps = {
      disabled: props.disabled,
      type: 'button',
      className: 'btn btn-primary',
      onClick: this.addLineItem,
    }
    const wrapperProps = {
      className: classname({
        'searchable-dropdown-wrapper': true,
        'has-error': !isSKUValid,
      }),
    };
    const saveButtonText = !this.props.isMultiple ? 'Save' : 'Add more';

    if (props.simple) {
      const options = props.options.map(option => {
        return <option key={option.sku} value={option.sku}>{ option.name }</option>;
      });

      return (
        <div className='col-sm-6 modal-form-input form-group'>
          <label>
            { props.displayName }
          </label>

          <select className='form-control' onChange={this.props.updateTemporaryLineItem}>
            <option value='none'>Select an option</option>
            { options }
          </select>
        </div>
      );
    }

    return (
      <div {...wrapperProps}>
        <div className='col-sm-8'>
          <label>
            { props.displayName }
            <span>Pick a product from the list or enter your own { props.skuLabel }</span>
          </label>

          <SearchableDropdown {...dropdownProps} />

          {!isSKUValid && (
            <small className='has-error-reason'>{ props.skuLabel } format is not valid</small>
          )}
        </div>

        <div className='col-sm-2'>
          <label htmlFor={inputProps.name}>Quantity</label>
          <input {...inputProps} />
        </div>

        {!props.disabled && (
          <div className='col-sm-2'>
            <button {...buttonProps}>{ saveButtonText }</button>
          </div>
        )}

        <div className='clearfix'></div>
      </div>
    );
  }
}

export default SKUFieldGroup;
