import { deleteExecutionTransactions, getHostingExecutionStatus } from 'utils/api.service';

export const Types = {
  SET_DEFAULT_FILTERS: 'submit/SETTING_DEFAULT_SOURCE',
};

// THESE ARE FOR THE SUBMITTING PAGE, WHICH IS CONSIDERED AN EXTENSION OF JOBS QUEUE.
export const setDefaultFilter = (source) => {
  return (dispatch) => {
    dispatch({
      type: Types.SET_DEFAULT_FILTERS,
      source
    });
  }
};

export const deleteTransactions = (service, selectedTransactions) => {
  const executions = Object.keys(selectedTransactions);
  const requests = executions.map((executionid) => {
    const payload = {
      transactionids: Array.from(selectedTransactions[executionid]),
    };

    return deleteExecutionTransactions(service, executionid, payload);
  });

  return Promise.all(requests);
};

export default {
  getExecutionDetails: (service, executionid) => {
    return getHostingExecutionStatus(service, executionid);
  },
};
