import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LoadingSpinner from 'components/LoadingSpinner';
import 'react-table/react-table.css';
import {
  getMasterData,
  getSearchDataStores,
  claimTestDataStore,
  getClaimList,
} from 'utils/api.service';
import { setLightboxState } from 'store/actions/lightbox_actions';
import ReactTooltip from 'react-tooltip';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { addNewNotification } from 'store/actions/notification_actions';
import ConfirmationModal from 'components/ConfirmationModal';

const TestDataStore = () => {
  const [loadingMasterData, setLoadingMasterData] = useState(true);
  const [loadingDataStore, setLoadingDataStore] = useState(false);
  const [data, setData] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedDataAge, setSelectedDataAge] = useState('');
  const [masterData, setMasterData] = useState(null);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [claimingDataStore, setClaimingDataStore] = useState(false);
  const [claimList, setClaimList] = useState([]);
  const [dataWasClaimed, setDataWasClaimed] = useState(false);
  const [dataWasDiscarded, setDataWasDiscarded] = useState(false);
  const [dataWasSearched, setDataWasSearched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');

  const dispatch = useDispatch();

  const fetchClaimList = async () => {
    try {
      const { data } = await getClaimList();
      setClaimList(data);
    } catch (error) {
      dispatch(addNewNotification('Error fetching claim list', 'danger'));
    }
  };

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const {
          data: { dataage, ...environments },
        } = await getMasterData();
        setMasterData({ dataage, environments });
        setSelectedEnvironment(Object.keys(environments)[0]);
        setSelectedStore(
          Object.keys(environments[Object.keys(environments)[0]])[0]
        );
        setSelectedCategory(
          Object.keys(
            environments[Object.keys(environments)[0]][
              Object.keys(environments[Object.keys(environments)[0]])[0]
            ]
          )[0]
        );
        setSelectedSubCategory(
          Object.values(
            environments[Object.keys(environments)[0]][
              Object.keys(environments[Object.keys(environments)[0]])[0]
            ]
          )[0].subCategories[0].subdatacategorykey
        );
        setSelectedDataAge(Object.values(dataage)[0]);
        setLoadingMasterData(false);
      } catch (error) {
        dispatch(addNewNotification('Error fetching master data', 'danger'));
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchMasterData(), fetchClaimList()]);
    };

    fetchData();
  }, []);

  const searchDataStore = async () => {
    const getDataStores = async () => {
      setLoadingDataStore(true);
      setData(null);
      setHasSearchError(false);
      try {
        const {
          data: {
            createdtime: createdTime,
            subdatacategoryname: subCategoryName,
            datastoreoutput: dataStoreOutput,
            testdata: testData,
            store,
            datacategoryname: dataCategoryName,
            createddate: createdDate,
          },
        } = await getSearchDataStores(
          masterData.environments[selectedEnvironment][selectedStore][
            selectedCategory
          ].datacategorykey,
          selectedSubCategory,
          selectedEnvironment,
          selectedStore,
          selectedDataAge
        );
        setData({
          createdTime,
          subCategoryName,
          dataStoreOutput: dataStoreOutput || testData,
          store,
          dataCategoryName,
          createdDate,
        });
        setDataWasSearched(true);
        setDataWasDiscarded(false);
        setLoadingDataStore(false);
        setDataWasClaimed(false);
      } catch (error) {
        setHasSearchError(true);
        setSearchErrorMessage(
          error?.response?.data || 'Error fetching data store'
        );
        setLoadingDataStore(false);
        setDataWasSearched(false);
      }
    };
    Promise.all([getDataStores(), fetchClaimList()]);
  };

  const showConfirmationModal = () => {
    setIsModalOpen(true);
  };

  const discardDataStore = async () => {
    setIsModalOpen(false);
    dispatch(setLightboxState({ visible: true }));
    const payload = {
      records: [
        {
          datacategorykey:
            masterData.environments[selectedEnvironment][selectedStore][
              selectedCategory
            ].datacategorykey,
          subdatacategorykey: selectedSubCategory,
          createddate: parseInt(data.createdDate),
          env: selectedEnvironment,
          store: selectedStore,
        },
      ],
    };
    try {
      await claimTestDataStore(payload);
      dispatch(
        addNewNotification(
          'This data was successfully sent back to the data pool',
          'success'
        )
      );
      setDataWasDiscarded(true);
    } catch ({
      response: {
        data: { detail: errorDetail },
      },
    }) {
      dispatch(addNewNotification(errorDetail, 'danger'));
    } finally {
      setClaimingDataStore(false);
      dispatch(setLightboxState({ visible: false }));
      setDataWasSearched(false);
    }
  };

  const handleEnvironmentChange = (event) => {
    setSelectedEnvironment(event.target.value);
    setSelectedStore(
      Object.keys(masterData.environments[event.target.value])[0]
    );
    setSelectedCategory(
      Object.keys(
        masterData.environments[event.target.value][
          Object.keys(masterData.environments[event.target.value])[0]
        ]
      )[0]
    );
    setSelectedSubCategory(
      masterData.environments[event.target.value][
        Object.keys(masterData.environments[event.target.value])[0]
      ][
        Object.keys(
          masterData.environments[event.target.value][
            Object.keys(masterData.environments[event.target.value])[0]
          ]
        )[0]
      ].subCategories[0].subdatacategorykey
    );
  };

  const handleStoreChange = (event) => {
    setSelectedStore(event.target.value);
    setSelectedCategory(
      Object.keys(
        masterData.environments[selectedEnvironment][event.target.value]
      )[0]
    );
    setSelectedSubCategory(
      masterData.environments[selectedEnvironment][event.target.value][
        Object.keys(
          masterData.environments[selectedEnvironment][event.target.value]
        )[0]
      ].subCategories[0].subdatacategorykey
    );
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(
      masterData.environments[selectedEnvironment][selectedStore][
        event.target.value
      ].subCategories[0].subdatacategorykey
    );
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleDataAgeChange = (event) => {
    setSelectedDataAge(event.target.value);
  };

  return (
    <div>
      <h2 className="title">Data Store</h2>
      <hr />
      {loadingMasterData ? (
        <LoadingSpinner text="Loading master data" />
      ) : (
        <div>
          <div className="search-header">
            <section>
              <label htmlFor="environmet">Environment</label>
              <select
                className="form-control"
                name="environment"
                value={selectedEnvironment}
                onChange={handleEnvironmentChange}
              >
                {Object.keys(masterData.environments).map((environment) => (
                  <option key={environment} value={environment}>
                    {environment}
                  </option>
                ))}
              </select>
            </section>
            <section>
              <label htmlFor="store">Store</label>
              <select
                className="form-control"
                id="stores"
                value={selectedStore}
                onChange={handleStoreChange}
              >
                {Object.keys(masterData.environments[selectedEnvironment]).map(
                  (store) => (
                    <option key={store} value={store}>
                      {store}
                    </option>
                  )
                )}
              </select>
            </section>
            <section>
              <label htmlFor="category">Category</label>
              <select
                className="form-control"
                id="categories"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                {Object.keys(
                  masterData.environments[selectedEnvironment][selectedStore]
                ).map((category) => (
                  <option
                    key={
                      masterData.environments[selectedEnvironment][
                        selectedStore
                      ][category].datacategorykey
                    }
                    value={category}
                  >
                    {category}
                  </option>
                ))}
              </select>
            </section>
            <section>
              <label htmlFor="subcategory">Subcategory</label>
              <select
                className="form-control"
                id="subCategories"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
              >
                {masterData.environments[selectedEnvironment][selectedStore][
                  selectedCategory
                ].subCategories.map((subCategory) => (
                  <option
                    key={subCategory.subdatacategorykey}
                    value={subCategory.subdatacategorykey}
                  >
                    {subCategory.subdatacategoryname}
                  </option>
                ))}
              </select>
            </section>
            <section>
              <label htmlFor="data-age">Data Age</label>
              <select
                className="form-control"
                name="data-age"
                onChange={handleDataAgeChange}
                value={selectedDataAge}
              >
                {Object.entries(masterData.dataage).map(([label, value]) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </section>
            {dataWasSearched && (
              <ReactTooltip
                id="search-button"
                place="top"
                effect="solid"
                className="tooltip"
              >
                Search result has to be claimed or discarded to trigger a new
                search
              </ReactTooltip>
            )}
            <button
              className="btn btn-primary btn-search"
              onClick={searchDataStore}
              disabled={
                loadingDataStore || claimingDataStore || dataWasSearched
              }
              data-tip
              data-for="search-button"
            >
              Search
            </button>
          </div>
          <div className="spacer" />
          {loadingDataStore ? (
            <LoadingSpinner text="Loading search data" />
          ) : (
            data &&
            !dataWasDiscarded && (
              <div>
                <div>
                  <h4 className="title">Found 1 result </h4>
                </div>
                <div className="table-container">
                  <ReactTable
                    data={[
                      {
                        date: data.createdTime.split('.')[0],
                        subcategory: data.subCategoryName,
                        dataStoreOutput: data.dataStoreOutput,
                      },
                    ]}
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'date',
                        width: 200,
                        className: 'text-center text-center_vertical',
                      },
                      {
                        Header: 'Subcategory',
                        accessor: 'subcategory',
                        className: 'text-center text-center_vertical',
                        width: 150,
                      },
                      {
                        Header: 'Test Data',
                        accessor: 'details',
                        className: 'text-center_vertical',
                        Cell: ({ original }) =>
                          Object.entries(original.dataStoreOutput).map(
                            ([key, value]) => (
                              <div key={key}>
                                <div>
                                  <code>
                                    {key}
                                    {': '}
                                  </code>
                                  <samp>{value}</samp>
                                </div>
                              </div>
                            )
                          ),
                      },
                      {
                        Header: '',
                        accessor: 'claim',
                        width: 100,
                        align: 'center',
                        className: 'text-center center_vertical',
                        Cell: (_) => {
                          const disabledTooltip =
                            'Test data was already claimed You need to trigger a new search';
                          return (
                            <div className="button-container">
                              <ReactTooltip
                                id="claim-button"
                                place="left"
                                effect="solid"
                              >
                                {dataWasClaimed
                                  ? disabledTooltip
                                  : 'Claim action will confirm this test data for you'}
                              </ReactTooltip>
                              <button
                                className="btn btn-primary btn-search btn-column"
                                onClick={() => {
                                  dispatch(
                                    addNewNotification(
                                      'Claimed data store was confirmed',
                                      'success'
                                    )
                                  );
                                  setDataWasClaimed(true);
                                  setDataWasSearched(false);
                                }}
                                disabled={claimingDataStore || dataWasClaimed}
                                data-tip
                                data-for="claim-button"
                              >
                                {dataWasClaimed ? 'Claimed' : 'Claim'}
                              </button>
                              <div className="spacer" />
                              <div className="button-container">
                                <ReactTooltip
                                  id="discard-button"
                                  place="left"
                                  effect="solid"
                                >
                                  By default this data is reserved for you. This
                                  action will send the data back to data pool
                                </ReactTooltip>
                                <button
                                  className="btn btn-primary btn-search btn-column"
                                  onClick={showConfirmationModal}
                                  data-tip
                                  data-for="discard-button"
                                >
                                  Discard
                                </button>
                              </div>
                            </div>
                          );
                        },
                      },
                    ]}
                    defaultPageSize={1}
                    loading={loadingDataStore}
                    page={0}
                    showPagination={false}
                    sortable={false}
                  />
                </div>
              </div>
            )
          )}
          {hasSearchError && (
            <h4 className="error-message title no-data">
              {searchErrorMessage}
            </h4>
          )}
          <div className="spacer" />
          {claimList.length > 0 && (
            <>
              <h4 className="title">Your last claimed data</h4>
              <ReactTable
                defaultSorted={[
                  {
                    id: 'claimeddate',
                    desc: true,
                  },
                ]}
                data={claimList}
                columns={[
                  {
                    Header: 'Claimed On',
                    accessor: 'claimeddate',
                    className: 'text-center',
                    width: 150,
                    Cell: ({ original }) =>
                      original.claimeddate
                        ? original.claimeddate.split(' ')[0]
                        : '',
                  },
                  {
                    Header: 'Env',
                    accessor: 'env',
                    className: 'text-center',
                    width: 50,
                  },
                  {
                    Header: 'Store',
                    accessor: 'store',
                    className: 'text-center',
                    width: 100,
                  },
                  {
                    Header: 'Category',
                    accessor: 'datacategoryname',
                    className: 'text-center',
                    width: 200,
                  },
                  {
                    Header: 'SubCategory',
                    accessor: 'subdatacategoryname',
                    className: 'text-center',
                  },
                  {
                    Header: 'Test Data',
                    accessor: 'details',
                    className: 'text-center_vertical',
                    Cell: ({ original }) =>
                      original.datastoreoutput
                        ? Object.entries(original.datastoreoutput).map(
                            ([key, value]) => (
                              <div key={key}>
                                <div>
                                  <code>
                                    {key}
                                    {': '}
                                  </code>
                                  <samp>{value}</samp>
                                </div>
                              </div>
                            )
                          )
                        : original.outputpayload.map(({ name, value }) => (
                            <div key={name}>
                              <div>
                                <code>
                                  {name}
                                  {': '}
                                </code>
                                <samp>{value}</samp>
                              </div>
                            </div>
                          )),
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                loading={loadingDataStore}
              />
            </>
          )}
          {isModalOpen && (
            <ConfirmationModal
              title="Discard Data Store"
              message="Are you sure you want to discard this data store?"
              onClose={() => setIsModalOpen(false)}
              onConfirm={discardDataStore}
            >
              <p>
                By discarding this data store, you are sending it back to the
                data pool. This action cannot be undone.
              </p>
            </ConfirmationModal>
          )}
        </div>
      )}
    </div>
  );
};
export default TestDataStore;
