import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

function CollapsibleDropdown(props) {
  // state
  const [open, setOpen] = useState(false);
  // user actions
  const onDropdownClick = () => {
    setOpen(!open);
  };
  // render methods
  const dropdownProps = {
    className: classname({
      'collapsible-dropdown': true,
      'open': open,
    }),
  };

  return (
    <div {...dropdownProps}>
      <span onClick={onDropdownClick}>{ props.title }</span>
      <img src='https://testing-hub-static-assets.s3.amazonaws.com/images/icons/arr-d.png' alt='' />

      <div className='collapsible-dropdown-content'>
        { props.children }
      </div>
    </div>
  );
}

CollapsibleDropdown.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CollapsibleDropdown;
