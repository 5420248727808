import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as CJT } from './svg/cjt.svg';
import { ReactComponent as TestData } from './svg/testdata.svg';
import { ReactComponent as PerformanceTesting } from './svg/performancetesting.svg';

const LandingPage = () => {
  const { emailid: emailId, displayname: displayName } = useSelector(
    (state) => state.app?.userInfo || {}
  );
  return (
    <div className="home-wrapper">
      <div className="home-intro">
        <h5>Hello {displayName || emailId}</h5>
        <h3>Welcome to Testing Hub!</h3>

        <p>
          Testing Hub is a powerful self-service platform which provides
          software testing services on demand. It helps engineering and business
          teams create and manage their test data, execute end to end business
          functional tests and implement virtualized services via their browser
          as well a REST API.
        </p>
      </div>

      <div className="home-offerings">
        <ul>
          <li>
            <div className="svg-wrapper">
              <CJT />
            </div>

            <h5>Customer Journey Testing</h5>
            <p>
              Customer Journey Testing helps engineering teams confidently
              deliver on-demand releases by running a suite of pre-determined
              tests within a CI/CD pipeline.
            </p>
            <Link to="/cjt/content">
              Learn more <i className="anchor-caret">{'>'}</i>
            </Link>
          </li>

          <li>
            <div className="svg-wrapper">
              <TestData />
            </div>

            <h5>Test Data</h5>
            <p>
              The test data is available through a REST API that can be easily
              integrated with an engineering team's automated functional tests
              to create data on the fly.
            </p>
            <Link to="/quarry/content">
              Learn more <i className="anchor-caret">{'>'}</i>
            </Link>
          </li>

          <li>
            <div className="svg-wrapper">
              <PerformanceTesting />
            </div>

            <h5>Test Hosting</h5>
            <p>
              Test Hosting feature which enables engineering team to host their
              testcases. These testcases can be shared with Business users or
              other teams, executed via Testinghub UI or API and test execution
              status can also be monitored using API or Testinghub UI
            </p>
            <a
              href="https://wiki.autodesk.com/display/DES/Test+hosting"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more <i className="anchor-caret">{'>'}</i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LandingPage;
