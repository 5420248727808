import React from 'react';

import CJTModal from './CJTModal';
import Checkbox from 'components/Checkbox';
import Channels from 'components/Channels';
import TestCase from 'components/TestCase';
import EnvironmentSwitch from 'components/EnvironmentSwitch';
import LoadingSpinner from 'components/LoadingSpinner';
import TotalSelectedBadge from 'components/TotalSelectedBadge';

import { fetchCategoryDataQuarry, fetchChannelList } from 'utils/api.service';
import filterList from 'selectors/search';

class CJT extends React.Component {
  state = {
    activeChannel: '',
    catid: '',
    localChannels: [],
    metafields: {},
    loadingTestCases: false,
    scenarios: [],
    visibleScenarios: [],
    searchparam: '',
    submitting: false,
    where: '',
  };

  constructor() {
    super();

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.getScenarios = this.getScenarios.bind(this);
    this.prepareScenarios = this.prepareScenarios.bind(this);
    this.onSubmitTestsClick = this.onSubmitTestsClick.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.selectAllRef = React.createRef();
  }

  componentDidMount() {
    this.getChannels();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeEnvironment !== this.props.activeEnvironment) {
      this.checkSelectedItems();
    }
  }

  getChannels() {
    fetchCategoryDataQuarry(this.props.id)
      .then((res) => {
        const subcategories = res.data.subcategory.slice(0);
        const environments = res.data.envlist;
        const activeEnvironment =
          this.props.activeEnvironment || environments[0].env;
        const activeChannel = subcategories.filter(
          (c) => c.status[activeEnvironment] === 'enabled'
        )[0];

        this.setState({
          localChannels: res.data.subcategory,
          activeChannel: activeChannel.subcategoryid,
          catid: res.data.categoryid,
          where: res.data.categoryname,
          loadingTestCases: true,
          environments,
        });

        this.getScenarios(activeChannel.subcategoryid);
      })
      .catch((err) => console.log(err));
  }

  getScenarios(channelid) {
    this.setState({
      activeChannel: channelid,
      searchparam: '',
      loadingTestCases: true,
      scenarios: [],
      visibleScenarios: [],
    });

    fetchChannelList(this.state.catid, channelid)
      .then((res) => {
        const scenarios = this.prepareScenarios(res.data.testcases);
        const metafields = res.data.metafields;

        this.setState({
          visibleScenarios: scenarios.filter(
            (test) => test.status[this.props.activeEnvironment] === 'enabled'
          ),
          scenarios,
          metafields,
          loadingTestCases: false,
        });
      })
      .catch((err) => console.log(err));
  }

  handleSearchInputChange(e) {
    const visibleScenarios = filterList(this.state.scenarios, e.target.value)
      .map((scenario) => {
        const previousSelection = this.state.visibleScenarios.find(
          (test) => test.testcaseid === scenario.testcaseid
        );

        if (previousSelection) {
          return {
            ...scenario,
            checked: previousSelection.checked,
          };
        }

        return scenario;
      })
      .filter(
        (test) => test.status[this.props.activeEnvironment] === 'enabled'
      );

    this.setState({
      searchparam: e.target.value,
      visibleScenarios,
    });
  }

  selectAll() {
    const visibleScenarios = this.state.visibleScenarios.map((item) => {
      const checked =
        item.status[this.props.activeEnvironment] === 'disabled'
          ? false
          : this.selectAllRef.current.checked;

      return { ...item, checked };
    });

    this.setState({
      visibleScenarios,
    });
  }

  selectItem(id) {
    const visibleScenarios = this.state.visibleScenarios.map((item) => {
      return item.testcaseid !== id
        ? item
        : { ...item, checked: !item.checked };
    });

    this.setState({
      visibleScenarios,
    });
  }

  checkSelectedItems() {
    const { props, state } = this;
    const availableChannels = state.localChannels.filter(
      (c) => c.status[props.activeEnvironment] === 'enabled'
    );
    const visibleScenarios = state.visibleScenarios.map((test) => {
      return test.status[props.activeEnvironment] === 'enabled'
        ? test
        : { ...test, checked: false };
    });
    let activeChannel = state.activeChannel;

    if (
      !availableChannels
        .map((c) => c.subcategoryid)
        .includes(state.activeChannel)
    ) {
      activeChannel = availableChannels[0].subcategoryid;

      this.getScenarios(activeChannel);
    }

    this.setState({
      activeChannel,
      visibleScenarios,
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  onSubmitTestsClick() {
    if (this.state.where === 'Order Fulfillment') {
      this.openModal();
    } else {
      this.submitTests();
    }
  }

  submitTests() {
    const { props, state } = this;
    const channel = state.localChannels.find(
      (c) => c.subcategoryid === state.activeChannel
    );
    const payload = {
      env: props.activeEnvironment,
      subcategoryname: channel.subcategoryname.toLowerCase(),
      items: state.visibleScenarios.filter((scenario) => scenario.checked),
    };

    if (payload.items.length > 0) {
      this.setState({ submitting: true });

      props.submitTests(payload).then(() => {
        this.setState({ submitting: false });
      });
    }
  }

  prepareScenarios(data) {
    const { state } = this;
    const newData = data
      .map((item) => {
        const channel = state.localChannels.find(
          (c) => c.subcategoryid === state.activeChannel
        );

        return {
          ...item,
          checked: false,
          channel: channel.subcategoryname.toLowerCase(),
        };
      })
      .filter((item) => item.status !== 'disabled');

    return newData;
  }

  renderParameterizationModal() {
    const { props, state } = this;
    const selectedTestCases = state.visibleScenarios.filter(
      (scenario) => scenario.checked
    );

    if (selectedTestCases.length && state.showModal) {
      const channel = state.localChannels.find(
        (c) => c.subcategoryid === state.activeChannel
      );

      const modalProps = {
        environment: props.activeEnvironment,
        channel: channel.subcategoryname.toLowerCase(),
        history: props.history,
        onClose: this.closeModal,
        scenarios: selectedTestCases,
        submitTests: props.submitTests,
        metafields: this.state.metafields,
        userInfo: props.userInfo,
      };

      return <CJTModal {...modalProps} />;
    }

    return null;
  }

  renderChannels(channels) {
    if (this.state.activeChannel) {
      const channelsProps = {
        channels: channels.filter(
          (c) => c.status[this.props.activeEnvironment] === 'enabled'
        ),
        onClick: this.getScenarios,
        selectedChannel: this.state.activeChannel,
        environment: this.props.activeEnvironment,
      };

      return <Channels {...channelsProps} />;
    }

    return null;
  }

  renderFiltersAndButtons() {
    const { state } = this;
    const selectedTestCases = state.visibleScenarios.filter(
      (scenario) => scenario.checked
    );
    const renderSelectAll = selectedTestCases.length >= 3;
    const searchProps = {
      type: 'search',
      onChange: this.handleSearchInputChange,
      value: state.searchparam,
      name: 'search',
      className: 'form-control',
      placeholder: 'Search tests',
    };
    const buttons = [];
    const allSelected =
      selectedTestCases.length === state.visibleScenarios.length;
    const visibleScenarios = state.visibleScenarios.filter((testCase) => {
      return testCase.status[this.props.activeEnvironment] !== 'disabled';
    });

    if (selectedTestCases.length) {
      buttons.push(
        <button
          key="submit"
          type="button"
          disabled={state.submitting}
          onClick={this.onSubmitTestsClick}
          className="btn btn-primary"
        >
          Submit test
        </button>
      );
    }

    return (
      <div className="cjt-search-filter-actions">
        <div className="form-group col-md-3 pl-0">
          <input {...searchProps} />
        </div>

        <div className="cjt-actions">
          <TotalSelectedBadge
            available={visibleScenarios.length}
            selected={selectedTestCases.length}
            total={this.state.scenarios.length}
          />

          {renderSelectAll && (
            <div className="cjt-actions-select-all">
              <Checkbox
                ref={this.selectAllRef}
                checked={allSelected}
                onChange={this.selectAll}
              />
              <label className="cjt-actions-select-all-label">Select All</label>
            </div>
          )}

          {buttons}
        </div>
      </div>
    );
  }

  renderTestCases(testCases) {
    const { props, state } = this;

    if (state.loadingTestCases && !testCases.length) {
      return <LoadingSpinner text="Loading tests" />;
    } else {
      const tests = testCases.map((testCase) => {
        if (testCase.status[props.activeEnvironment] === 'disabled') {
          return null;
        } else {
          const testCaseProps = {
            checked: testCase.checked,
            description: testCase.description,
            name: testCase.name,
            testcaseid: testCase.testcaseid,
            onItemSelected: this.selectItem,
            learnMoreUrl:
              'https://wiki.autodesk.com/display/DES/CJT+Test+Case+Steps+-+Order+Fulfillment',
          };

          return (
            <div className="scenario" key={testCase.name.split(' ').join('-')}>
              <TestCase {...testCaseProps} />
            </div>
          );
        }
      });
      const emptyItems = [
        ...Array(
          Math.ceil(tests.filter((t) => t).length / 3) * 3 -
            tests.filter((t) => t).length
        ).keys(),
      ];
      const placeholders = emptyItems.map((idx) => (
        <div className="scenario empty" key={`empty-${idx}`}></div>
      ));

      return [...tests, ...placeholders];
    }
  }

  render() {
    const { state } = this;
    const channels = this.renderChannels(state.localChannels);
    const searchFilters = this.renderFiltersAndButtons();
    const testCases = this.renderTestCases(state.visibleScenarios);
    const modal = this.renderParameterizationModal();
    const environmentProps = {
      environments: state.environments,
      context: this.props.context,
    };

    return (
      <div className="cjt-wrapper">
        <section className="section-title">
          <div className="section-title-group">
            <h2 className="title">{state.where || 'Loading...'}</h2>
            <a
              href="https://wiki.autodesk.com/display/DES/Project+Mesh"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is this?
            </a>
          </div>

          <EnvironmentSwitch {...environmentProps} />
        </section>

        <div className="cjt-channels">{channels}</div>

        <div className="cjt-action-buttons">{searchFilters}</div>

        <div className="scenarios">{testCases}</div>

        {modal}
      </div>
    );
  }
}

export default CJT;
