import React from 'react';
import PropTypes from 'prop-types';

function PageTitle(props) {
  return (
    <div className='page-title'>
      <div className='title-wrapper'>
        <h2 className='title'>{ props.title }</h2>
        <p className='description'>{ props.description }</p>
      </div>
      <div className='children-wrapper'>
        { props.children }
      </div>
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  learnMoreText: PropTypes.string,
  learnMoreLink: PropTypes.string,
};

PageTitle.defaultProps = {
  description: '',
  learnMoreText: '',
  learnMoreLink: '',
};

export default PageTitle;
