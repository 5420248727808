import React from 'react';
import PropTypes from 'prop-types';

function LoadingPage(props) {
  return (
    <div className='loading-container'>
      <span className='loading-spinner'></span>
      <h4 className='loading-title'>Loading</h4>
      <div className='loading-message'>{ props.message }</div>
    </div>
  );
}

LoadingPage.propTypes = {
  message: PropTypes.string,
};

LoadingPage.defaultProps = {
  message: '',
};

export default LoadingPage;
