const HEADERS = ['createddate', 'executionid', 'transactionid', 'status', 'name', 'userinput', 'validations', 'syncstatus', 'steps', 'output', 'testdata', 'message', 'logid'];

const normalizeString = (string) => {
  return string.replaceAll('"', '').replaceAll(',', '; ').replaceAll(':', ': ');
}

const parseCellData = (cellData, header) => {
  // if is an object but not an array
  if (typeof cellData === 'object' && !Array.isArray(cellData)) {
    cellData = Object.keys(cellData).map(key => {
      const value = normalizeString(JSON.stringify(cellData[key]));

      return { name: key, value };
    });
  }

  if (Array.isArray(cellData)) {
    const list = [];

    cellData.forEach(step => {
      const key = step.validation || step.sync || step.name || '';
      let value = step.status || step.value || '';
      let cell;

      if (typeof value !== 'string') {
        value = '{}';
      }

      cell = !!key ? `${key}: ${value}` : value;

      if (step.screenshots) {
        const screenshotUrl = `${process.env.REACT_APP_HOST}/screenshots?id=${step.screenshots.join('__')}`;

        cell = `${cell} - ${screenshotUrl}`
      }

      list.push(normalizeString(cell));
    });

    if (list.length) {
      return `"${list.join('\n')}"`;
    } else {
      return '';
    }
  } else {
    if (header === 'createddate') {
      return new Date(cellData).toLocaleString().replace(',', '');
    } else {
      return cellData ? `"${cellData}"` : '';
    }
  }
};

const generateTestDataReport = (records) => {
  const headers = [];
  const rows = [];

  records.forEach((record) => {
    const row = [];

    HEADERS.forEach((header) => {
      if (record[header] && ['userinput', 'output', 'testdata'].includes(header)) {
        if (typeof record[header] === 'object' && !Array.isArray(record[header])) {
          Object.entries(record[header]).forEach(([key, value]) => {
            if (rows.length === 0) {
              headers.push(key);
            }

            row.push(parseCellData(value, header));
          });
        }

        if (Array.isArray(record[header])) {
          record[header].forEach((column) => {
            if (rows.length === 0) {
              headers.push(column.name);
            }

            row.push(parseCellData(column.value, header));
          });
        }
      } else {
        if (rows.length === 0) {
          headers.push(header);
        }

        row.push(parseCellData(record[header], header));
      }
    });

    rows.push(row);
  });

  rows.unshift(headers);

  return rows;
};

const generateReport = (records) => {
  const rows = [];

  rows.push(HEADERS);

  // prepare data
  records.forEach((record, index) => {
    const row = [];

    HEADERS.forEach(header => {
      row.push(parseCellData(record[header], header));
    });

    rows.push(row);
  });

  return rows;
};

const CSVReportGenerator = (records, service) => {
  const rows = service === 'testdata' ? generateTestDataReport(records) : generateReport(records);
  let report = '';

  // generate report and send it back
  report += rows.map(r => r.join(',')).join('\n');

  return report;
};

export default CSVReportGenerator;
