import { Types } from 'store/actions/lightbox_actions';

const initialState = {
  visible: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case Types.SET_VISIBLE:
      return {
        ...state,
        visible: action.state.visible,
      }
    default:
      return state;
  }
};

export default reducer;
