import { combineReducers } from 'redux';

import appReducer from './app_reducer.js';
import filtersReducer from './filters_reducer.js';
import jobsQueueReducer from './jobsqueue_reducer.js';
import loginReducer from './login_reducer.js';
import menuReducer from './menu_reducer.js';
import meshReducer from './mesh_reducer.js';
import quarryReducer from './quarry_reducer.js';
import hostingReducer from './hosting_reducer.js';
import notificationReducer from './notification_reducer.js';
import testSuitesReducer from './test_suites_reducer.js';
import lightboxReducer from './lightbox_reducer.js';

export default combineReducers({
  app: appReducer,
  user: loginReducer,
  quarry: quarryReducer,
  mesh: meshReducer,
  hosting: hostingReducer,
  menu: menuReducer,
  jobsQueue: jobsQueueReducer,
  filter: filtersReducer,
  notifications: notificationReducer,
  testSuites: testSuitesReducer,
  lightbox: lightboxReducer,
});
