import { Types } from 'store/actions/hosting_actions';

const defaultState = {
  isSubmitting: false,
  status: '',
  payload: {},
  data: null,
  message: '',
  role: 'member',
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case (Types.UPDATE_ROLE):
      return {
        ...state,

        role: action.role,
      }
    case (Types.SUBMIT_BEGIN):
      return {
        ...state,

        isSubmitting: true,
        status: '',
        message: '',
        data: null,
        payload: {},
      };
    case 'HOSTING_RESET':
      return {
        ...state,

        status: '',
        message: '',
        data: null,
        payload: {},
      };
    case (Types.SUBMIT_END):
      return {
        ...state,

        isSubmitting: false,
        status: action.status,
        message: action.message,
        data: action.data
      };
    default:
      return state;
  }
}
