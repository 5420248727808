let endpoint = process.env.REACT_APP_API_ENDPOINT;
let stack = endpoint.split('.testinghub.autodesk.com')[0].split('https://')[1];
const endpoints = {
  getNavigationData: {
    url: 'thservices/v1/categories',
  },
  createAccessTestCase: {
    method: 'post',
    url: 'cjt/v1/portal/testcase',
  },
  createTestCase: {
    method: 'post',
    url: 'cjt/v1/:channel/testcase',
  },
  fetchCategoryDataQuarry: {
    url: 'cjt/v1/categories?categoryid=:id&limit=11011',
  },
  fetchChannelList: {
    url: 'cjt/v1/categories?categoryid=:categoryid&limit=11011&subcategoryid=:channelid',
  },
  fetchNextDataSet: {
    url: ':url',
  },
  getCustomOrders: {
    url: 'testdata/v1/mydata?status=:filter&limit=:limit',
  },
  getCustomTestCases: {
    url: 'cjt/v1/mytestcases?:query',
  },
  getQuarryChannels: {
    url: 'testdata/v1/:category',
  },
  getQuarryTestCases: {
    url: 'testdata/v1/:category?subcategoryid=:channel&limit=11011&env=:env',
  },
  getProducts: {
    url: 'testdata/v1/getproducts?intendedUsage=:channelName&env=:env&limit=300',
  },
  getPublicKey: {
    url: 'encryption/v1/publickey',
  },
  getQuarryTestCaseFields: {
    url: 'testdata/v1/:category?subcategoryid=:channel&metafield=:metafield&env=:env',
  },
  getQuarryProductFields: {
    url: 'testdata/v1/:category?subcategoryid=:channel&materialcode=:materialcode&env=:env',
  },
  getMeshTestCaseFields: {
    url: 'cjt/v1/categories?subcategoryid=:channel&metafield=:metafield',
  },
  getMyMeshTestCases: {
    url: 'cjt/v1/mytestcases?limit=:limit',
  },
  getMyQuarryOrders: {
    url: 'testdata/v1/mydata?limit=:limit',
  },
  getQuarrySKUs: {
    url: '/testdata/v1/sku/:type?fulfillmentType=:fulfillment&region=:region&channel=:channel&env=:env&limit=50&offset=0',
  },
  submitOrderToQuarry: {
    method: 'post',
    url: 'testdata/:version/:channel/order',
  },

  // Hosting
  getHostingProjects: {
    url: 'hosting/v1/org/:org/workstream/:workstream/project',
  },
  getHostingProject: {
    url: 'hosting/v1/org/:org/workstream/:workstream/project/:project',
  },
  getHostingProjectTestCases: {
    url: 'hosting/v1/org/:org/workstream/:workstream/project/:project/testcase',
  },
  getHostingProjectTestCase: {
    url: 'hosting/v1/org/:org/workstream/:workstream/project/:project/testcase/:testcase',
  },
  postHostingProjectTestCase: {
    method: 'post',
    url: 'hosting/v1/project/:project/testcase',
  },
  postHostingNewProject: {
    method: 'post',
    url: 'hosting/v1/onboard/project',
  },
  putHostingEditProject: {
    method: 'put',
    url: 'hosting/v1/onboard/project/:project',
  },
  deleteHostingProject: {
    method: 'delete',
    url: 'hosting/v1/onboard/workstream/:workstream/project/:project',
  },
  postHostingNewTestCase: {
    method: 'post',
    url: 'hosting/v1/onboard/testcase',
  },
  putHostingEditTestCase: {
    method: 'put',
    url: 'hosting/v1/onboard/workstream/:workstream/project/:project/testcase/:testcase',
  },
  deleteHostingTestCase: {
    method: 'delete',
    url: 'hosting/v1/onboard/workstream/:workstream/project/:project/testcase/:testcase',
  },
  postHostingCreateTestDataFile: {
    method: 'post',
    url: 'hosting/v1/onboard/s3/:workstream/:project/:testcase?env=:env&filetype=:filetype',
  },
  postHostingCreateExecutionTestDataFile: {
    method: 'post',
    url: 'hosting/v1/execution/s3/:workstream/:project/:testcase?env=:env&filetype=:filetype',
  },
  deleteHostingTestDataFile: {
    method: 'delete',
    url: ':uri',
  },
  postHostingCreateTestData: {
    method: 'post',
    url: 'hosting/v1/onboard/testdata',
  },

  // Jobs queue page
  getHostingExecutions: {
    url: 'hosting/v1/myexecutions/:org?limit=:limit:query',
  },
  getTestCaseStatus: {
    url: 'cjt/v1/status/:id?:query',
  },
  getTestDataStatus: {
    url: 'testdata/v1/status/:id',
  },
  getHostingStatus: {
    url: 'hosting/v1/status/:executionid?transactionid=:id',
  },
  getHostingExecutionStatus: {
    url: ':service/v1/status/:executionid',
  },
  postHostingAbortTransactions: {
    method: 'post',
    url: 'hosting/v1/abort/execution/:executionid',
  },
  postPublishReports: {
    method: 'post',
    url: 'report/v1/publish/:service',
  },
  postPublishToJIRA: {
    method: 'post',
    url: 'jira/v1/publish/:service',
  },
  deleteExecutionTransactions: {
    method: 'delete',
    url: 'execution/v1/:service/:executionid',
  },

  getTestDataExecutionStatus: {
    url: 'testdata/v1/execution/status/:executionid?transactionid=:transactionid',
  },

  getCJTExecutionStatus: {
    url: 'cjt/v1/execution/status/:executionid?transactionid=:transactionid',
  },

  // Test Suites
  getTestSuites: {
    url: 'suites/v1/testsuites/:service',
  },
  getTestSuite: {
    url: 'suites/v1/testsuite/:id',
  },
  postNewTestSuite: {
    method: 'post',
    url: 'suites/v1/testsuites/:service',
  },
  postPublishTestSuite: {
    method: 'post',
    url: 'suites/v1/testsuite/publish/:id',
  },
  putUpdateTestSuite: {
    method: 'put',
    url: 'suites/v1/testsuite/:id',
  },
  getServiceEnvironments: {
    url: 'suites/v1/env/:service',
  },
  getScreenshot: {
    url: 'screenshot/' + stack + '-screenshots-bucket/:screenshotname',
  },
  getPlaywrightReport: {
    url: 'hosting/v1/playwright/report/:executionid',
  },
  getPlaywrightReportTransaction: {
    url: 'hosting/v1/playwright/report/:executionid?transactionid=:transactionid',
  },
  deleteTestSuite: {
    method: 'delete',
    url: '/suites/v1/testsuite/:testsuiteid',
  },
  getSharedExecutions: {
    url: 'shared/v1/execution/:service?limit=:limit:query',
  },
  postResubmit: {
    method: 'post',
    url: 'resubmit/v1/:service/:executionid:query',
  },
  putTestSuiteSchedule: {
    method: 'put',
    url: 'suites/v1/testsuite/schedule/:id',
  },
  deleteTestSuiteSchedule: {
    method: 'delete',
    url: 'suites/v1/testsuite/schedule/:id',
  },
  postRunTestSuite: {
    method: 'post',
    url: 'suites/v1/testsuite/:id',
  },
  getMasterData: {
    url: 'datastore/masterdata',
  },
  getSearchDataStores: {
    url: 'datastore/testdata/:category/:subCategory/:env/:store?dataage=:dataAge',
  },
  claimTestDataStore: {
    method: 'patch',
    url: 'datastore/testdata/claim',
  },
  getClaimList: {
    url: 'datastore/testdata/claim',
  },
};

export default endpoints;
