import React from 'react';
import classname from 'classname';

const Checkbox = React.memo(
  React.forwardRef((props, ref) => {
    const className = classname({
      'checkbox-container': true,
      checked: props.checked,
    });

    return (
      <div className={className}>
        <input
          ref={ref}
          type="checkbox"
          onChange={props.onChange}
          checked={props.checked}
          aria-checked={props.checked}
        />
        <span className="checkbox-checkmark"></span>
      </div>
    );
  })
);

export default Checkbox;
