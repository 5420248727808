import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classname';

import LoadingSpinner from 'components/LoadingSpinner';

const Modal = ({
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  deleteButtonText = 'Delete',
  title = '',
  onClose,
  onSubmit = null,
  onDelete = null,
  submitEnabled = true,
  loading = false,
  children,
  alert = false,
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const renderCloseButton = () => {
    return (
      <button type="button" onClick={onClose} className="modal-close-button">
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          title="x-close-gray-24"
        >
          <g fill="#fff" fillRule="evenodd">
            <rect
              transform="rotate(45 9.192 9.192)"
              x="-2.808"
              y="8.192"
              width="24"
              height="2"
            ></rect>
            <rect
              transform="rotate(135 9.192 9.192)"
              x="-2.808"
              y="8.192"
              width="24"
              height="2"
            ></rect>
          </g>
        </svg>
      </button>
    );
  };

  const renderSubmitButton = () => {
    if (onSubmit) {
      const buttonProps = {
        className: classname({
          disabled: !submitEnabled,
        }),
        onClick: submitEnabled ? onSubmit : () => {},
      };

      return (
        <div className="button-wrapper">
          <button {...buttonProps}>{submitButtonText}</button>
        </div>
      );
    }

    return null;
  };

  const renderDeleteButton = () => {
    if (onDelete && !showConfirmDelete) {
      return (
        <div className="button-wrapper delete-btn">
          <button onClick={() => setShowConfirmDelete(true)}>
            {deleteButtonText}
          </button>
        </div>
      );
    }
    if (showConfirmDelete) {
      return (
        <div className="button-wrapper delete-btn">
          <button onClick={onDelete}>Confirm</button>{' '}
          <button onClick={() => setShowConfirmDelete(false)}>No</button>
        </div>
      );
    }

    return null;
  };

  const closeButton = renderCloseButton();
  const submitButton = renderSubmitButton();

  return (
    <div className="modal-wrapper">
      <div className="modal-overlay" onClick={onClose}></div>
      <div className={classname('modal-body', { 'modal--small': alert })}>
        {loading && (
          <div className="modal-loading">
            <LoadingSpinner />
          </div>
        )}

        <div className="modal-title">
          <h4 className="title">{title}</h4>
          {closeButton}
        </div>

        <div className="modal-content">{children}</div>

        <div className="modal-footer">
          {renderDeleteButton()}
          <div className="button-wrapper">
            <button onClick={onClose}>{cancelButtonText}</button>
          </div>
          {submitButton}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  submitEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default Modal;
