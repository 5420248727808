import React from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner';

const Lightbox = (props) => {
  // render methods
  const renderLoading = () => {
    return (
      <div className='lightbox'>
        <div className='lightbox-content'>
          <LoadingSpinner />
          <p>Request in progress</p>
        </div>
      </div>
    );
  };

  return props.lightbox.visible ? renderLoading() : null;
};

const mapStateToProps = (state) => ({
  lightbox: state.lightbox,
});

export default connect(mapStateToProps)(Lightbox);
