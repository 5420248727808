import { submitOrderToQuarry } from 'utils/api.service';

export const Types = {
  FETCH_OPTIONS: 'quarry/FETCHING_OPTION',
  ERROR_SETTING: 'quarry/FETCHING_ERROR',
  FETCH_META_VALS: 'quarry/FETCHING_META_VALS',
  IS_SENDING: 'quarry/IS_SENDING',
  RESET_SUCCESS: 'quarry/RESETTING_DEFAULT'
};

export const resetSuccess = () => {
  return dispatch => {
    dispatch({
      type: Types.RESET_SUCCESS,
      sending: false,
      message: '',
      status: '',
    });
  };
};

export const submitOrder = (payload, version, channel) => {
  return dispatch => {
    const customEndpoints = [
      'return',
      'addseat',
      'addproduct',
      'renewal',
      'pws_addproduct',
      'pws_addseat',
      'aoe_addproduct',
      'aoe_addseat',
      'reference',
    ];
    let endpointChannel = channel;

    dispatch({
      type: Types.IS_SENDING,
      sending: true,
      message: 'Processing request',
    });

    dispatch({ type: 'MESH_CLEAR_TESTCASES' });
    dispatch({type:'HOSTING_RESET'})

    if (customEndpoints.includes(payload.ordertype)) {
      endpointChannel = payload.ordertype.replace('_', '');
    }

    submitOrderToQuarry(payload, version, endpointChannel).then(response => {
      dispatch({
        type: Types.IS_SENDING,
        sending: false,
        status: response.status,
        data: response.data,
      });
    }).catch(error => {
      if (error.response) {
        // Request made and server responded
        dispatch({
          type: Types.IS_SENDING,
          sending: false,
          data: error.response.data,
          status: error.response.status,
        });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        dispatch({
          type: Types.IS_SENDING,
          sending: false,
          data: {message: error.message},
          status: undefined,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
    });
  }
};
